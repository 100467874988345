<template>
	<div 
		class="organism-search-panel"
		:class="[
			panelOpen ? 'open' : '',
			dragging ? 'dragging' : '',
			`search-type-${searchType}`
		]"
		:style="translateX ? { transform: `translateX(${translateX}px)` } : {}"
		>

		<div class="search-toolbar">
			<AtomSearchTypeToggle
				v-model="searchType"
				:appOnly="!canSearchChapters"
				/>
			<AtomInputText 
				v-model="searchTerm"
				placeholder="Search..."
				:clearButton="true"
				ref="searchField"
				@cleared="searchCleared"
				:searchMatchCount="searchMatchCount"
				:currentMatchIndex="currentMatchIndex"
				@nextMatch="nextMatch"
				@prevMatch="prevMatch"
				/>
			<AtomIcon
				icon="search"
				size="small"
				/>
		</div>

		<div 
			v-if="searchType == 'app'"
			class="search-results-container"
			>

			<div 
				v-if="loadingSearchResults"
				class="loading-orb-container"
				>
				<AtomLoadingOrb />
			</div>

			<template v-if="!loadingSearchResults && searchResults != null && searchResults.length > 0 && searchTerm != ''">
				<div 
					v-for="(result, index) in searchResults"
					:key="index"
					class="search-result-item"
					@click="gotoResult(result)"
					>
					<div class="outline">
						{{ result.outline_number ? result.outline_number : '' }}
					</div>
					<div class="content">
						<div class="title">
							{{ resultTitle(result) }}
						</div>
						<div 
							v-if="resultExcerpt(result)"	
							class="excerpt"
							v-html="resultExcerpt(result)"
							/>
						<div class="document">
							<AtomIcon 
								icon="document"
								size="xxsmall"
								color="grey"
								/>
							{{ result.document_name ? result.document_name : 'No document name...' }}
						</div>
					</div>
				</div>
			</template>

			<div
				v-if="!loadingSearchResults && (searchResults == null || searchResults.length === 0) && searchTerm != ''"
				class="empty-results"
				>
				{{ searchTerm.length < 3 ? 'Please enter more characters...' : 'No results found' }}
			</div>

		</div>


		<div 
			class="drag-toggle-container"
			:class="[
				searchType === 'article' ? 'hide' : ''
			]"
			>
			<div class="drag-toggle" />
		</div>

	</div>
</template>

<script>

import { mapGetters, mapMutations, mapActions } from 'vuex'
import Hammer from 'hammerjs'

import AtomInputText from '@/components/atoms/AtomInputText.vue'
import AtomIcon from '../atoms/AtomIcon.vue'
import AtomSearchTypeToggle from '../atoms/AtomSearchTypeToggle.vue'
import AtomLoadingOrb from '../atoms/AtomLoadingOrb.vue'

export default {
	name: 'OrganismSearchPanel',
	components: {
		AtomInputText,
		AtomIcon,
		AtomSearchTypeToggle,
		AtomLoadingOrb
	},
	data: () => {
		return {
			searchTerm: '',
			dragging: false,
			translateX: false,
			startX: 0,
			searchType: 'app',
			layoutOuter: null,
			matches: [],
			currentMatchIndex: -1,
			loadingSearchResults: false,
			searchResults: null,
			searchTimeout: null,
			persistSearchTerm: false,
			refocusInput: true
		}
	},
	mounted() {

		this.layoutOuter = document.querySelector('.layout-main-outer')

		const hammer = new Hammer(this.$el.querySelector('.drag-toggle-container'))
		hammer.on('panstart', this.handlePanStart)
		hammer.on('panmove', this.handlePanMove)
		hammer.on('panend', this.handlePanEnd)
	},
	methods: {
		...mapMutations({
			setSearchPanelOpen: 'layout/setSearchPanelOpen',
			setChapterSearchTerm: 'documents/setChapterSearchTerm'
		}),
		...mapActions('documents', {
			searchApp: 'searchApp'
		}),
		handlePanStart(event) {
			this.dragging = true
			this.startX = event.center.x
		},
		handlePanMove(event) {
			const deltaX = event.center.x - this.startX
			this.translateX = Math.min(Math.max(deltaX, 0), window.innerWidth)
		},
		handlePanEnd() {
			this.dragging = false
			if (this.translateX > window.innerWidth * 0.4) {
				this.setSearchPanelOpen(false)
				this.translateX = false
			} else {
				this.translateX = 0
			}
		},
		searchCleared() {
			console.log('search cleared')
			this.setSearchPanelOpen(false)
		},
		updateMatches() {
			this.matches = Array.from(this.layoutOuter.querySelectorAll('.search-match-highlight'))
			this.currentMatchIndex = this.matches.length > 0 ? 0 : -1
			this.scrollToCurrentMatch()
		},
		scrollToCurrentMatch() {
			if (this.currentMatchIndex >= 0 && this.currentMatchIndex < this.matches.length) {
				// Remove 'current-match' class from all matches
				this.matches.forEach(match => match.classList.remove('current-match'))

				// Add 'current-match' class to the current match
				const match = this.matches[this.currentMatchIndex]
				match.classList.add('current-match')

				match.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'center'
				})
			}
		},
		nextMatch() {
			console.log('next match')
			if (this.matches.length > 0) {
				this.currentMatchIndex = (this.currentMatchIndex + 1) % this.matches.length
				this.scrollToCurrentMatch()
			}
		},
		prevMatch() {
			console.log('prev match')
			if (this.matches.length > 0) {
				this.currentMatchIndex = (this.currentMatchIndex - 1 + this.matches.length) % this.matches.length
				this.scrollToCurrentMatch()
			}
		},
		resultTitle(result){
			if(result && result.type){
				if(result.type === 'chapter'){
					return result.name
				}else if(result.type === 'block'){
					if(result.block_type === 'text' && (result.outline_title != '' && result.outline_title != null)){
						return result.outline_title
					}else{
						return result.chapter_name
					}
				}else{
					return ''
				}
			}else{
				return ''
			}
		},
		resultExcerpt(result){
			if(result && result.type){
				if(result.type === 'chapter'){
					return result.excerpt
				}else if(result.type === 'block'){
					if(result.block_type === 'text'){
						return result.excerpt
					}else if(result.block_type === 'table'){
						return `<strong>"${this.searchTerm}"</strong> found in a table`
					}else{
						return false
					}
				}else{
					return false
				}
			}else{
				return false
			}
		},
		gotoResult(result){
			let currentSearchTerm = this.searchTerm
			this.persistSearchTerm = true
			this.refocusInput = false // disable recofusing so that keyboard doesn't popup again
			if(result && result.type){
				this.searchType = 'article'

				if(result.type === 'chapter'){
					this.$router.push({ name: 'Chapter', params: { id: result.id } })
				}else if(result.type === 'block'){
					this.$router.push({ name: 'Chapter', params: { id: result.chapter_id } })
				}

				setTimeout(() => {
					this.persistSearchTerm = false
					this.refocusInput = true // enable refocusing since it wont trigger the watcher after this point

					// trigger the watcher on search term so that results show up in article mode.
					this.searchTerm = false
					this.$nextTick(() => {
						this.searchTerm = currentSearchTerm
					})
				}, 500)
			}
		}
	},
	computed: {
		...mapGetters({
			panelOpen: 'layout/getSearchPanelOpen',
			canSearchChapters: 'documents/canSearchChapters',
			chapterSearchMatchCount: 'documents/getChapterSearchMatchCount',
		}),
		searchMatchCount(){
			if(this.searchType === 'article'){
				return this.matches.length
			}else{
				return false
			}
		}
	},
	watch: {
		panelOpen(newVal) {
			if (newVal) {
				this.$nextTick(() => {
					this.$refs.searchField.$el.querySelector('input').focus()
				})
			}
		},
		canSearchChapters(newVal) {
			if(newVal){
				this.searchType = 'article'
			}else{
				this.searchType = 'app'
			}
		},
		searchType(newVal) {
			if(newVal === 'article'){
				this.setChapterSearchTerm(this.searchTerm)
				
				if(!this.persistSearchTerm){
					this.searchTerm = ''
				}
			}else{ // this will be going to 'app'
				this.setChapterSearchTerm(null)

				// if we have a searchTerm then trigger the watcher to search the app
				if(this.searchTerm.length > 0){
					let existingSearchTerm = this.searchTerm
					this.searchTerm = false
					this.$nextTick(() => {
						this.searchTerm = existingSearchTerm
					})
				}
			}
			if(this.refocusInput && this.panelOpen){
				this.$nextTick(() => {
					this.$refs.searchField.$el.querySelector('input').focus()
				})
			}
		},
		searchTerm(newVal) {
			if(this.searchType === 'article'){
				this.setChapterSearchTerm(newVal)
				this.$nextTick(() => {
					this.updateMatches()
				})
			}else if(this.searchType == 'app'){
				this.loadingSearchResults = true
				
				// debounce the call to searchApp
				if(this.searchTerm.length > 2){
					clearTimeout(this.searchTimeout)
					this.searchTimeout = setTimeout(() => {

						// run the actual search
						this.searchApp(this.searchTerm)
							.then(results => {
								this.searchResults = results
								this.loadingSearchResults = false
							})
							.catch(err => {
								console.error(err)
								this.loadingSearchResults = false
							})
					}, 500)
				}else if(this.searchTerm.length === 0){
					// if empty then clear the results and stop the animation
					clearTimeout(this.searchTimeout)
					this.loadingSearchResults = false
					this.searchResults = null
				}else{
					clearTimeout(this.searchTimeout)
					// show the loading animation for a bit longer, but then tell them that they should enter more characters
					this.searchTimeout = setTimeout(() => {
						this.loadingSearchResults = false
						this.searchResults = null
					}, 1500)
				}

			}
		}
	}
}

</script>

<style lang="sass">

.organism-search-panel
	position: fixed
	top: 0
	left: 0
	right: 0
	height: 100vh
	height: 100dvh
	z-index: 10
	background-image: url('~@/assets/images/bg-grad-blue.png')
	background-size: cover
	transition: all $transitionLength1
	transform: translateX(100%)
	padding-top: calc($appPadTop)
	opacity: 0

	&.search-type-article
		height: calc($appPadTop + 60px)

	&.open
		transform: translateX(0)
		opacity: 1

	&.dragging
		transition: none

	.drag-toggle-container
		position: absolute
		top: 50%
		left: 0
		height: 60px
		transform: translateY(-50%)
		width: 30px
		opacity: 1
		visibility: visible
		transition: all $transitionLength1

		.drag-toggle
			position: absolute
			top: 0
			bottom: 0
			right: 8px
			width: 3px
			border-radius: 3px
			background-color: $midGrey

		&.hide
			visibility: hidden
			opacity: 0

	.search-toolbar
		display: grid
		grid-template-columns: 70px 1fr 20px
		gap: 10px
		align-items: center
		padding: 0 $sidePaddingSmall

	.search-results-container
		position: absolute
		top: calc($appPadTop + 60px)
		left: $sidePaddingSmall
		right: $sidePaddingSmall
		bottom: $appPadBottom
		@include momentumScrollY()
		display: flex
		flex-direction: column
		gap: 16px
		padding-top: 20px
		padding-bottom: 30px
		mask-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 20px, rgba(255, 255, 255, 1) calc(100% - 20px), rgba(255, 255, 255, 0))

		.loading-orb-container
			position: relative
			width: 100%
			height: 140px
			display: flex
			align-items: center
			justify-content: center

		.empty-results
			width: 100%
			height: 140px
			display: flex
			align-items: center
			justify-content: center
			font-size: 16px
			color: $white
			text-align: center

		.search-result-item
			display: grid
			grid-template-columns: 50px 1fr
			gap: 15px

			.outline
				color: $white
				font-size: 14px
				font-weight: bold
				text-align: right

			.content
				display: flex
				flex-direction: column
				gap: 5px
				align-items: flex-start
				text-align: left

				.title
					font-size: 14px
					font-weight: bold
					color: $white
					line-height: 1.2

				.excerpt
					font-size: 12px
					color: $white
					line-height: 1.2

				.document
					font-size: 10px
					color: $lightGrey
					display: flex
					align-items: center
					gap: 3px
					line-height: 1


</style>