<template>
	<div 
		class="molecule-chapter-card"
		:class="[
			!loading && chapter ? 'has-chapter' : '',
			loading ? 'is-loading' : '',
			!loading && !chapter ? 'no-chapter' : '',
			fullHeight ? 'full-height' : '',
			!isOnline && !availableOffline ? 'disabled' : ''
		]"
		>
		<div 
			v-if="loading"
			class="loading-card"
			/>
		<template v-else-if="!loading && chapter">
			<div 
				class="chapter-card-inner"
				:class="[
					tallerImage ? 'taller-image' : ''
				]"
				>
				<div 
					class="bookmark-icon"
					:class="[
						bookmarked ? 'is-bookmarked' : ''
					]"
					@click="toggleBookmark($event, chapter.id)"
					>
					<AtomIcon 
						v-if="bookmarked"
						icon="bookmark-3d"
						color="purple"
						size="large"
						/>
					<AtomIcon 
						v-else
						icon="bookmark-flat"
						color="grey"
						size="large"
						/>
				</div>

				<div class="image-container">
					<AtomImage 
						:src="thumbnail"
						:absolute="true"
						:cover="true"
						/>
				</div>

				<div class="info-container">
					<AtomHeading 
						color="grey"
						size="s"
						weight="bold"
						align="left"
						>
						{{ chapter.name }}
					</AtomHeading>

					<div
						v-if="showDescription"
						class="chapter-description"
						>
						{{ shortDescription }}
					</div>

					<div 
						v-if="document"
						class="parent-document"
						>
						<AtomIcon 
							icon="document"
							color="dark-grey"
							size="small"
							/>
						<span>{{ document.name }}</span>
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			Could not load Chapter
		</template>
	</div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

import AtomImage from '@/components/atoms/AtomImage.vue'
import AtomIcon from '@/components/atoms/AtomIcon.vue'
import AtomHeading from '@/components/atoms/AtomHeading.vue'

export default {
	name: 'MoleculeChapterCard',
	components: {
		AtomImage,
		AtomIcon,
		AtomHeading
	},
	props: {
		document: {
			type: [Boolean, Object],
			default: false
		},
		chapter: {
			type: [Boolean, Object],
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		},
		showDescription: {
			type: Boolean,
			default: false
		},
		tallerImage: {
			type: Boolean,
			default: false
		},
		fullHeight: {
			type: Boolean,
			default: false
		}
	},
	data: () => {
		return {
			availableOffline: false
		}
	},
	mounted(){
		if(!this.isOnline && this.chapter){
			this.checkChapterAvailabilityOffline(this.chapter.id)
		}
	},
	methods: {
		...mapActions('documents', [
			'bookmarkChapter',
			'doesChapterExistOffline'
		]),
		toggleBookmark(event, id) {
			event.stopPropagation()
			this.bookmarkChapter(id)
		},
		async checkChapterAvailabilityOffline(chapterId) {
      const exists = await this.doesChapterExistOffline(chapterId)
      this.availableOffline = exists
      console.log('availableOffline', {
        availableOffline: this.availableOffline,
        chapterName: this.chapter.name,
        chapterId: this.chapter.id
      })
    }
	},
	computed: {
		...mapGetters({
			bookmarks: 'documents/getBookmarks',
			isOnline: 'layout/getIsOnline'
		}),
		// limit description to 7 words
		shortDescription() {
			if(this.chapter && this.chapter.description){
				return this.chapter.description.split(' ').slice(0, 7).join(' ') + (this.chapter.description.split(' ').length > 7 ? '...' : '')
			}else{
				return ''
			}
		},
		thumbnail() {
			if(this.chapter 
				&& this.chapter.uploads 
				&& this.chapter.uploads.length > 0 
				&& this.chapter.uploads[0].sizes
				&& this.chapter.uploads[0].sizes.length > 0
				&& this.chapter.uploads[0].sizes.some(size => size.size_name === 'thumbnail')
				){
				const thumbnailSize = this.chapter.uploads[0].sizes.find(size => size.size_name === 'thumbnail');
				if (thumbnailSize) {
					return thumbnailSize.publicUrl;
				}else{
					return null
				}
			}else{
				return null
			}
		},
		bookmarked() {
			if(this.chapter && this.bookmarks){
				// check all items in the array where 'bookmarkable_type' is "App\Models\Chapter" and 'bookmarkable_id' is the chapter.id. if it is then return true, else false
				return this.bookmarks.some(bookmark => bookmark.bookmarkable_type === 'App\\Models\\Chapter' && bookmark.bookmarkable_id === this.chapter.id)
			}else{
				return false
			}
		}
	},
	watch: {
		isOnline(val) {
			if(!val && this.chapter){
				this.checkChapterAvailabilityOffline(this.chapter.id)
			}
		}
	}
}

</script>

<style lang="sass">

$imageToContentsize: 95px
$imageToContentsizeLarger: 115px

.molecule-chapter-card
	display: flex
	width: 100%
	min-height: 200px
	background-color: $white
	border-radius: $borderRadiusSmall
	position: relative
	cursor: pointer

	.loading-card
		background: linear-gradient(30deg, lighten($midGrey, 15%) 25%, lighten($midGrey, 20%) 50%, lighten($midGrey, 15%) 75%)
		background-size: 100% 200% 
		animation: gradientLoadingAnimationY 2.6s infinite linear
		position: absolute
		inset: 0
		border-radius: $borderRadiusSmall

	&.disabled
		opacity: 0.3

	&.has-chapter
		border-radius: $borderRadiusSmall 0 $borderRadiusSmall $borderRadiusSmall

	&.no-chapter
		background-color: lighten($midGrey, 15%)
		padding: 15px
		text-align: center
		align-items: center
		justify-content: center
		flex-direction: column
		gap: 10px
		color: $midGrey
		font-family: $mainFontReg

		&:before
			content: '!'
			font-family: $mainFontBold
			width: 30px
			height: 30px
			border-radius: 50%
			border: 2px solid $midGrey
			display: flex
			align-items: center
			justify-content: center
			font-size: 20px

	&.full-height
		height: 100%

	.chapter-card-inner
		position: relative
		width: 100%
		display: flex
		flex-direction: column

		&:before
			content: ''
			position: absolute
			top: 0
			right: 0
			width: 20px
			height: 20px
			background-color: $midGrey
			background: linear-gradient(45deg, $midGrey 0%, $midGrey 20%, lighten($midGrey, 10%) 50%, $midGrey 100%)
			border-radius: 0 0 0 $borderRadiusSmall
			z-index: 1

		&:after
			content: ''
			position: absolute
			top: 0
			right: 0
			width: 0
			height: 0
			border-left: 20px solid $lightGrey
			border-left: 20px solid transparent
			border-right: 20px solid $lightGrey
			border-bottom: 20px solid transparent
			z-index: 2

		.bookmark-icon
			position: absolute
			top: 0px
			left: 8px
			z-index: 3

			&.is-bookmarked
				top: -3px

				.atom-icon.size-large
					height: 34px

		.image-container
			position: relative
			width: 100%
			height: $imageToContentsize
			overflow: hidden
			border-radius: $borderRadiusSmall $borderRadiusSmall 0 0

		.info-container
			padding: 12px
			position: relative
			display: flex
			flex-direction: column
			gap: 10px
			flex-grow: 1

			.chapter-description
				color: $midGrey
				font-size: 12px
				line-height: 1.2

			.parent-document
				display: flex
				align-items: flex-start
				gap: 5px
				color: $midGrey
				margin-top: auto
				padding-top: 10px

				span
					font-size: 11px

		&.taller-image
			.image-container
				height: $imageToContentsizeLarger

			.info-container
				// top: $imageToContentsizeLarger
	

</style>