<template>
	<div class="page-quick-links page-layout">
		<div class="container">

			<AtomHeading
				color="white"
				size="l"
				align="left"
				weight="bold"
				>
				Quick Links
			</AtomHeading>

		</div>

		<div class="quick-links-listing">

			<div 
				v-for="quickLink in quickLinks"
				:key="quickLink.id"
				class="link-row"
				@click="gotoLink(quickLink)"
				>

				<AtomIcon
					color="grey"
					:icon="linkIcon(quickLink)"
					size="medium"
					/>

				<div class="content-container">
					<div class="title">
						{{ quickLink.name }}
					</div>
					<div v-if="quickLink.description" class="description">
						{{ quickLink.description }}
					</div>
				</div>

				<div class="icon-container">
					<AtomIcon
						icon="arrow-right"
						color="grey"
						size="small"
						/>
				</div>
			</div>

		</div>

		<div class="loading-container" v-if="loading">
			<AtomLoadingOrb />
		</div>

	</div>
</template>

<script>

import { mapActions, mapGetters, mapMutations } from 'vuex'

import AtomHeading from '@/components/atoms/AtomHeading.vue'
import AtomIcon from '@/components/atoms/AtomIcon.vue'
import AtomLoadingOrb from '@/components/atoms/AtomLoadingOrb.vue'

export default {
	name: 'PageQuickLinks',
	components: {
		AtomHeading,
		AtomIcon,
		AtomLoadingOrb
	},
	data: () => {
		return {
			loading: true
		}
	},
	mounted(){
		
		this.setActiveFooterIcon('info')
		this.getQuickLinks().then(() => {
			this.loading = false
		})

	},
	methods: {
		...mapMutations({
			setActiveFooterIcon: 'layout/setActiveFooterIcon',
		}),
		...mapActions({
			getQuickLinks: 'quickLinks/getQuickLinks',
		}),
		// eslint-disable-next-line
		gotoLink(quickLink){
			// open the link in a new tab
			window.open(quickLink.url, '_blank')
		},
		linkIcon(quickLink){
			// scan the quickLink.url and if it has linkedin somewher in the url then return the linkedin icon, and the same for facebook and twitter/x 
			if (quickLink.url.includes('linkedin')){
				return 'logo-linkedin'
			} else if (quickLink.url.includes('facebook.com')){
				return 'logo-facebook'
			} else if (quickLink.url.includes('twitter.com') || quickLink.url.includes('x.com')){
				return 'logo-twitter'
			} else {
				return 'link'
			}
		}
	},
	computed: {
		...mapGetters({
			quickLinks: 'quickLinks/getQuickLinks',
		})
	},
}

</script>

<style lang="sass">

.page-quick-links
	padding-top: 20px

	.quick-links-listing
		display: flex
		width: 100%
		gap: 2px
		flex-direction: column
		padding: 20px 0 0

		.link-row
			background-color: $transGrey1
			padding: 15px $sidePaddingSmall
			display: grid
			grid-template-columns: 30px 1fr 20px
			gap: 12px
			align-items: center

			&:first-child
				border-top-left-radius: $borderRadiusMedium
				border-top-right-radius: $borderRadiusMedium

			&:last-child
				border-bottom-left-radius: $borderRadiusMedium
				border-bottom-right-radius: $borderRadiusMedium

			.content-container
				display: flex
				flex-direction: column
				gap: 8px

				.title
					font-size: 14px
					font-family: $mainFontBold
					letter-spacing: -0.5px
					color: $white
					line-height: 1.2

				.description
					font-size: 13px
					font-family: $mainFontReg
					color: $white
					line-height: 1.2

			.icon-container
				display: flex
				align-items: center
				justify-content: center
				gap: 10px
				flex-direction: column

	.loading-container
		display: flex
		justify-content: center
		padding: 20px 0

</style>