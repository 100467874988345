<template>
	<div class="page-find-a-centre page-layout">
		<div 
			class="top-bar"
			ref="topBar"
			>
			<AtomHeading 
				color="grey" 
				align="left"
				size="l"
				weight="normal"
				:spread="true"
				>
				Find a Centre

				<div 
					class="filter-icon-container"
					@click="setCentreFilterPanelOpen(true)"
					>
					<div 
						v-if="totalActiveFilters > 0"
						class="filter-count"
						>
						{{ totalActiveFilters }}
					</div>

					<AtomIcon
						icon="filter"
						:color="totalActiveFilters > 0 ? 'orange' : 'grey'"
						size="small"
						/>
				</div>
			</AtomHeading>
		</div>

		<div 
			class="map-container"
			ref="mapContainer"
			>
			<div 
				v-if="loading"
				class="loading-orb-container"
				:style="{ 
					height: mapHeight + 'px' 
				}"
				>
				<AtomLoadingOrb
					color="grey"
					/>
			</div>


			<GMapMap
				v-if="!loading"
				:center="startLocation"
				:zoom="startZoom"
				:options="mapOptions"
				map-type-id="roadmap"
				:style="{ 
					width: '100vw', 
					height: mapHeight + 'px' 
				}"
				ref="theMap"
				>
				<GMapCluster
					:minimumClusterSize="2"
					:zoomOnClick="true"
					v-if="filteredCentres.length > 0"
					>
					<GMapMarker
						:key="index"
						v-for="(m, index) in filteredCentres"
						:position="m.position"
						:clickable="true"
						@click="openCentre(m.index)"
						/>
				</GMapCluster>

			</GMapMap>
		</div>
	</div>
</template>

<script>

import { mapMutations, mapActions, mapGetters } from 'vuex'

import AtomIcon from '@/components/atoms/AtomIcon.vue'
import AtomHeading from '@/components/atoms/AtomHeading.vue'
import AtomLoadingOrb from '@/components/atoms/AtomLoadingOrb.vue'

export default {
	name: 'PageFindACentre',
	components: {
		AtomIcon,
		AtomHeading,
		AtomLoadingOrb
	},
	data() {
		return {
			map: null,
			loading: true,
			mapHeight: 0,
			startLocation: {
				lat: 54.191370,
				lng: -2.798164
			},
			startZoom: 6,
			mapOptions: {
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				minZoom: 2,
				maxZoom: 14
			},
			qualificationDataMap: {
				'ExF': {
					type: 'wildcard',
					pattern: 'ExF (*)'
				},
				'ExF+': {
					type: 'wildcard',
					pattern: 'ExF Plus (*)'
				},
				'Ex01 - Ex04': {
					type: 'wildcard',
					pattern: 'Ex01-04 (*)'
				},
				'Ex05 - Ex06': {
					type: 'wildcard',
					pattern: 'Ex05-06 (*)'
				},
				'Ex07 - Ex08': {
					type: 'wildcard',
					pattern: 'Ex07-08 (*)'
				},
				'Ex09 - Ex10': {
					type: 'wildcard',
					pattern: 'Ex09-10 (*)'
				},
				'Ex11': {
					type: 'wildcard',
					pattern: 'Ex11 (*)'
				},
				'Ex12': {
					type: 'wildcard',
					pattern: 'Ex12 (*)'
				},
				'Ex14': {
					type: 'wildcard',
					pattern: 'Ex14 (*)'
				},
				'MTEx01 - MTEx04': {
					type: 'wildcard',
					pattern: 'MTEx01-04'
				},
				'FSF': {
					type: 'wildcard',
					pattern: 'FSF (*)'
				},
				'RPP': {
					type: 'contains',
					pattern: 'RPP'
				}
			},
			typeDataMap: {
				'Ex Foundation': {
					type: 'wildcard',
					pattern: 'ExF (*)'
				},
				'Ex Foundation Plus With practical': {
					type: 'wildcard',
					pattern: 'ExF Plus (*)'
				},
				'Gas & Vapours': {
					type: 'wildcard',
					pattern: 'Ex01-04 (*)'
				},
				'Dust': {
					type: 'wildcard',
					pattern: 'Ex05-06 (*)'
				},
				'Petrol Forecourts': {
					type: 'wildcard',
					pattern: 'Ex07-08 (*)'
				},
				'Water Utilities': {
					type: 'wildcard',
					pattern: 'Ex09-10 (*)'
				},
				'Mechanical': {
					type: 'wildcard',
					pattern: 'Ex11 (*)'
				},
				'Application Design Engineer': {
					type: 'wildcard',
					pattern: 'Ex12 (*)'
				},
				'Responsible Person': {
					type: 'wildcard',
					pattern: 'Ex14 (*)'
				},
				'Maritime': {
					type: 'wildcard',
					pattern: 'MTEx01-04'
				},
				'Functional Safety Foundation': {
					type: 'wildcard',
					pattern: 'FSF (*)'
				},
				'Recognised Practitioner Programme': {
					type: 'contains',
					pattern: 'RPP'
				}
			}
		}
	},
	mounted(){
		this.getCentres().then(() => {
			this.getCountries()

			this.loading = false
			this.$nextTick(() => {
				this.map = this.$refs.theMap
			})
		})

		this.mapHeight = this.$refs.mapContainer.clientHeight

		this.setActiveFooterIcon('map')

		this.$nextTick(() => {
			this.setHeaderOpacity(1)
		})
	},
	beforeUnmount(){
		this.setHeaderOpacity(0)
	},
	methods: {
		...mapActions({
			getCentres: 'centres/getCentres',
			getCountries: 'centres/getCountries',
		}),
		...mapMutations({
			setHeaderOpacity: 'layout/setHeaderOpacity',
			setActiveFooterIcon: 'layout/setActiveFooterIcon',
			setCentreInfoPanelContent: 'centres/setCentreInfoPanelContent',
			setCentreInfoPanelOpen: 'centres/setCentreInfoPanelOpen',
			setCentreFilterPanelOpen: 'centres/setCentreFilterPanelOpen',
			setHowManyResultsFound: 'centres/setHowManyResultsFound',
		}),
		openCentre(index){
			const centre = this.centres[index]
			console.log('open centre', centre)
			this.setCentreInfoPanelContent(centre)
			this.setCentreInfoPanelOpen(true)

			if (this.map && centre) {
				this.map.panTo({
					lat: parseFloat(centre.latitude),
					lng: parseFloat(centre.longitude)
				})
			}
		},
	},
	computed: {
		...mapGetters({
			centres: 'centres/getCentres',
			countries: 'centres/getCountries',
			activeFilters: 'centres/getActiveFilters'
		}),
		markers(){
			if(!this.centres) return []
			return this.centres.map((centre, index) => {
				return {
					position: {
						lat: parseFloat(centre.latitude),
						lng: parseFloat(centre.longitude)
					},
					index: index,
					centre: centre
				}
			})
		},
		totalActiveFilters() {
			return this.activeFilters.countries.length + this.activeFilters.qualifications.length
		},
		filteredCentres(){
			if(!this.centres) return []

			let filtered = this.markers.filter(c => {
				if(this.activeFilters.countries.length > 0 && !this.activeFilters.countries.includes(c.centre.country)){
					return false
				}

				// Parse the modules JSON array
				let modules = [];
				try {
					modules = JSON.parse(c.centre.modules);
				} catch (e) {
					// console.error('Error parsing modules JSON:', e);
					return false;
				}

				// Check if all of the active qualifications match the centre's modules
				if(this.activeFilters.qualifications.length > 0){
					return this.activeFilters.qualifications.every(qualification => {
						const qualificationData = this.qualificationDataMap[qualification];
						if (!qualificationData) {
							return false;
						}

						if (qualificationData.type === 'wildcard') {
							const pattern = qualificationData.pattern.toLowerCase().replace('(*)', '([\\w-\\s]+)');
							const regex = new RegExp(pattern);
							const matchedWildcard = modules.some(module => regex.test(module.toLowerCase()));

							const substractWildcard = qualificationData.pattern.toLowerCase().replace(' (*)', '');
							const matchedSubstract = modules.some(module => module.toLowerCase().indexOf(substractWildcard) !== -1);

							if(matchedWildcard || matchedSubstract){
								return true
							}else{
								return false
							}

						} else if (qualificationData.type === 'contains') {
							return modules.some(module => module.toLowerCase().indexOf(qualificationData.pattern.toLowerCase()) !== -1);
						}

						return false;
					});
				}

				// types are the same as qualifications but with a different data map - a human readable, more understandable name. We're allowing people to filter by either

				// Check if all of the active types match the centre's modules
				if(this.activeFilters.types.length > 0){
					return this.activeFilters.types.every(type => {
						const typeData = this.typeDataMap[type];
						if (!typeData) {
							return false;
						}

						if (typeData.type === 'wildcard') {
							const pattern = typeData.pattern.toLowerCase().replace('(*)', '([\\w-\\s]+)');
							const regex = new RegExp(pattern);
							const matchedWildcard = modules.some(module => regex.test(module.toLowerCase()));

							const substractWildcard = typeData.pattern.toLowerCase().replace(' (*)', '');
							const matchedSubstract = modules.some(module => module.toLowerCase().indexOf(substractWildcard) !== -1);

							if(matchedWildcard || matchedSubstract){
								return true
							}else{
								return false
							}

						} else if (typeData.type === 'contains') {
							return modules.some(module => module.toLowerCase().indexOf(typeData.pattern.toLowerCase()) !== -1);
						}

						return false;
					});
				}

				return true
			})

		
			this.setHowManyResultsFound(filtered.length)

			// zoom and pan the map to show all markers in the view
			if(this.map && filtered.length > 0){
				const bounds = {
					north: -Infinity,
					south: Infinity,
					east: -Infinity,
					west: Infinity,
					extend(position) {
						this.north = Math.max(this.north, position.lat);
						this.south = Math.min(this.south, position.lat);
						this.east = Math.max(this.east, position.lng);
						this.west = Math.min(this.west, position.lng);
					},
					toLatLngBounds() {
						return {
							north: this.north,
							south: this.south,
							east: this.east,
							west: this.west
						};
					}
				};
				filtered.forEach(marker => {
					bounds.extend(marker.position);
				});
				this.map.fitBounds(bounds.toLatLngBounds());
			}
			return filtered
		}
	}
}

</script>

<style lang="sass">

$topBarHeight: 40px

.page-find-a-centre
	width: 100%
	position: relative

	.top-bar
		position: absolute
		top: 0
		z-index: 3
		padding: 8px $sidePaddingSmall
		display: flex
		transition: all 300ms
		gap: 10px
		height: 40px
		background-color: rgba($white, 0.9)
		width: 100%

		.filter-icon-container
			display: flex
			align-items: center
			gap: 5px

			.filter-count
				background-color: $orange
				color: $white
				border-radius: 15px
				padding: 2px 5px
				font-size: 12px
				font-family: $mainFontBold
				line-height: 1
				white-space: nowrap

	.map-container
		width: 100%
		height: calc(100vh - $headerHeight - $footerHeight - $topBarHeight)
		height: calc(100dvh - $headerHeight - $footerHeight - $topBarHeight)
		margin-top: $topBarHeight

	.loading-orb-container
		margin-top: $topBarHeight
		display: flex
		justify-content: center
		align-items: center
		width: 100%
		height: 100%
		background-color: $lightGrey
		position: absolute
		top: 0
		left: 0
		z-index: 2

</style>