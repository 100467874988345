<template>
	<div 
		class="molecule-pop-up-panel"
		:class="[
			panelOpen ? 'open' : null,
			dragging ? 'dragging' : null,
			fullscreen ? 'fullscreen' : null
		]"
		:style="{ transform: translateY !== false ? `translateY(${translateY}px)` : '' }"
		>

		<div 
			v-if="panelIcon"
			class="panel-icon"
			>
			<AtomIcon 
				:icon="panelIcon"
				color="grey"
				size="small"
				/>
		</div>

		<div class="drag-toggle-container">
			<div class="drag-toggle" />
		</div>

		<div class="close-icon">
			<AtomIcon 
				icon="close"
				color="grey"
				size="small"
				@click="closePanel"
				/>
		</div>
		
		<div class="panel-inner">
			<slot />
		</div>

	</div>
</template>

<script>

import Hammer from 'hammerjs'

import AtomIcon from '@/components/atoms/AtomIcon.vue'

export default {
	name: 'MoleculePopUpPanel',
	components: {
		AtomIcon
	},
	props: {
		panelOpen: {
			type: Boolean,
			default: false
		},
		fullscreen: {
			type: Boolean,
			default: false
		},
		panelIcon: {
			type: [Boolean, String],
			default: false
		},
		preventScrollEvents: {
			type: Boolean,
			default: true
		}
	},
	data: () => {
		return {
			translateY: false,
			startY: 0,
			dragging: false,
			panelHeight: 0
		}
	},
	mounted(){
		this.panelHeight = this.$el.offsetHeight

		const hammer = new Hammer(this.$el.querySelector('.drag-toggle-container'))
		hammer.get('pan').set({ direction: Hammer.DIRECTION_VERTICAL })
		hammer.on('panstart', this.handlePanStart)
		hammer.on('panmove', this.handlePanMove)
		hammer.on('panend', this.handlePanEnd)

		// Prevent default touch and mouse events
		this.$el.addEventListener('touchmove', this.preventScroll, { passive: false })
		this.$el.addEventListener('mousemove', this.preventScroll, { passive: false })
	},
	beforeUnmount() {
		this.$el.removeEventListener('touchmove', this.preventScroll)
		this.$el.removeEventListener('mousemove', this.preventScroll)
	},
	methods: {
		closePanel(){
			console.log('panel close')
			this.translateY = false
			this.$emit('closePanel')
		},
		handlePanStart(event) {
			this.dragging = true
			this.startY = event.center.y
		},
		handlePanMove(event) {
			const deltaY = event.center.y - this.startY
			this.translateY = Math.min(Math.max(deltaY, 0), this.panelHeight)
		},
		handlePanEnd() {
			this.dragging = false
			
			if (this.translateY > this.panelHeight * 0.4) {
				this.closePanel()
			} else {
				this.translateY = 0
			}
		},
		preventScroll(event) {
			if(this.preventScrollEvents){
				event.preventDefault()
			}
		}
	},
	watch: {
		panelOpen(newVal) {
			if (newVal) {
				this.translateY = 0
			}else{
				this.translateY = false
			}
		}
	}
}

</script>

<style lang="sass">

.molecule-pop-up-panel
	position: fixed
	bottom: 0
	left: 0
	right: 0
	min-height: 25vh
	min-height: 25dvh
	z-index: 9
	background-image: url('~@/assets/images/bg-grad-blue.png')
	background-size: cover
	transition: all $transitionLength2
	transform: translateY(100%)
	padding-bottom: calc($appPadBottom + 30px)
	border-radius: $borderRadiusLarge $borderRadiusLarge 0 0
	box-shadow: 0 0 10px 10px rgba(0,0,0,0)
	display: block

	&.open
		transform: translateY(0)
		box-shadow: 0 0 90px 10px rgba(0,0,0,0.3)

	&.dragging
		transition: none

	.close-icon
		position: absolute
		top: 0
		right: 0
		padding: 15px $sidePaddingSmall
		z-index: 10

	.panel-icon
		position: absolute
		top: 0
		left: 0
		padding: 15px $sidePaddingSmall
		z-index: 10

	.drag-toggle-container
		position: absolute
		top: 0
		left: 50%
		height: 45px
		transform: translateX(-50%)
		width: 80px
		opacity: 1
		visibility: visible
		cursor: grab
		touch-action: none // Disable touch actions

		.drag-toggle
			position: absolute
			top: 20px
			left: 15px
			right: 15px
			height: 3px
			border-radius: 3px
			background-color: $midGrey

	.panel-inner
		padding: 60px $sidePaddingSmall 0

	&.fullscreen
		height: 100vh
		height: 100dvh
		border-radius: 0

		.close-icon
			top: $appPadTop

		.panel-icon
			top: $appPadTop

		.drag-toggle-container
			top: $appPadTop

		.panel-inner
			position: absolute
			top: calc($appPadTop + 40px)
			bottom: calc($appPadBottom + 30px)
			left: 0
			right: 0
			padding-top: 20px
			padding-bottom: 20px
			@include momentumScrollY()
			mask-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 20px, rgba(255, 255, 255, 1) calc(100% - 20px), rgba(255, 255, 255, 0))


</style>