<template>
	<div 
		class="atom-content-block"
		:class="[
			highContrastEnabled ? 'high-contrast' : null,
			`text-size-${textSize}`
		]"
		>
		<template 
			v-if="block.block_type === 'text' && block.content_type === 'plain'"
			>
			<div 
				class="container"
				:class="{ 
					'indent-1': indentLevel === 1, 
					'indent-2': indentLevel === 2 
				}"
				>
				<div
					v-if="showOutline" 
					class="outline"
					>
					<div 
						class="outline-number"
						:class="{ smaller: !largeOutlineNumber }"
						>
						{{ outlineNumber }}
					</div>
					<div class="outline-title" v-html="block.outline_title" />
				</div>
				<div 
					v-if="!['<p><br></p>', ''].includes(block.content_plain)"
					class="text-content"
					:class="{ 
						'indent': indentLevel > 0
					}"
					v-html="block.content_plain"
					/>
			</div>
		</template>

		<template
			v-else-if="block.block_type === 'image' && block.uploads && block.uploads.length > 0"
			>
			<div class="image-content">
				<img 
					v-if="imageUrl && !imageError"
					class="image-content"
					:src="imageUrl"
					@load="onImageLoad"
					@error="onImageError"
					/>
				<div class="placeholder-image" v-else />
			</div>
		</template>

		<template
			v-else-if="block.block_type === 'table' && block.content_type === 'json'"
			>
			<div class="table-content">
				<table>
					<thead>
						<tr>
							<th 
								v-for="(header, hIndex) in block.content_json[0]"
								:key="hIndex"
								v-html="header"
								/>
						</tr>
					</thead>
					<tbody>
						<tr 
							v-for="(row, rIndex) in block.content_json.slice(1)" 
							:key="rIndex"
							>
							<td 
								v-for="(cell, cIndex) in row"
								:key="cIndex"
								v-html="cell"
								/>
						</tr>
					</tbody>
				</table>
			</div>
		</template>
	</div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
	name: 'AtomContentBlock',
	props: {
		block: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			imageError: false
		}
	},
	mounted() {
		// console.log('block', this.block)
	},
	methods: {
		onImageLoad() {
			this.imageError = false;
			this.$emit('image-error', false);
			console.log('Image loaded');
		},
		onImageError() {
			this.imageError = true;
			this.$emit('image-error', true);
			console.log('Image error');
		}
	},
	computed: {
		...mapGetters({
			highContrastEnabled: 'layout/getEnableHighContrast',
			textSize: 'layout/getTextSize'
		}),
		imageUrl() {
			if(this.block && this.block.block_type === 'image' && this.block.uploads && this.block.uploads.length > 0) {
				// uploads will be an array of different sizes. get the one where 'size_name' is large
				const largeSize = this.block.uploads[0].sizes.find(size => size.size_name === 'large')
				if (largeSize) {
					return largeSize.publicUrl
				}else{
					return null
				}
			}else{
				return null
			}
		},
		outlineNumber(){
			if(this.block && this.block.outline_number){
				// split the outline number by '.' and if the second or third digit is 0 then just show the first, if the first and second is greater than 0 then show first and second, if all three are greater than 0 then show all three
				let splitOutlineNumber = this.block.outline_number.split('.')
				if(splitOutlineNumber[1] == '0' && splitOutlineNumber[2] == '0'){
					return splitOutlineNumber[0]
				}else if(splitOutlineNumber[1] != '0' && splitOutlineNumber[2] == '0'){
					return splitOutlineNumber[0] + '.' + splitOutlineNumber[1]
				}else if(splitOutlineNumber[2] != '0'){
					return splitOutlineNumber[0] + '.' + splitOutlineNumber[1] + '.' + splitOutlineNumber[2]
				}else{
					return null
				}

			}else{
				return null
			}
		},
		largeOutlineNumber(){
			if(this.block && this.block.outline_number){
				// only show the large outline if the second and third digit is 0
				let splitOutlineNumber = this.block.outline_number.split('.')
				if(splitOutlineNumber[1] == '0' && splitOutlineNumber[2] == '0'){
					return true
				}else{
					return false
				}
			}else{
				return false
			}
		},
		indentLevel(){
			if(this.block && this.block.outline_number){
				
				let splitOutlineNumber = this.block.outline_number.split('.')
				if(splitOutlineNumber[1] == '0' && splitOutlineNumber[2] == '0'){
					return 0
				}else if(splitOutlineNumber[1] != '0' && splitOutlineNumber[2] == '0'){
					return 1
				}else if(splitOutlineNumber[2] != '0'){
					return 2
				}else{
					return 0
				}

			}else{
				return 0
			}
		},
		showOutline(){
			if(this.block && this.block.outline_number && this.block.outline_title != null && this.block.outline_title != ''){
				return true
			}else{
				return false
			}
		}
	}
};
</script>

<style lang="sass">

.atom-content-block
	display: block

	.container
		&.indent-1
			padding-left: 35px

		&.indent-2
			padding-left: 45px

	.outline
		display: flex
		gap: 10px
		padding: 0 0 10px 0
		font-family: $mainFontBold

		.outline-number
			font-size: 20px
			margin-top: -2px

			&.smaller
				font-size: 16px
				margin-top: 0px

		.outline-title
			font-size: 16px
			flex: 1

	.text-content
		margin: 0
		padding: 0
		font-size: 14px
		line-height: 1.3
		color: $textGrey

		&.indent
			padding-left: 10px
		
		p
			padding: 0 0 15px

		ul, ol
			margin: 0 0 15px 20px
			padding: 0
			list-style-position: outside

		li
			margin: 0 0 8px 0
			padding: 0
			font-size: 14px
			line-height: 1.3
			color: $textGrey
			
			&[data-list="bullet"]
				list-style-type: disc

			&[data-list="ordered"]
				list-style-type: decimal

		blockquote
			padding: 0 0 0 10px
			margin: 0 0 15px 10px
			border-left: 2px solid $midGrey

	.image-content
		// margin: 0 -$sidePaddingSmall
		padding-bottom: 5px
		
		img
			width: 100%
			height: auto

		.placeholder-image
			background-color: lighten($midGrey, 15%)
			display: flex
			justify-content: center
			align-items: center
			background-position: center center
			background-size: 60%
			background-repeat: no-repeat
			background-image: url('~@/assets/images/logo-compex-light-one-colour.svg')
			width: 100%
			height: 200px

	.table-content
		width: 100%
		overflow: auto
		-webkit-overflow-scrolling: touch
		padding: 0 $sidePaddingSmall 10px

		thead
			tr
				th
					border: 1px solid $midGrey
					padding: 5px 7px
					font-size: 14px
					text-align: left
					white-space: nowrap
					font-fmaily: $mainFontBold
					font-weight: bold

		tbody
			tr
				td
					border: 1px solid $midGrey
					padding: 5px 7px
					font-size: 14px
					text-align: left

	&.high-contrast
		.outline
			.outline-number
				color: $white

				&.smaller
					color: $white

			.outline-title
				color: $white

		.text-content
			color: $white

			li
				color: $white

		.table-content
			thead
				tr
					th
						border: 1px solid $white
						color: $white

			tbody
				tr
					td
						border: 1px solid $white
						color: $white

	&.text-size-small
		.outline
			.outline-number
				font-size: 20px

				&.smaller
					font-size: 16px

			.outline-title
				font-size: 16px

		.text-content
			font-size: 14px

			li
				font-size: 14px

		.table-content
			thead
				tr
					th
						font-size: 14px

			tbody
				tr
					td
						font-size: 14px

	&.text-size-medium
		.outline
			.outline-number
				font-size: 22px

				&.smaller
					font-size: 18px

			.outline-title
				font-size: 18px

		.text-content
			font-size: 16px

			li
				font-size: 16px

		.table-content
			thead
				tr
					th
						font-size: 16px

			tbody
				tr
					td
						font-size: 16px

	&.text-size-large
		.outline
			.outline-number
				font-size: 26px

				&.smaller
					font-size: 22px

			.outline-title
				font-size: 22px

		.text-content
			font-size: 20px

			li
				font-size: 20px

		.table-content
			thead
				tr
					th
						font-size: 20px

			tbody
				tr
					td
						font-size: 20px

</style>