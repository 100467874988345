<template>
	<div 
		class="atom-input-text"
		:class="[
			'align-' + align
		]"
		>

		<label 
			v-if="showLabel"
			:for="modelValue"
			>
			{{ placeholder }}
		</label>

		<div class="input-container">
			<textarea 
				:value="modelValue" 
				@input="onInput"
				:placeholder="placeholder"
				/>

			<div 
				v-if="icon"
				class="icon" 
				>
				<AtomIcon 
					:icon="icon"
					color="grey"
					size="small"
					/>
			</div>
		</div>

	</div>
</template>

<script>

import AtomIcon from '@/components/atoms/AtomIcon.vue';

export default {
	name: 'AtomInputTextArea',
	components: {
		AtomIcon
	},
	props: {
		modelValue: {
			type: String,
			required: true
		},
		type: {
			type: String,
			default: 'text',
			validator: (value) => ['text', 'email', 'password'].includes(value)
		},
		placeholder: {
			type: String,
			default: ''
		},
		align: {
			type: String,
			default: 'left',
			validator: (value) => ['left', 'center', 'right'].includes(value)
		},
		icon: {
			type: [Boolean, String],
			default: false
		},
		showLabel: {
			type: Boolean,
			default: false
		}
	},
	data: () => {
		return {

		}
	},
	methods: {
		onInput(event) {
			this.$emit('update:modelValue', event.target.value)
		},
	},
};
</script>

<style lang="sass">

.atom-input-text
	width: 100%
	position: relative

	.input-container
		position: relative

	label
		padding: 5px 0 8px 15px
		font-size: 12px
		color: $white
		display: block

	textarea
		width: 100%
		max-width: 100%
		min-width: 100%
		padding: 10px 15px
		border-radius: $borderRadiusMedium
		font-size: 16px
		min-height: 200px
		max-height: 300px
		border: 1px solid $transGrey2
		background-color: $transGrey1
		line-height: 1.2
		color: #fff

		&:focus
			outline: none

		&::placeholder
			color: #fff
			opacity: 0.9

	&.align-left
		input
			text-align: left

	&.align-center
		input
			text-align: center

	&.align-right
		input
			text-align: right

	.icon
		width: 46px
		height: 46px
		display: flex
		align-items: center
		justify-content: center
		position: absolute
		top: 0
		right: 0
		pointer-events: none
		user-select: none
		outline: none
		-webkit-tap-highlight-color: transparent

		.inner
			width: 13px
			height: 13px			

</style>