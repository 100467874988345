import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import axios from 'axios'
import { StatusBar, Style } from '@capacitor/status-bar'
import { Keyboard } from '@capacitor/keyboard'
import { Capacitor } from '@capacitor/core'
import VueGoogleMaps from 'vue-google-maps-community-fork'
import { App as CapacitorApp } from '@capacitor/app'

// Set Axios defaults
axios.defaults.headers.common['Accept'] = 'application/json'

// set a request header of 'ngrok-skip-browser-warning' to avoid the ngrok browser warning - if using ngrok
// axios.defaults.headers.common['ngrok-skip-browser-warning'] = 'true'

if (window.location.hostname === 'frontend.compex.test' && window.location.port === '8080') {
	
	axios.defaults.baseURL = 'https://backend.compex.test'
	document.documentElement.style.setProperty('--app-pad-bottom', '0px')
	document.documentElement.style.setProperty('--app-pad-top', '15px')
	console.log('Running on local')

} else if (window.Capacitor) {
	console.log('Running on device')

	axios.defaults.baseURL = 'https://compex-backend.bsu-dev.co.uk/'
	
	if (Capacitor.getPlatform() === 'android') {
		console.log('Android')

		StatusBar.setBackgroundColor({ color: '#FFFFFFFF' })
		StatusBar.setStyle({ style: Style.Light })
		Keyboard.setResizeMode({ mode: 'none' }) // prevents keyboard moving the viewport (search input was hiding behind camera cutout)
		
		StatusBar.setOverlaysWebView({ overlay: true })
		
		// Get the status bar height
		const calculatedStatusBarHeight = getStatusBarHeight()
		
		// console.log('statusBarHeight', calculatedStatusBarHeight)
		
		document.documentElement.style.setProperty('--app-pad-bottom', '0px')
		// Apply padding to the app content based on status bar height
    document.documentElement.style.setProperty('--app-pad-top', `${calculatedStatusBarHeight}px`)
		
	} else if (Capacitor.getPlatform() === 'ios') {
		console.log('iOS')

		StatusBar.setBackgroundColor({ color: '#FFFFFFFF' })
		StatusBar.setStyle({ style: Style.Light })
		Keyboard.setResizeMode({ mode: 'none' }) // prevents keyboard moving the viewport (search input was hiding behind camera cutout)
		
		document.documentElement.style.setProperty('--app-pad-bottom', '20px')
		document.documentElement.style.setProperty('--app-pad-top', 'env(safe-area-inset-top)')
	} else {
		console.log('Other')
		document.documentElement.style.setProperty('--app-pad-bottom', '0px')
		document.documentElement.style.setProperty('--app-pad-top', '15px')
	}
	
} else {
	console.log('Running on server')
	axios.defaults.baseURL = 'https://backend.compex.test'
	document.documentElement.style.setProperty('--app-pad-bottom', '0px')
	// axios.defaults.baseURL = 'https://compex-backend.bsu-dev.co.uk/'
}

store.dispatch('layout/checkNetworkStatus')
	.then(() => {
		// check if device is online before creating the app so that every function proceeds with the correct state
		createApp(App)
			.use(store)
			.use(router)
			.use(VueGoogleMaps, {
				load: {
					key: 'AIzaSyCLFrNzFmGFoeUZA848irOlfHgWr945QHM',
				},
			})
			.mount('#app')
	})


// Track history stack manually
let canGoBack = false;

// Update canGoBack whenever the route changes
router.beforeEach((to, from, next) => {
	canGoBack = from.name !== null; // Checks if there was a previous route
	next();
});

CapacitorApp.addListener('backButton', () => {
	if (canGoBack) {
		router.back();
	} else {
		CapacitorApp.exitApp();
	}
});

function getStatusBarHeight() {
	const screenHeight = window.screen.height;
	const innerHeight = window.innerHeight;
	const statusBarHeight = screenHeight - innerHeight;
	return statusBarHeight > 0 ? statusBarHeight : 0;
}