<template>
	<div class="page-contact page-layout">
		<div class="container">

			<AtomHeading
				color="white"
				size="l"
				align="left"
				weight="bold"
				>
				Contact Us
			</AtomHeading>

			
			<form
				class="form-account-details"
				@submit.prevent="handleFormSubmission"
				>
				<div class="form-input">
					<AtomInputText
						icon="pencil"
						v-model="form.type"
						placeholder="Enquiry Type"
						align="left"
						:showLabel="true"
						/>
				</div>
				<div class="form-input">
					<AtomInputTextArea
						icon="pencil"
						v-model="form.message"
						placeholder="Message"
						align="left"
						:showLabel="true"
						/>
				</div>					
			</form>

			<div class="form-terms">
				<div class="term-row">
					<span class="tick-label">
						I conﬁrm that I have read & understood <a href="https://compexcertification.com/my-compex-app/" target="_blank">T&Cs</a> and <a href="https://compexcertification.com/my-compex-app/" target="_blank">Privacy Policy</a>, and I agree to the terms
					</span>
					<AtomYesNo v-model="form.terms" />
				</div>
			</div>

			<ul 
				class="form-errors"
				v-if="(v$.form.$error && v$.form.$errors.length > 0) || errorLog.length > 0"
				>
				<template v-if="errorLog.length > 0">
					<li 
						class="error-message"
						v-for="(error, key) in errorLog"
						:key="key"
						>
						{{ filterErrorMessage(error) }}
					</li>
				</template>
				<template v-if="v$.form.$error && v$.form.$errors.length > 0">
					<li 
						class="error-message"
						v-for="(error, key) in v$.form.$errors"
						:key="key"
						>
						{{ error.$message }}
					</li>
				</template>
			</ul>

			<div 
				v-if="updateMessage"
				class="form-success-message"
				>
				{{ updateMessage }}
			</div>

			<div class="button-container">
				<AtomButton
					label="Send"
					:color="buttonColour"
					:loading="formProcessing"
					@click="handleFormSubmission"
					/>
			</div>


		</div>
	</div>
</template>

<script>

import axios from 'axios'
import { mapActions, mapMutations } from 'vuex'
import useVuelidate from "@vuelidate/core"
import { required, helpers } from "@vuelidate/validators"

import AtomHeading from '@/components/atoms/AtomHeading.vue'
import AtomInputText from '@/components/atoms/AtomInputText.vue'
import AtomInputTextArea from '@/components/atoms/AtomInputTextArea.vue'
import AtomButton from '@/components/atoms/AtomButton.vue'
import AtomYesNo from '@/components/atoms/AtomYesNo.vue'

export default {
	name: 'PageContact',
	components: {
		AtomHeading,
		AtomInputText,
		AtomInputTextArea,
		AtomButton,
		AtomYesNo,
	},
	setup: () => ({ v$: useVuelidate() }),
	data: () => {
		return {
			form: {
				type: '',
				message: '',
				terms: false,
			},
			formProcessing: null,
			errorLog: [],
			updateMessage: null,
		}
	},
	mounted(){
		this.setActiveFooterIcon('info')

	},
	validations() {

		const mustBeTrue = helpers.withMessage('You must agree to the terms', value => value === true)

		return {
			form: {
				type: { 
					required: helpers.withMessage('Enquiry type cannot be empty', required), 
				},
				message: { 
					required: helpers.withMessage('Message cannot be empty', required)
				},
				terms: { 
					mustBeTrue
				},
			},
		}
	},
	methods: {
		...mapMutations({
			setActiveFooterIcon: 'layout/setActiveFooterIcon'
		}),
		...mapActions('auth', {
			updateAccount: 'updateAccount',
		}),
		handleFormSubmission(){
			// clear any previous errors
			this.errorLog = []
			// touch the form to trigger validation
			this.v$.$touch()
			if (!this.v$.$invalid && !this.formProcessing) {
				this.formProcessing = true

				axios.post(`/api/send-message`, this.form)
					.then((response) => {
						this.formProcessing = false
						console.log('response', response.data)

						this.updateMessage = response.data.message

						// reset form
						this.form = {
							type: '',
							message: '',
							terms: false,
						}

						// reset validation
						this.v$.$reset()
					})
					.catch((error) => {
						this.formProcessing = false
						this.errorLog.push(error)
					})
			}else{
				// well, they failed then. Vuelidate will give a message, so we don't need to do anything here.
			}
		},
		filterErrorMessage(error){
			console.log('error', JSON.stringify(error))
			if(typeof error === 'string' && error.includes('AxiosError: ')){
				return error.replace('AxiosError: ', '')
			}else if(typeof error === 'object' && error.message){
				return error.message
			}else{
				return error
			}
		}
	},
	computed: {
		buttonColour(){
			// when the login fields are valid, the button should be orange to indicate that they can proceed.
			if(this.v$.$invalid){
				return 'trans-grey'
			}else{
				return 'orange'
			}
		},
	},
}

</script>

<style lang="sass">

.page-contact
	padding-top: 20px

	.form-account-details
		display: flex
		flex-direction: column
		gap: 10px
		padding: 30px 0 20px 0

	.form-input
		position: relative

	.form-errors
		display: flex
		flex-direction: column
		gap: 10px
		padding: 15px
		background-color: rgba($red, 0.5)
		border-radius: $borderRadiusMedium
		border: 1px solid $transGrey2

		.error-message
			color: $white
			font-size: 14px
			font-family: $mainFontBold

	.form-success-message
		display: flex
		flex-direction: column
		gap: 10px
		padding: 15px
		background-color: rgba($green, 0.5)
		border-radius: $borderRadiusMedium
		border: 1px solid $transGrey2
		color: $white
		font-size: 14px
		font-family: $mainFontBold

	.button-container
		display: flex
		justify-content: center
		margin: 20px 0 calc(40px + $appPadBottom)

	.form-terms
		display: flex
		flex-direction: column
		gap: 15px
		padding: 0 $borderRadiusMedium 20px

		.term-row
			display: flex
			justify-content: space-between
			align-items: center
			gap: 10px

			.tick-label
				font-size: 11px
				color: $white

			a
				text-decoration: underline

</style>