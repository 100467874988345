<template>
	<div class="page-rate-share-app page-layout">
		<div class="container">

			<MoleculeHeadingWithIcon
				heading="Rate or Share the App"
				icon="arrow-left"
				iconColour="white"
				iconRoute="/dashboard"
				iconSide="left"
				/>


			<div class="button-container">
				<AtomButton
					label="Rate MyCompEx App"
					color="orange"
					@click="rateApp"
					icon="thumbs-up"
					iconColor="white"
					/>
				<AtomButton
					label="Share MyCompEx App"
					color="orange"
					@click="shareApp"
					icon="share"
					iconColor="white"
					/>
			</div>


		</div>
	</div>
</template>

<script>

import { mapMutations } from 'vuex'

import AtomButton from '@/components/atoms/AtomButton.vue'

import MoleculeHeadingWithIcon from '@/components/molecules/MoleculeHeadingWithIcon.vue'

export default {
	name: 'PageRateShareApp',
	components: {
		AtomButton,
		MoleculeHeadingWithIcon
	},
	data: () => {
		return {
	
		}
	},
	mounted(){
		this.setActiveFooterIcon('user')

		this.$nextTick(() => {
			this.setHeaderOpacity(1)
		})
	},
	beforeUnmount(){
		this.setHeaderOpacity(0)
	},
	methods: {
		...mapMutations({
			setHeaderOpacity: 'layout/setHeaderOpacity',
			setActiveFooterIcon: 'layout/setActiveFooterIcon'
		}),
		rateApp(){
			// Rate the app
			console.log('Rate the app')
		},
		shareApp(){
			// Share the app
			console.log('Share the app')
		}
	},
	computed: {
	
	},
}

</script>

<style lang="sass">

.page-rate-share-app
	padding-top: 20px

	.button-container
		display: flex
		flex-direction: column
		align-items: center
		gap: 20px
		justify-content: center
		margin: 50px 0 calc(40px + $appPadBottom)


</style>