<template>
	<div 
		class="molecule-header"
		:class="[
			`theme-${theme}`
		]"
		>
		<div class="header-inner">
			<AtomLogo 
				:theme="theme == 'transparent' ? 'light' : 'dark'"
				size="small"
				/>

			<div>
				<AtomIcon
					v-if="isOnline"
					icon="search"
					:color="theme == 'transparent' ? 'white' : 'grey'"
					size="small"
					@click="setSearchPanelOpen(true)"
					/>
			</div>
		</div>
	</div>
</template>

<script>

import AtomLogo from '@/components/atoms/AtomLogo.vue'
import AtomIcon from '@/components/atoms/AtomIcon.vue'

import { StatusBar, Style } from '@capacitor/status-bar'
import { mapMutations, mapGetters } from 'vuex'

export default {
	name: 'MoleculeHeader',
	components: {
		AtomLogo,
		AtomIcon
	},
	props: {
		theme: {
			type: String,
			default: 'transparent',
			validator: function(value) {
				return ['transparent', 'light'].includes(value);
			}
		}
	},
	methods: {
		...mapMutations({
			setSearchPanelOpen: 'layout/setSearchPanelOpen'
		}),
	},
	computed: {
		...mapGetters({
			isOnline: 'layout/getIsOnline'
		})
	},
	watch: {
		theme(newVal) {
			if (window.Capacitor) {
				if(newVal == 'transparent'){
					StatusBar.setStyle({ style: Style.Dark })
					StatusBar.setBackgroundColor({ color: '#00000000' })
				}else{
					StatusBar.setStyle({ style: Style.Light })
					StatusBar.setBackgroundColor({ color: '#FFFFFFFF' })
				}
			}
		}
	},
}

</script>

<style lang="sass">

.molecule-header
	display: flex
	align-items: flex-bottom
	justify-content: center
	height: $headerHeight
	padding-top: calc($appPadTop)

	&.theme-transparent
		background-color: transparent

	&.theme-light
		background-color: rgba($white, 0.9)
		backdrop-filter: blur(3px)

	.header-inner
		width: 100%
		display: flex
		align-items: center
		justify-content: space-between
		padding: 0 $sidePaddingSmall 15px

</style>