<template>
	<div class="atom-text-size-switch">

		<div class="small-a">A</div>

		<div class="switch-container">
			<div class="track" ref="track">
				<div 
					class="filled-track" 
					ref="filledTrack" 
					:class="{ dragging: dragging }" 
					/>
			</div>
			<div 
				class="switch"
				:class="{ dragging: dragging }" 
				ref="switch" 
				@pan="onPan"
				>
				<div 
					ref="invisibleSwitch" 
					class="invisible-switch" 
					/>
			</div>
		</div>

		<div class="large-a">A</div>

	</div>
</template>

<script>
import Hammer from 'hammerjs'

export default {
	name: 'AtomTextSizeSwitch',
	props: {
		initialValue: {
			type: String,
			default: 'small'
		}
	},
	data() {
		return {
			positions: ['small', 'medium', 'large'],
			currentPosition: 0, // Start at the low position
			dragging: false,
			userInteracting: false,
			interactionTimeout: null
		}
	},
	mounted() {

		switch(this.initialValue) {
			case 'small':
				this.currentPosition = 0
				break
			case 'medium':
				this.currentPosition = 1
				break
			case 'large':
				this.currentPosition = 2
				break
		}

		this.hammer = new Hammer(this.$refs.invisibleSwitch)
		this.hammer.on('panstart', () => {
			this.userInteracting = true
			clearTimeout(this.interactionTimeout)
		})
		this.hammer.on('pan', this.onPan)
		this.hammer.on('panend', this.onPanEnd)
		this.updateSwitchPosition()
	},
	beforeUnmount() {
		this.hammer.off('pan', this.onPan)
		this.hammer.off('panend', this.onPanEnd)
	},
	methods: {
		onPan(event) {
			this.dragging = true
			const trackWidth = this.$refs.track.clientWidth
			const switchWidth = this.$refs.switch.clientWidth
			let newLeft = event.center.x - this.$el.offsetLeft - switchWidth

			if (newLeft < 0) newLeft = 0
			if (newLeft > trackWidth - switchWidth) newLeft = trackWidth - (switchWidth * 2)

			this.$refs.switch.style.left = `${newLeft}px`
			this.$refs.filledTrack.style.width = `${newLeft + (switchWidth / 2)}px`
		},
		onPanEnd() {
			this.dragging = false
			const trackWidth = this.$refs.track.clientWidth
			const switchWidth = this.$refs.switch.clientWidth
			const segmentWidth = trackWidth / 3
			const switchLeft = this.$refs.switch.offsetLeft + (switchWidth / 2) - (segmentWidth / 2)

			let newPosition = Math.round(switchLeft / segmentWidth)
			if (newPosition < 0) newPosition = 0
			if (newPosition > 2) newPosition = 2

			this.currentPosition = newPosition
			this.updateSwitchPosition()
			this.$emit('change', this.positions[this.currentPosition])

			this.interactionTimeout = setTimeout(() => {
				this.userInteracting = false
			}, 500)
		},
		updateSwitchPosition() {
			this.dragging = false
			const trackWidth = this.$refs.track.clientWidth
			const switchWidth = this.$refs.switch.clientWidth
			const segmentWidth = trackWidth / 3

			let newLeft
			if (this.currentPosition === 0) {
				newLeft = 0 // Low position
			} else if (this.currentPosition === 1) {
				newLeft = segmentWidth + (segmentWidth - switchWidth) / 2 // Medium position
			} else {
				newLeft = (2 * segmentWidth) + (segmentWidth - switchWidth) // High position
			}

			this.$refs.switch.style.left = `${newLeft}px`
			this.$refs.filledTrack.style.width = `${newLeft + (switchWidth / 2)}px`
		},
	},
	watch: {
		initialValue(newVal) {
			if(!this.userInteracting){ // if the user is interacting with the switch, don't run this. This is purely for sensing if initialValue changes externally. if we run updateSwitchPosition every time then the animation doens't run to smoothly move the switch from where they let go to where it should rest.
				switch(newVal) {
					case 'small':
						this.currentPosition = 0
						break
					case 'medium':
						this.currentPosition = 1
						break
					case 'large':
						this.currentPosition = 2
						break
				}
				this.updateSwitchPosition()
			}
		}
	}
}
</script>

<style lang="sass">

.atom-text-size-switch
	position: relative
	width: 100%
	display: flex
	gap: 15px
	align-items: center

	.small-a
		font-size: 14px
		color: $white
		font-family: $mainFontBold

	.large-a
		font-size: 22px
		color: $white
		font-family: $mainFontBold

	.switch-container
		position: relative
		width: 100%
		height: 20px
		border-radius: 2px

	.track
		position: absolute
		top: 50%
		left: 0
		width: 100%
		height: 4px
		border-radius: 2px
		background-color: $midGrey
		transform: translateY(-50%)
		overflow: hidden

		.filled-track
			height: 4px
			background-color: $orange
			position: absolute
			left: 0
			width: 0
			transition: all 300ms

			&.dragging
				transition: none

	.switch
		position: absolute
		top: 50%
		width: 20px
		height: 20px
		background-color: $orange
		border-radius: 50%
		transform: translateY(-50%)
		cursor: pointer
		transition: all 300ms

		&.dragging
			transition: none

		.invisible-switch
			position: absolute
			top: 50%
			left: 50%
			width: 100px
			height: 60px
			// border: 1px solid red
			transform: translate(-50%, -50%)
			background-color: transparent
			cursor: pointer


</style>