<template>
	<div class="page-news page-layout">
		<div class="container">

			<AtomHeading
				color="white"
				size="l"
				align="left"
				weight="bold"
				>
				News
			</AtomHeading>

		</div>

		<div class="news-listing">

			<div 
				v-for="newsItem in news"
				:key="newsItem.id"
				class="news-row"
				@click="gotoArticle(newsItem)"
				>
				<div class="image-container">
					<AtomImage 
						:src="getThumbnail(newsItem)"
						:absolute="true"
						:cover="true"
						/>
				</div>

				<div class="content-container">
					<div class="title">
						{{ limitText(newsItem.name, 45) }}
					</div>
					<div class="excerpt">
						{{ limitText(newsItem.description, 60) }}
					</div>
				</div>

				<div class="icon-container">
					<AtomIcon
						icon="arrow-right"
						:color="isRead(newsItem) ? 'grey' : 'orange'"
						size="small"
						/>
				</div>
			</div>

		</div>

		<div class="loading-container" v-if="loading">
			<AtomLoadingOrb />
		</div>

		<div 
			v-if="moreNewsToLoad"
			class="load-more"
			>
			<AtomButton
				label="Load More"
				color="trans-grey"
				:loading="loading"
				@click="getMoreNews"
				/>
		</div>

	</div>
</template>

<script>

import { mapActions, mapGetters, mapMutations } from 'vuex'

import AtomHeading from '@/components/atoms/AtomHeading.vue'
import AtomImage from '@/components/atoms/AtomImage.vue'
import AtomIcon from '@/components/atoms/AtomIcon.vue'
import AtomButton from '@/components/atoms/AtomButton.vue'
import AtomLoadingOrb from '@/components/atoms/AtomLoadingOrb.vue'

export default {
	name: 'PageNews',
	components: {
		AtomHeading,
		AtomImage,
		AtomIcon,
		AtomButton,
		AtomLoadingOrb
	},
	data: () => {
		return {
			loading: true
		}
	},
	mounted(){
		this.resetNews()
		this.getProfile()
		this.setActiveFooterIcon('news')
		this.getNews().then(() => {
			console.log('News loaded', this.news)
			this.loading = false
		})

	},
	methods: {
		...mapMutations({
			setActiveFooterIcon: 'layout/setActiveFooterIcon',
			resetNews: 'news/resetNews'
		}),
		...mapActions({
			getNews: 'news/getNews',
			getProfile: 'auth/getProfile'
		}),
		getMoreNews(){
			this.loading = true
			
			this.getNews().then(() => {
				console.log('More news loaded', this.news)
				this.loading = false
			})

		},
		getThumbnail(newsItem){
			if(newsItem 
				&& newsItem.uploads 
				&& newsItem.uploads.length > 0 
				&& newsItem.uploads[0].sizes
				&& newsItem.uploads[0].sizes.length > 0
				&& newsItem.uploads[0].sizes.some(size => size.size_name === 'thumbnail')
				){
				const thumbnailSize = newsItem.uploads[0].sizes.find(size => size.size_name === 'thumbnail');
				if (thumbnailSize) {
					return thumbnailSize.publicUrl;
				}else{
					return null
				}
			}else{
				return null
			}
		},
		// make a function to limit the number of characters in supplied text, end in ellipsis if text length is longer than limit
		limitText(text, limit){
			if(text.length > limit){
				return text.substring(0, limit) + '...'
			}else{
				return text
			}
		},
		isRead(newsItem){
			// user has 'news_read' array of news ids, if newsItem.id is in that array, then it has been read
			if(this.user && this.user.news_read && this.user.news_read.includes(newsItem.id)){
				return true
			}else{
				return false
			}
		},
		gotoArticle(newsItem){
			this.$router.push({ name: 'NewsArticle', params: { id: newsItem.id } })
		}
	},
	computed: {
		...mapGetters({
			news: 'news/getNews',
			user: 'auth/getUser',
			moreNewsToLoad: 'news/getMoreNewsToLoad'
		})
	},
}

</script>

<style lang="sass">

.page-news
	padding-top: 20px

	.news-listing
		display: flex
		width: 100%
		gap: 2px
		flex-direction: column
		padding: 20px 0 0

		.news-row
			background-color: $transGrey1
			padding: 15px $sidePaddingSmall
			display: grid
			grid-template-columns: 0.85fr 1fr 20px
			gap: 12px

			&:first-child
				border-top-left-radius: $borderRadiusMedium
				border-top-right-radius: $borderRadiusMedium

			&:last-child
				border-bottom-left-radius: $borderRadiusMedium
				border-bottom-right-radius: $borderRadiusMedium

			.image-container
				width: 100%
				height: 80px
				overflow: hidden
				border-radius: $borderRadiusSmall
				position: relative

			.content-container
				display: flex
				flex-direction: column
				gap: 8px

				.title
					font-size: 14px
					font-family: $mainFontBold
					letter-spacing: -0.5px
					color: $white
					line-height: 1.2

				.excerpt
					font-size: 13px
					font-family: $mainFontReg
					color: $white
					line-height: 1.2

			.icon-container
				display: flex
				align-items: center
				justify-content: center
				gap: 10px
				flex-direction: column

				.unread-blop
					width: 10px
					height: 10px
					background-color: $orange
					border-radius: 50%

	.loading-container
		display: flex
		justify-content: center
		padding: 20px 0

	.load-more
		display: flex
		justify-content: center
		padding: 20px 0


</style>