<template>
	<div class="organism-popup-centre-info">
		
		<MoleculePopUpPanel
			@closePanel="setInfoPanelOpen(false)"
			:panelOpen="panelOpen"
			>

			<div 
				class="centre-info-panel-inner"
				v-if="infoPanelContent"
				>
				<div class="centre-title">
					{{ infoPanelContent.name }}
				</div>

				<div class="qualifications-cluster">
					<div class="centre-subtitle">
						Qualifications
					</div>

					<div class="centre-qualifications">
						<div 
							v-for="qualification in qualifications" 
							:key="qualification"
							class="a-qualification"
							>
							{{ qualification }}
						</div>
					</div>
				</div>

				<div class="contact-cluster">
					<div class="centre-subtitle">
						Contact
					</div>

					<div 
						v-if="infoPanelContent.telephone"
						class="contact-element"
						>
						<AtomIcon 
							icon="phone" 
							color="white"
							size="xsmall"
							/>
						<a href="tel:{{ infoPanelContent.telephone }}">
							{{ infoPanelContent.telephone }}
						</a>
					</div>

					<div 
						v-if="infoPanelContent.primaryContactEmail"
						class="contact-element"
						>
						<AtomIcon 
							icon="email" 
							color="white"
							size="xsmall"
							/>
						<a href="mailto:{{ infoPanelContent.primaryContactEmail }}">
							{{ infoPanelContent.primaryContactEmail }}
						</a>
					</div>

				</div>
			</div>

		</MoleculePopUpPanel>

	</div>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'

import AtomIcon from '@/components/atoms/AtomIcon.vue'
import MoleculePopUpPanel from '../molecules/MoleculePopUpPanel.vue'

export default {
	name: 'OrganismPopupCentreInfo',
	components: {
		AtomIcon,
		MoleculePopUpPanel
	},
	data: () => {
		return {
			
		}
	},
	methods: {
		...mapMutations('centres', {
			setInfoPanelOpen: 'setCentreInfoPanelOpen',
		}),
		resetAll(){
			this.setEnableHighContrast(false)
			this.setTextSize('small')
		}
	},
	computed: {
		...mapGetters('centres', {
			panelOpen: 'getCentreInfoPanelOpen',
			infoPanelContent: 'getCentreInfoPanelContent'
		}),
		qualifications(){
			return this.infoPanelContent.modules ? JSON.parse(this.infoPanelContent.modules) : []
		},
		qualificationsString() {
			return this.qualifications.length ? this.qualifications.join(', ') : ''
		},
	}
}

</script>

<style lang="sass">

.organism-popup-centre-info
	.centre-info-panel-inner
		display: flex
		flex-direction: column
		align-items: center
		text-align: center
		gap: 15px
		width: 100%
		padding-bottom: 10px

	.centre-title
		font-size: 23px
		color: $white
		width: 100%
		text-align: left
		font-family: $mainFontBold
		letter-spacing: -0.7px

	.centre-subtitle
		font-size: 18px
		color: $white
		width: 100%
		text-align: left
		font-family: $mainFontBold
		letter-spacing: -0.5px
		padding: 0 0 10px

	.qualifications-cluster
		width: 100%
		display: flex
		flex-direction: column
		gap: 5px
		padding: 10px 0 0 0

		.centre-qualifications
			width: 100%
			display: flex
			flex-wrap: wrap
			gap: 5px
			justify-content: flex-start
			align-items: center
			text-align: left
			font-size: 16px
			color: $white
			font-family: $mainFontReg
			letter-spacing: -0.5px

			.a-qualification
				background-color: $transGrey1
				color: $white
				padding: 5px 10px
				border-radius: 20px
				font-size: 13px
				font-family: $mainFontBold
				letter-spacing: -0.5px
				display: inline-flex
				align-items: center
				text-align: left

	.contact-cluster
		width: 100%
		padding: 10px 0 0 0
		display: flex
		flex-direction: column
		gap: 5px

		.contact-element
			display: flex
			align-items: center
			justify-content: flex-start
			gap: 5px
			font-size: 16px
			color: $white
			font-family: $mainFontReg
		


</style>