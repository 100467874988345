<template>
	<span 
		class="loading-orb"
		:class="[
			`color-${color}`
		]"
		>
	</span>
</template>

<script>

export default {
	name: 'AtomLoadingOrb',
	props: {
		color: {
			type: String,
			default: 'white'
		}
	}
}

</script>

<style lang="sass">

.loading-orb
	width: 48px
	height: 48px
	display: inline-block
	position: relative

	&:after, &:before
		content: ''
		box-sizing: border-box
		width: 48px
		height: 48px
		border-radius: 50%
		background: #FFF
		position: absolute
		left: 0
		top: 0
		animation: animloader 1000ms linear infinite

	&:after
		animation-delay: 400ms
		transform: scale(0)
		opacity: .3
		filter: blur(10px)

	&.color-grey
		&:after, &:before
			background: $darkGrey

@keyframes animloader
	0%
		transform: scale(0)
		opacity: 1

	100%
		transform: scale(1)
		opacity: 0

</style>