import axios from 'axios'

const state = () => ({
	quickLinks: [],
})

const getters = {
	getQuickLinks: state => state.quickLinks,
}

const mutations = {
	setQuickLinks(state, quickLinks) {
		state.quickLinks = quickLinks
	}
}

const actions = {
	getQuickLinks({ commit, dispatch }) {
		// console.log('Fetching quick links...')
		return new Promise((resolve, reject) => {
			axios.get('/api/quick-links')
				.then(response => {
					commit('setQuickLinks', response.data)
					// console.log('quick links', response.data)
					resolve(response)
				})
				.catch(error => {
					// if 401 then dispatch clearUser
					if (error.response && error.response.status === 401) {
						dispatch('auth/clearUser', null, { root: true })
						resolve(error)
					}else{
						console.error('Error fetching quick links:', error)
						reject(error)
					}
				})
		})
	}
}


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}