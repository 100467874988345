<template>
	<div class="organism-filter-docs-panel">
		
		<MoleculePopUpPanel
			@closePanel="setFilterDocsPanelOpen(false)"
			:panelOpen="panelOpen"
			panelIcon="filter"
			:fullscreen="true"
			:preventScrollEvents="false"
			>

			<div class="filter-panel-inner">

				<template v-if="activeFilters.length > 0">
					<div class="panel-title">
						Active Filters
					</div>

					<div class="active-filters">
						<div 
							v-for="option in activeFilters"
							:key="option.id"
							class="active-filter-option"
							@click="toggleFilter(option)"
							>
							<div class="active-indicator" />
							<div class="option-title">
								{{ option.name }}
							</div>
						</div>
					</div>
				</template>

				<div class="panel-title">
					Filters
				</div>

				<div class="filter-options">
					
					<div 
						v-for="option in tags"
						:key="option"
						class="filter-option"
						:class="{ active: activeFilters.findIndex(filter => filter.id === option.id) !== -1 }"
						@click="toggleFilter(option)"
						>
						<div class="option-title">
							{{ option.name }}
						</div>
					</div>

				</div>
			</div>

			<div class="button-bar">
				<AtomButton
					color="fake-trans-grey-on-grad-blue"
					label="Clear All"
					:fullWidth="true"
					@click="clearAll"
					/>
				<AtomButton
					color="orange"
					label="Apply"
					:fullWidth="true"
					@click="setFilterDocsPanelOpen(false)"
					/>
			</div>

		</MoleculePopUpPanel>

	</div>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'

import AtomButton from '../atoms/AtomButton.vue'
import MoleculePopUpPanel from '../molecules/MoleculePopUpPanel.vue'

export default {
	name: 'OrganismFilterDocsPanel',
	components: {
		AtomButton,
		MoleculePopUpPanel
	},
	methods: {
		...mapMutations({
			setFilterDocsPanelOpen: 'layout/setFilterDocsPanelOpen',
			setActiveFilters: 'documents/setActiveFilters'
		}),
		resetAll(){
			this.setEnableHighContrast(false)
			this.setTextSize('small')
		},
		toggleFilter(option){
			const activeFilters = this.activeFilters
			const index = activeFilters.findIndex(filter => filter.id === option.id)
			if(index === -1){
				activeFilters.push(option)
			} else {
				activeFilters.splice(index, 1)
			}
			this.setActiveFilters(activeFilters)
		},
		clearAll(){
			this.setActiveFilters([])
		}
	},
	computed: {
		...mapGetters({
			panelOpen: 'layout/getFilterDocsPanelOpen',
			tags: 'documents/getTags',
			activeFilters: 'documents/getActiveFilters'
		})
	},
}

</script>

<style lang="sass">

.organism-filter-docs-panel
	.filter-panel-inner
		display: flex
		flex-direction: column
		align-items: center
		gap: 15px
		padding-top: 20px
		min-height: calc(100dvh - $appPadTop - $appPadBottom - 190px)

		.panel-title
			font-size: 22px
			color: $white
			width: 100%

		.filter-options
			display: grid
			grid-template-columns: 1fr 1fr
			gap: 15px
			width: 100%
			padding-bottom: 20px

			.filter-option
				position: relative
				background-color: $transGrey1
				width: 100%
				display: flex
				height: 50px
				border-radius: $borderRadiusSmall

				.option-title
					position: absolute
					inset: 0
					display: flex
					justify-content: center
					align-items: center
					text-align: center
					font-size: 13px
					color: $white
					font-family: $mainFontBold
					letter-spacing: -0.5px
					padding: 0 10px
					line-height: 1.2
					@include fontSmoothing()

				&.active
					background-color: $white

					.option-title
						color: $black

		.active-filters
			display: flex
			justify-content: flex-start
			flex-wrap: wrap
			gap: 8px
			padding-bottom: 15px
			width: 100%

			.active-filter-option
				position: relative
				background-color: $white
				display: inline-flex
				align-items: center
				padding: 0 10px
				height: 30px
				border-radius: $borderRadiusSmall

				.active-indicator
					display: flex
					width: 15px
					height: 15px
					background-color: $orange
					border-radius: 50%
					justify-content: center
					align-items: center

					&:after
						content: ''
						width: 5px
						height: 1px
						background-color: $white

				.option-title
					text-align: center
					font-size: 11px
					color: $black
					font-family: $mainFontBold
					letter-spacing: -0.5px
					padding: 0 10px
					line-height: 1
					@include fontSmoothing()
					white-space: nowrap

	.button-bar
		position: sticky
		left: 0
		right: 0
		bottom: -20px
		padding: 20px 0
		display: grid
		grid-template-columns: 1fr 1fr
		gap: 15px
		z-index: 5

			


</style>