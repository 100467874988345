import { Network } from '@capacitor/network'

const state = () => ({
	headerOpacity: 0,
	activeFooterIcon: 'home',
	searchPanelOpen: false,
	readabilityPanelOpen: false,
	enableHighContrast: JSON.parse(localStorage.getItem('enableHighContrast')) || false,
	textSize: localStorage.getItem('textSize') || 'small',
	infoPanelOpen: false,
	userPanelOpen: false,
	filterDocsPanelOpen: false,
	isOnline: false
})

const getters = {
	getHeaderOpacity: state => state.headerOpacity,
	getActiveFooterIcon: state => state.activeFooterIcon,
	getSearchPanelOpen: state => state.searchPanelOpen,
	getReadabilityPanelOpen: state => state.readabilityPanelOpen,
	getEnableHighContrast: state => state.enableHighContrast,
	getTextSize: state => state.textSize,
	getInfoPanelOpen: state => state.infoPanelOpen,
	getUserPanelOpen: state => state.userPanelOpen,
	getFilterDocsPanelOpen: state => state.filterDocsPanelOpen,
	getIsOnline: state => state.isOnline
}

const mutations = {
	setHeaderOpacity(state, opacity) {
		state.headerOpacity = opacity
	},
	setActiveFooterIcon(state, icon) {
		state.activeFooterIcon = icon
	},
	setSearchPanelOpen(state, open) {
		state.searchPanelOpen = open
	},
	setReadabilityPanelOpen(state, open) {
		state.readabilityPanelOpen = open
	},
	setEnableHighContrast(state, enable) {
		state.enableHighContrast = enable
		localStorage.setItem('enableHighContrast', JSON.stringify(enable))
	},
	setTextSize(state, size) {
		state.textSize = size
		localStorage.setItem('textSize', size)
	},
	setInfoPanelOpen(state, open) {
		if(state.isOnline){
			state.infoPanelOpen = open
		}else{
			state.infoPanelOpen = false
		}
	},
	setUserPanelOpen(state, open) {
		if(state.isOnline){
			state.userPanelOpen = open
		}else{
			state.userPanelOpen = false
		}
	},
	setFilterDocsPanelOpen(state, open) {
		state.filterDocsPanelOpen = open
	},
	setIsOnline(state, isOnline) {
		state.isOnline = isOnline
	}
}

const actions = {
	checkNetworkStatus({ commit }) {
		return new Promise((resolve) => {
			Network.getStatus()
				.then(status => {
					if (!status.connected) {
						console.log('No internet connection')
						commit('setIsOnline', false)
						// Handle no internet connection
					} else {
						console.log('Internet connection available')
						commit('setIsOnline', true)
						// Handle internet connection available
					}
					resolve()
				}
			)
		})
	}
}


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}