<template>
	<div class="page-change-password page-layout">
		<div class="container">

			<MoleculeHeadingWithIcon
				heading="Change Password"
				icon="arrow-left"
				iconColour="white"
				iconRoute="/dashboard"
				iconSide="left"
				/>

			
			<form
				class="form-account-details"
				@submit.prevent="handleChangePassword"
				>
				<div class="form-input">
					<AtomInputText
						icon="pencil"
						type="password"
						v-model="form.currentPassword"
						placeholder="Current Password"
						align="left"
						:showLabel="true"
						/>
				</div>
				<div class="form-input">
					<AtomInputText
						icon="pencil"
						type="password"
						v-model="form.password"
						placeholder="Password"
						align="left"
						:showLabel="true"
						/>
				</div>
				<div class="form-input">
					<AtomInputText
						icon="pencil"
						type="password"
						v-model="form.confirmPassword"
						placeholder="Confirm Password"
						align="left"
						:showLabel="true"
						/>
				</div>
			</form>

			<ul 
				class="form-errors"
				v-if="(v$.form.$error && v$.form.$errors.length > 0) || errorLog.length > 0"
				>
				<template v-if="errorLog.length > 0">
					<li 
						class="error-message"
						v-for="(error, key) in errorLog"
						:key="key"
						>
						{{ filterErrorMessage(error) }}
					</li>
				</template>
				<template v-if="v$.form.$error && v$.form.$errors.length > 0">
					<li 
						class="error-message"
						v-for="(error, key) in v$.form.$errors"
						:key="key"
						>
						{{ error.$message }}
					</li>
				</template>
			</ul>

			<div 
				v-if="updateMessage"
				class="form-success-message"
				>
				{{ updateMessage }}
			</div>

			<div class="button-container">
				<AtomButton
					label="Update"
					:color="buttonColour"
					:loading="formProcessing"
					@click="handleChangePassword"
					/>
			</div>


		</div>
	</div>
</template>

<script>

import { mapGetters, mapActions, mapMutations } from 'vuex'
import useVuelidate from "@vuelidate/core"
import { required, minLength, helpers } from "@vuelidate/validators"

import AtomInputText from '@/components/atoms/AtomInputText.vue'
import AtomButton from '@/components/atoms/AtomButton.vue'

import MoleculeHeadingWithIcon from '@/components/molecules/MoleculeHeadingWithIcon.vue'

export default {
	name: 'PageChangePassword',
	components: {
		AtomInputText,
		AtomButton,
		MoleculeHeadingWithIcon
	},
	setup: () => ({ v$: useVuelidate() }),
	data: () => {
		return {
			form: {
				currentPassword: '',
				password: '',
				confirmPassword: '',
			},
			formProcessing: null,
			errorLog: [],
			updateMessage: null,
		}
	},
	mounted(){
		this.setActiveFooterIcon('user')

		this.$nextTick(() => {
			this.setHeaderOpacity(1)
		})
	},
	beforeUnmount(){
		this.setHeaderOpacity(0)
	},
	validations() {

		return {
			form: {
				currentPassword: { 
					required: helpers.withMessage('Current Password cannot be empty', required),
					minLength: helpers.withMessage('Current Password must be at least 6 characters', minLength(6))
				},
				password: { 
					required: helpers.withMessage('Password cannot be empty', required),
					minLength: helpers.withMessage('Password must be at least 6 characters', minLength(6))
				},
				confirmPassword: { 
					required: helpers.withMessage('Confirm Password cannot be empty', required),
					minLength: helpers.withMessage('Confirm Password must be at least 6 characters', minLength(6)),
					sameAsPassword: helpers.withMessage('Passwords must match', (value) => {
						return value === this.form.password
					})
				},
			},
		}
	},
	methods: {
		...mapMutations({
			setHeaderOpacity: 'layout/setHeaderOpacity',
			setActiveFooterIcon: 'layout/setActiveFooterIcon'
		}),
		...mapActions('auth', {
			updatePassword: 'updatePassword',
		}),
		handleChangePassword(){
			// clear any previous errors and messages
			this.errorLog = []
			this.updateMessage = null
			// touch the form to trigger validation
			this.v$.$touch()
			if (!this.v$.$invalid && !this.formProcessing) {
				this.formProcessing = true
				this.updatePassword(this.form)
					.then((response) => {
						this.formProcessing = false

						this.updateMessage = response.data.message

						// reset the fields
						this.form.currentPassword = ''
						this.form.password = ''
						this.form.confirmPassword = ''

						// clear form errors
						this.v$.$reset()
					})
					.catch((error) => {
						this.formProcessing = false
						this.errorLog.push(error)
					})
			}else{
				// well, they failed then. Vuelidate will give a message, so we don't need to do anything here.
			}
		},
		filterErrorMessage(error){
			console.log('error', JSON.stringify(error))
			if(typeof error === 'string' && error.includes('AxiosError: ')){
				return error.replace('AxiosError: ', '')
			}else if(typeof error === 'object' && error.message){
				return error.message
			}else{
				return error
			}
		}
	},
	computed: {
		...mapGetters('auth', {
			getToken: 'getToken',
			getUser: 'getUser',
			isAuthenticated: 'isAuthenticated',
		}),
		buttonColour(){
			// when the login fields are valid, the button should be orange to indicate that they can proceed.
			if(this.v$.$invalid){
				return 'trans-grey'
			}else{
				return 'orange'
			}
		},
	},
}

</script>

<style lang="sass">

.page-change-password
	padding-top: 20px

	.form-account-details
		display: flex
		flex-direction: column
		gap: 10px
		padding: 30px 0 20px 0

	.form-input
		position: relative

	.form-errors
		display: flex
		flex-direction: column
		gap: 10px
		padding: 15px
		background-color: rgba($red, 0.5)
		border-radius: $borderRadiusMedium
		border: 1px solid $transGrey2

		.error-message
			color: $white
			font-size: 14px
			font-family: $mainFontBold

	.form-success-message
		display: flex
		flex-direction: column
		gap: 10px
		padding: 15px
		background-color: rgba($green, 0.5)
		border-radius: $borderRadiusMedium
		border: 1px solid $transGrey2
		color: $white
		font-size: 14px
		font-family: $mainFontBold

	.button-container
		display: flex
		justify-content: center
		margin: 20px 0 calc(40px + $appPadBottom)

</style>