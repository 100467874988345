<template>
	<div 	
		class="page-content"
		:class="[
			highContrastEnabled ? 'high-contrast' : null,
			`text-size-${textSize}`
		]"
		>

		<div class="featured-image-container">
			<div class="featured-image">

				<AtomImage 
					:src="pageImage"
					:absolute="true"
					:cover="true"
					/>

			</div>
		</div>

		<div class="typography-styles">
			
			<div 
				class="page-header"
				:class="{ sticky: articleHeaderIsSticky }"
				ref="articleHeading"
				>
				
				<AtomIcon
					icon="arrow-left"
					:color="highContrastEnabled ? (articleHeaderIsSticky ? 'black' : 'white') : 'grey'"
					size="medium"
					@click="backToDashboard"
					/>
				<AtomIcon
					icon="font-size"
					:color="readabilityPanelOpen ? 'orange' : (highContrastEnabled ? (articleHeaderIsSticky ? 'black' : 'grey') : 'grey')"
					size="medium"
					@click="toggleReadabilityPanel"
					/>
				
				<div class="third-col">
					
				</div>
				
			</div>

			<div class="container">
				<div class="page-title">
					{{ pageTitle }}
				</div>
			</div>

			

			<div class="blocks-container">
				
				<AtomContentBlock
					v-for="(block, index) in pageBlocks"
					:key="index"
					:block="block"
					/>

			</div>
			
		</div>
	</div>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'

import AtomIcon from '@/components/atoms/AtomIcon.vue'
import AtomImage from '@/components/atoms/AtomImage.vue'
import AtomContentBlock from '@/components/atoms/AtomContentBlock.vue'

export default {
	name: 'PageAbout',
	components: {
		AtomIcon,
		AtomImage,
		AtomContentBlock,
	},
	data: () => {
		return {
			articleHeaderIsSticky: false,
			layoutOuter: null,
			initialArticleHeadingOffset: null,
			pageImage: require('@/assets/images/about-us.jpg'),
			pageTitle: 'About us',
			pageBlocks: [
        {
					"id": 0,
					"outline_number": "0.0.0",
					"outline_title": null,
					"block_type": "text",
					"content_type": "plain",
					"content_plain": "<p>CompEx is the international scheme for competency validation and certification of personnel who work in explosive atmospheres.</p> <p>Through a network of independent training and assessment centres the CompEx scheme supports practitioners in a range of industrial sectors including oil and gas, chemicals, pharmaceuticals, food manufacturing and utilities.</p>",
					"content_json": null,
					"order": 1,
					// "uploads": []
        },
      ],
		}
	},
	mounted() {
		
		this.setActiveFooterIcon('info')

		this.layoutOuter = document.querySelector('.layout-main-outer')
		
		// add scroll event listener
		this.layoutOuter.addEventListener('scroll', this.isArticleHeadingSticky)

		this.initializePage()

	},
	beforeUnmount() {
		// remove scroll event listener
		this.layoutOuter.removeEventListener('scroll', this.isArticleHeadingSticky)

	},
	methods: {
		...mapMutations({
			setReadabilityPanelOpen: 'layout/setReadabilityPanelOpen',
			setActiveFooterIcon: 'layout/setActiveFooterIcon'
		}),
		initializePage() {
			
			// smoothly scroll to the top of the page
			this.layoutOuter.scrollTo({ top: 0, behavior: 'smooth' })
			
			
		},
		isArticleHeadingSticky() {
			const articleHeading = this.$refs.articleHeading

			if(this.initialArticleHeadingOffset == null){
				this.initialArticleHeadingOffset = articleHeading.offsetTop
			}else{
				if (articleHeading && this.layoutOuter) {
					let isSticky = articleHeading.offsetTop != this.initialArticleHeadingOffset
					this.articleHeaderIsSticky = isSticky
				}
			}
		},
		backToDashboard() {
			this.$router.push('/dashboard')
		},
		toggleReadabilityPanel() {
			this.setReadabilityPanelOpen(!this.readabilityPanelOpen)
		},
	},
	computed: {
		...mapGetters({
			readabilityPanelOpen: 'layout/getReadabilityPanelOpen',
			highContrastEnabled: 'layout/getEnableHighContrast',
			textSize: 'layout/getTextSize',
		}),
		
	}
}
</script>

<style lang="sass">

.page-content
	display: flex
	flex-direction: column
	border-radius: $borderRadiusLarge $borderRadiusLarge 0 0
	background-color: $lightGrey
	width: 100%
	min-height: $mainContentWindowHeight
	overflow: visible
	padding: 0 0 20px 0
	transition: background-color 300ms

	.featured-image-container
		padding: 10px 10px 0

	.featured-image
		width: 100%
		height: 210px
		background-color: lighten($midGrey, 15%)
		margin-bottom: 0px
		display: flex
		position: relative
		border-radius: $borderRadiusMedium
		overflow: hidden

	.page-title
		display: flex
		gap: 10px
		padding: 0 0 20px 0
		font-family: $mainFontBold
		font-size: 22px

	.page-header
		position: sticky
		top: 0
		z-index: 3
		margin: 0 0 20px
		padding: 0 $sidePaddingSmall
		display: flex
		align-items: center
		justify-content: space-between
		transition: all 300ms
		height: 40px

		&.sticky
			background-color: rgba($white, 0.75)
			backdrop-filter: blur(5px)

		.third-col
			width: 30px
			display: flex
			justify-content: flex-end

	.blocks-container
		padding: 0 0 20px 0
		display: flex
		flex-direction: column
		gap: 5px

	&.high-contrast
		background-color: $bgDarkGrey

		.page-title
			color: $white

	&.text-size-small
		.page-title
			font-size: 22px

	&.text-size-medium
		.page-title
			font-size: 24px

	&.text-size-large
		.page-title
			font-size: 26px



</style>