import axios from 'axios'

const state = () => ({
	news: [],
	currentNewsPage: 1,
	unreadCount: 0,
	moreNewsToLoad: false,
	currentNewsArticle: null
})

const getters = {
	getNews: state => state.news,
	getCurrentNewsPage: state => state.currentNewsPage,
	getUnreadCount: state => state.unreadCount > 9 ? '9+' : state.unreadCount,
	getMoreNewsToLoad: state => state.moreNewsToLoad,
	getCurrentNewsArticle: state => state.currentNewsArticle
}

const mutations = {
	resetNews(state) {
		state.news = []
	},
	setNews(state, news) {
		state.news = [...state.news, ...news]
	},
	setCurrentNewsPage(state, page) {
		state.currentNewsPage = page
	},
	setUnreadCount(state, count) {
		state.unreadCount = count
	},
	setMoreNewsToLoad(state, more) {
		state.moreNewsToLoad = more
	},
	setCurrentNewsArticle(state, article) {
		state.currentNewsArticle = article
	}
}

const actions = {
	getNews({ commit, dispatch, state }) {
		// console.log('Fetching news...')

		if(state.moreNewsToLoad){
			commit('setCurrentNewsPage', state.currentNewsPage + 1)
		}

		return new Promise((resolve, reject) => {
			axios.get(`/api/news?page=${state.currentNewsPage}`)
				.then(response => {
					commit('setNews', response.data.data)
					// console.log('news', response.data)

					if(response.data.total > state.news.length){
						commit('setMoreNewsToLoad', true)
					}else{
						commit('setMoreNewsToLoad', false)
					}
					resolve(response)
				})
				.catch(error => {
					// if 401 then dispatch clearUser
					if (error.response && error.response.status === 401) {
						dispatch('auth/clearUser', null, { root: true })
						resolve(error)
					}else{
						console.error('Error fetching news:', error)
						reject(error)
					}
				})
		})
	},
	getNewsArticle({ commit, dispatch }, id) {
		console.log('Fetching news article...')
		return new Promise((resolve, reject) => {
			axios.get(`/api/news/${id}`)
				.then(response => {
					// console.log('news article', response.data)
					commit('setCurrentNewsArticle', response.data)
					resolve(response)
				})
				.catch(error => {
					// if 401 then dispatch clearUser
					if (error.response && error.response.status === 401) {
						dispatch('auth/clearUser', null, { root: true })
						resolve(error)
					}else{
						console.error('Error fetching news article:', error)
						reject(error)
					}
				})
		})
	},
	getUnreadCount({ commit, dispatch, rootGetters }) {
		const isOnline = rootGetters['layout/getIsOnline'];
		// console.log('Fetching unread count...')

		return new Promise((resolve, reject) => {
			if(isOnline){
				axios.get('/api/news/unread/count')
					.then(response => {
						commit('setUnreadCount', response.data.unread_count)
						// console.log('unread count', response.data)
						resolve(response)
					})
					.catch(error => {
						// if 401 then dispatch clearUser
						if (error.response && error.response.status === 401) {
							dispatch('auth/clearUser', null, { root: true })
							resolve(error)
						}else{
							console.error('Error fetching unread count:', error)
							reject(error)
						}
					})
			}else{
				commit('setUnreadCount', 0)
				resolve()
			}
		})
	},
	setArticleAsRead({ dispatch }, id) {
		// console.log('Setting article as read...')
		return new Promise((resolve, reject) => {
			axios.post(`/api/news/${id}/read`)
				.then(response => {
					// console.log('article read', response.data)
					dispatch('getUnreadCount')
					resolve(response)
				})
				.catch(error => {
					// if 401 then dispatch clearUser
					if (error.response && error.response.status === 401) {
						dispatch('auth/clearUser', null, { root: true })
						resolve(error)
					}else{
						console.error('Error setting article as read:', error)
						reject(error)
					}
				})
		})
	}
}


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}