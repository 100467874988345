<template>
	<div 	
		class="page-news-article"
		:class="[
			highContrastEnabled ? 'high-contrast' : null,
			`text-size-${textSize}`
		]"
		>

		<div class="featured-image-container">
			<div class="featured-image">
				<AtomImage 
					v-if="loading"
					:absolute="true"
					:loading="loading"
					/>
				<AtomImage 
					v-else
					:src="newsArticle ? featuredImage : null"
					:absolute="true"
					:cover="true"
					/>

			</div>
		</div>

		<div class="typography-styles">
			
				<div 
					class="article-header"
					:class="{ sticky: articleHeaderIsSticky }"
					ref="articleHeading"
					>
					
					<AtomIcon
						icon="arrow-left"
						:color="highContrastEnabled ? (articleHeaderIsSticky ? 'black' : 'white') : 'grey'"
						size="medium"
						@click="backToNews"
						/>
					<AtomIcon
						icon="font-size"
						:color="readabilityPanelOpen ? 'orange' : (highContrastEnabled ? (articleHeaderIsSticky ? 'black' : 'grey') : 'grey')"
						size="medium"
						@click="toggleReadabilityPanel"
						/>
					
					<div class="third-col">
						<AtomIcon
							v-if="!loading && newsArticle && newsArticle.external_link"
							icon="share"
							:color="highContrastEnabled ? (articleHeaderIsSticky ? 'black' : 'white') : 'grey'"
							size="medium"
							@click="shareArticle"
							/>
					</div>
					
				</div>

			<div class="container">
				<div 
					v-if="loading"
					class="news-title typography-styles"
					>
					<AtomLoadingText
						:lines="1"
						/>
				</div>
				<div 
					v-if="!loading && newsArticle.name"
					class="news-title"
					>
					{{ newsArticle.name }}
				</div>
			</div>

			

			<div class="blocks-container">
				<template v-if="loading">

					<div class="container">
						<AtomLoadingText
							v-for="i in 8"
							:key="i"
							:lines="12"
							/>
					</div>

				</template>
				<template v-else>

					<div 
						v-if="!newsArticle || newsArticle.status != 'published'"
						class="no-content container"
						>
						News article not available
					</div>

					<template v-else>

						<AtomContentBlock
							v-for="(block, index) in newsArticle.blocks"
							:key="index"
							:block="block"
							/>

					</template>

				</template>

			</div>

			<div 
				v-if="!loading && newsArticle && newsArticle.external_link"
				class="container external-link-button"
				>
				<a 
					:href="newsArticle.external_link" 
					target="_blank"
					>
					<AtomButton 
						label="View in web browser" 
						color="orange"
						/>
				</a>
			</div>
			
		</div>
	</div>
</template>

<script>

import { mapActions, mapGetters, mapMutations } from 'vuex'
import { Share } from '@capacitor/share'

import AtomLoadingText from '@/components/atoms/AtomLoadingText.vue'
import AtomIcon from '@/components/atoms/AtomIcon.vue'
import AtomImage from '@/components/atoms/AtomImage.vue'
import AtomContentBlock from '@/components/atoms/AtomContentBlock.vue'
import AtomButton from '@/components/atoms/AtomButton.vue'

export default {
	name: 'PageNewsArticle',
	components: {
		AtomLoadingText,
		AtomIcon,
		AtomImage,
		AtomContentBlock,
		AtomButton
	},
	data: () => {
		return {
			loading: true,
			articleID: null,
			articleHeaderIsSticky: false,
			layoutOuter: null,
			initialArticleHeadingOffset: null,
		}
	},
	mounted() {
		
		this.setActiveFooterIcon('news')

		this.layoutOuter = document.querySelector('.layout-main-outer')
		
		// add scroll event listener
		this.layoutOuter.addEventListener('scroll', this.isArticleHeadingSticky)

		this.initializeArticle()

		setTimeout(() => {
			this.markAsRead()
		}, 2000)

	},
	beforeUnmount() {
		// remove scroll event listener
		this.layoutOuter.removeEventListener('scroll', this.isArticleHeadingSticky)

		this.setSearchPanelOpen(false)
	},
	methods: {
		...mapActions({
			getNewsArticle: 'news/getNewsArticle',
			setArticleAsRead: 'news/setArticleAsRead'
		}),
		...mapMutations({
			setSearchPanelOpen: 'layout/setSearchPanelOpen',
			setReadabilityPanelOpen: 'layout/setReadabilityPanelOpen',
			setActiveFooterIcon: 'layout/setActiveFooterIcon'
		}),
		initializeArticle() {
			// get the id from the route params
			this.loading = true
			// smoothly scroll to the top of the page
			this.layoutOuter.scrollTo({ top: 0, behavior: 'smooth' })
			
			this.articleID = this.$route.params.id
			this.getNewsArticle(this.articleID).then(() => {
				this.loading = false
				// console.log('newsArticle', this.newsArticle)
			})
		},
		isArticleHeadingSticky() {
			const articleHeading = this.$refs.articleHeading

			if(this.initialArticleHeadingOffset == null){
				this.initialArticleHeadingOffset = articleHeading.offsetTop
			}else{
				if (articleHeading && this.layoutOuter) {
					let isSticky = articleHeading.offsetTop != this.initialArticleHeadingOffset
					this.articleHeaderIsSticky = isSticky
				}
			}
		},
		markAsRead(){
			// if this article is not in the user's 'news_read' array, then add it
			if(this.user && (Array.isArray(this.user.news_read) && !this.user.news_read.includes(parseInt(this.articleID))) || !Array.isArray(this.user.news_read)){
				this.setArticleAsRead(this.articleID)
			}else{
				console.log('Article already read')
			}
		},
		backToNews() {
			this.$router.push('/news')
		},
		toggleReadabilityPanel() {
			this.setReadabilityPanelOpen(!this.readabilityPanelOpen)
		},
		async shareArticle() {
			try {
				await Share.share({
					title: 'Please view this article',
					text: this.newsArticle.name,
					url: this.newsArticle.external_link,
					dialogTitle: 'Share'
				})
			} catch (error) {
				console.log('share cancelled', error)
			}
		},
	},
	watch: {
		'$route.params.id': 'initializeArticle',
	},
	computed: {
		...mapGetters({
			newsArticle: 'news/getCurrentNewsArticle',
			readabilityPanelOpen: 'layout/getReadabilityPanelOpen',
			highContrastEnabled: 'layout/getEnableHighContrast',
			textSize: 'layout/getTextSize',
			user: 'auth/getUser'
		}),
		featuredImage() {
			if(this.newsArticle 
				&& this.newsArticle.uploads 
				&& this.newsArticle.uploads.length > 0 
				&& this.newsArticle.uploads[0].sizes
				&& this.newsArticle.uploads[0].sizes.length > 0
				&& this.newsArticle.uploads[0].sizes.some(size => size.size_name === 'large')
				){
				const largeSize = this.newsArticle.uploads[0].sizes.find(size => size.size_name === 'large');
				if (largeSize) {
					return largeSize.publicUrl;
				}else{
					return null
				}
			}else{
				return null
			}
		},
	}
}
</script>

<style lang="sass">

.search-match-highlight
	background-color: $orange

	&.current-match
		background-color: $yellow

.page-news-article
	display: flex
	flex-direction: column
	border-radius: $borderRadiusLarge $borderRadiusLarge 0 0
	background-color: $lightGrey
	width: 100%
	min-height: $mainContentWindowHeight
	overflow: visible
	padding: 0 0 20px 0
	transition: background-color 300ms

	.external-link-button
		padding: 5px $sidePaddingSmall 20px
		text-align: center

	.featured-image-container
		padding: 10px 10px 0

	.featured-image
		width: 100%
		height: 210px
		background-color: lighten($midGrey, 15%)
		margin-bottom: 0px
		display: flex
		position: relative
		border-radius: $borderRadiusMedium
		overflow: hidden

	.news-title
		display: flex
		gap: 10px
		padding: 0 0 20px 0
		font-family: $mainFontBold
		font-size: 22px

	.article-header
		position: sticky
		top: 0
		z-index: 3
		margin: 0 0 20px
		padding: 0 $sidePaddingSmall
		display: flex
		align-items: center
		justify-content: space-between
		transition: all 300ms
		height: 40px

		&.sticky
			background-color: rgba($white, 0.75)
			backdrop-filter: blur(5px)

		.third-col
			width: 30px
			display: flex
			justify-content: flex-end

	.blocks-container
		padding: 0 0 20px 0
		display: flex
		flex-direction: column
		gap: 5px

	&.high-contrast
		background-color: $bgDarkGrey

		.news-title
			color: $white

	&.text-size-small
		.news-title
			font-size: 22px

	&.text-size-medium
		.news-title
			font-size: 24px

	&.text-size-large
		.news-title
			font-size: 26px



</style>