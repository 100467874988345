<template>
	<div 
		class="atom-input-text"
		:class="[
			'align-' + align,
			clearButton ? 'with-clear-button' : null,
			searchMatchCount !== false ? 'with-search-match-count' : null
		]"
		>

		<label 
			v-if="showLabel"
			:for="modelValue"
			>
			{{ placeholder }}
		</label>

		<div class="input-container">
			<input 
				:type="type"
				:value="modelValue" 
				@input="onInput"
				:placeholder="placeholder"
				/>

			<div 
				v-if="icon"
				class="icon" 
				>
				<AtomIcon 
					:icon="icon"
					color="grey"
					size="small"
					/>
			</div>

			<div 
				v-if="searchMatchCount !== false && modelValue != ''"
				class="search-match-count"
				>
				<div class="match-navigator">
					<AtomIcon 
						v-if="currentMatchNumber > 1"
						icon="chevron-up"
						color="grey"
						size="xsmall"
						@click="prevMatch"
						/>
					<AtomIcon 
						v-if="currentMatchNumber < searchMatchCount"
						icon="chevron-down"
						color="grey"
						size="xsmall"
						@click="nextMatch"
						/>
				</div>

				<div class="match-counts">
					{{ currentMatchNumber }} / {{ searchMatchCount }}
				</div>
			</div>

			<div
				v-if="clearButton"
				class="icon clear"
				@click="clearInput"
				>
				<div class="inner">
					<AtomIcon 
						icon="close"
						color="grey"
						size="full"
						/>
				</div>
			</div>
		</div>

	</div>
</template>

<script>

import AtomIcon from '@/components/atoms/AtomIcon.vue';

export default {
	name: 'AtomInputText',
	components: {
		AtomIcon
	},
	props: {
		modelValue: {
			type: String,
			required: true
		},
		type: {
			type: String,
			default: 'text',
			validator: (value) => ['text', 'email', 'password'].includes(value)
		},
		placeholder: {
			type: String,
			default: ''
		},
		align: {
			type: String,
			default: 'left',
			validator: (value) => ['left', 'center', 'right'].includes(value)
		},
		icon: {
			type: [Boolean, String],
			default: false
		},
		clearButton: {
			type: Boolean,
			default: false
		},
		searchMatchCount: {
			type: [Boolean, Number],
			default: false
		},
		currentMatchIndex: {
			type: [Boolean, Number],
			default: false
		},
		showLabel: {
			type: Boolean,
			default: false
		}
	},
	data: () => {
		return {

		}
	},
	methods: {
		onInput(event) {
			this.$emit('update:modelValue', event.target.value)
		},
		clearInput() {
			this.$emit('update:modelValue', '')
			this.$emit('cleared')
		},
		nextMatch(){
			this.$emit('nextMatch')
		},
		prevMatch(){
			this.$emit('prevMatch')
		}
	},
	computed: {
		currentMatchNumber(){
			if(this.currentMatchIndex !== false){
				return this.currentMatchIndex + 1
			}else{
				return false
			}
		}
	}
};
</script>

<style lang="sass">

.atom-input-text
	width: 100%
	position: relative

	.input-container
		position: relative

	label
		padding: 5px 0 8px 15px
		font-size: 12px
		color: $white
		display: block

	input
		width: 100%
		padding: 0 15px
		border-radius: $borderRadiusMedium
		font-size: 16px
		height: 46px
		border: 1px solid $transGrey2
		background-color: $transGrey1
		line-height: 1
		color: #fff

		&:focus
			outline: none

		&::placeholder
			color: #fff
			opacity: 0.9

	&.with-clear-button
		input
			padding-right: 46px

	&.with-search-match-count
		input
			padding-right: 95px

	&.align-left
		input
			text-align: left

	&.align-center
		input
			text-align: center

	&.align-right
		input
			text-align: right

	.icon
		width: 46px
		height: 46px
		display: flex
		align-items: center
		justify-content: center
		position: absolute
		top: 0
		right: 0
		pointer-events: none
		user-select: none
		outline: none
		-webkit-tap-highlight-color: transparent

		.inner
			width: 13px
			height: 13px

		&.clear
			cursor: pointer
			pointer-events: auto

	.search-match-count
		position: absolute
		top: 0
		right: 40px
		bottom: 0
		color: #fff
		font-size: 13px
		line-height: 1
		display: flex
		align-items: center
		justify-content: center
		gap: 8px

		.match-navigator
			display: flex
			flex-direction: column
			align-items: center
			justify-content: center
			gap: 5px

			

</style>