<template>
	<div class="page-notification-settings page-layout">
		<div class="container">

			<MoleculeHeadingWithIcon
				heading="Notification Settings"
				icon="arrow-left"
				iconColour="white"
				iconRoute="/dashboard"
				iconSide="left"
				/>

			
			<form
				class="form-account-details"
				@submit.prevent="handleDetailsUpdate"
				>
				<div class="form-need-help">
					<strong>Need Help?</strong> <span>Contact us at: <a href="mailto:info@compex-cert.com">info@compex-cert.com</a></span>
				</div>
				<div class="form-tickbox-containers">
					<div class="term-row">
						<span class="tick-label">App Notifications</span>
						<AtomYesNo v-model="form.notifications" />
					</div>
					<div class="term-row">
						<span class="tick-label">eNEWS</span>
						<AtomYesNo v-model="form.enews" />
					</div>
				</div>
			</form>

			<ul 
				class="form-errors"
				v-if="errorLog.length > 0"
				>
				<li 
					class="error-message"
					v-for="(error, key) in errorLog"
					:key="key"
					>
					{{ filterErrorMessage(error) }}
				</li>
			</ul>

			<div 
				v-if="updateMessage"
				class="form-success-message"
				>
				{{ updateMessage }}
			</div>

			<div class="button-container">
				<AtomButton
					label="Update"
					color="orange"
					:loading="formProcessing"
					@click="handleDetailsUpdate"
					/>
			</div>


		</div>
	</div>
</template>

<script>

import { mapGetters, mapActions, mapMutations } from 'vuex'

import AtomYesNo from '@/components/atoms/AtomYesNo.vue'
import AtomButton from '@/components/atoms/AtomButton.vue'

import MoleculeHeadingWithIcon from '@/components/molecules/MoleculeHeadingWithIcon.vue'

export default {
	name: 'PageNotificationSettings',
	components: {
		AtomYesNo,
		AtomButton,
		MoleculeHeadingWithIcon
	},
	data: () => {
		return {
			form: {
				notifications: false,
				enews: false,
			},
			formProcessing: null,
			errorLog: [],
			updateMessage: null,
		}
	},
	mounted(){
		this.setActiveFooterIcon('user')

		this.getProfile()
			.then(() => {
				if(this.getUser && 'app_notifications_enabled' in this.getUser && 'enews_notifications_enabled' in this.getUser){
					this.form.notifications = this.getUser.app_notifications_enabled
					this.form.enews = this.getUser.enews_notifications_enabled
				}
			})


		this.$nextTick(() => {
			this.setHeaderOpacity(1)
		})
	},
	beforeUnmount(){
		this.setHeaderOpacity(0)
	},
	methods: {
		// TODO: subscribe them to notification permissions if form.notifications is true

		...mapMutations({
			setHeaderOpacity: 'layout/setHeaderOpacity',
			setActiveFooterIcon: 'layout/setActiveFooterIcon'
		}),
		...mapActions('auth', {
			updateNotificationSettings: 'updateNotificationSettings',
			getProfile: 'getProfile'
		}),
		handleDetailsUpdate(){
			// clear any previous errors and messages
			this.errorLog = []
			this.updateMessage = null
			
			this.formProcessing = true
			this.updateNotificationSettings(this.form)
				.then((response) => {
					this.formProcessing = false

					this.updateMessage = response.data.message

					this.getProfile()

				})
				.catch((error) => {
					this.formProcessing = false
					this.errorLog.push(error)
				})
			
		},
		filterErrorMessage(error){
			console.log('error', JSON.stringify(error))
			if(typeof error === 'string' && error.includes('AxiosError: ')){
				return error.replace('AxiosError: ', '')
			}else if(typeof error === 'object' && error.message){
				return error.message
			}else{
				return error
			}
		}
	},
	computed: {
		...mapGetters('auth', {
			getToken: 'getToken',
			getUser: 'getUser',
			isAuthenticated: 'isAuthenticated',
		}),
	},
}

</script>

<style lang="sass">

.page-notification-settings
	padding-top: 20px

	.form-account-details
		display: flex
		flex-direction: column
		gap: 10px
		padding: 30px 0 20px 0

	.form-input
		position: relative

	.form-errors
		display: flex
		flex-direction: column
		gap: 10px
		padding: 15px
		background-color: rgba($red, 0.5)
		border-radius: $borderRadiusMedium
		border: 1px solid $transGrey2

		.error-message
			color: $white
			font-size: 14px
			font-family: $mainFontBold

	.form-success-message
		display: flex
		flex-direction: column
		gap: 10px
		padding: 15px
		background-color: rgba($green, 0.5)
		border-radius: $borderRadiusMedium
		border: 1px solid $transGrey2
		color: $white
		font-size: 14px
		font-family: $mainFontBold

	.button-container
		display: flex
		justify-content: center
		margin: 20px 0 calc(40px + $appPadBottom)

	.form-tickbox-containers
		display: flex
		flex-direction: column
		gap: 15px
		padding: 0

		.term-row
			display: flex
			justify-content: space-between
			align-items: center
			gap: 10px

			.tick-label
				font-size: 14px
				color: $white

			a
				text-decoration: underline

	.form-need-help
		display: flex
		justify-content: space-between
		color: #fff
		font-size: 12px
		margin: 5px 0 15px
		padding: 0
		gap: 10px
		align-items: center

		strong
			font-weight: normal
			font-family: $mainFontBold
			font-size: 16px

		a
			text-decoration: underline

</style>