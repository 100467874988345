<template>
	<div class="page-forgot-password page-layout">
		<div class="container">

			<MoleculeHeadingWithIcon
				heading="Forgot Password"
				icon="arrow-left"
				iconColour="white"
				iconRoute="/login"
				iconSide="left"
				/>

			<template v-if="!resetComplete">
				<form
					class="form-password-reset"
					@submit.prevent="handleReset"
					>
					<div class="form-input">
						<AtomInputText
							v-model="form.email"
							placeholder="Enter Your Email"
							align="center"
							/>
					</div>
				</form>

				<ul 
					class="form-errors"
					v-if="(v$.form.$error && v$.form.$errors.length > 0) || errorLog.length > 0"
					>
					<template v-if="errorLog.length > 0">
						<li 
							class="error-message"
							v-for="(error, key) in errorLog"
							:key="key"
							>
							{{ filterErrorMessage(error) }}
						</li>
					</template>
					<template v-if="v$.form.$error && v$.form.$errors.length > 0">
						<li 
							class="error-message"
							v-for="(error, key) in v$.form.$errors"
							:key="key"
							>
							{{ error.$message }}
						</li>
					</template>
				</ul>

				<div class="button-container">
					<AtomButton
						label="Reset Password"
						:color="resetButtonColour"
						:loading="resetLoading"
						@click="handleReset"
						/>
				</div>

			</template>

			<template v-else>

				<div class="password-reset-message">
					<p>
						If the email address you entered is registered with us, we will send you an email with instructions on how to reset your password.
					</p>
					
					<div class="button-container">
						<AtomButton
							label="Sign In"
							color="trans-grey"
							@click="$router.push({ name: 'Login' })"
							/>
					</div>
				</div>

			</template>

			

		</div>
	</div>
</template>

<script>

import { mapActions } from 'vuex'
import useVuelidate from "@vuelidate/core"
import { required, email, helpers } from "@vuelidate/validators"

import AtomInputText from '@/components/atoms/AtomInputText.vue'
import AtomButton from '@/components/atoms/AtomButton.vue'

import MoleculeHeadingWithIcon from '@/components/molecules/MoleculeHeadingWithIcon.vue'

export default {
	name: 'PageLogin',
	components: {
		AtomInputText,
		AtomButton,
		MoleculeHeadingWithIcon
	},
	setup: () => ({ v$: useVuelidate() }),
	data: () => {
		return {
			form: {
				email: '',
			},
			resetLoading: null,
			errorLog: [],
			resetComplete: false,
		}
	},
	validations() {

		return {
			form: {
				email: { 
					required: helpers.withMessage('Email cannot be empty', required), 
					email: helpers.withMessage('Email must be valid', email)
				},
			},
		}

	},
	methods: {
		...mapActions('auth', {
			forgotPassword: 'forgotPassword',
		}),
		handleReset(){
			// clear any previous errors
			this.errorLog = []
			// touch the form to trigger validation
			this.v$.$touch()
			if (!this.v$.$invalid && !this.resetLoading) {
				this.resetLoading = true
				this.forgotPassword(this.form)
					.then((response) => {
						this.resetLoading = false
						this.resetComplete = true

						console.log('forgotten password response', response)

					})
					.catch((error) => {
						this.resetLoading = false
						this.errorLog.push(error)
					})
			}else{
				// well, they failed then. Vuelidate will give a message, so we don't need to do anything here.
			}
		},
		filterErrorMessage(error){
			console.log('error', JSON.stringify(error))
			if(typeof error === 'string' && error.includes('AxiosError: ')){
				return error.replace('AxiosError: ', '')
			}else if(typeof error === 'object' && error.message){
				return error.message
			}else{
				return error
			}
		}
	},
	computed: {
		resetButtonColour(){
			// when the login fields are valid, the button should be orange to indicate that they can proceed.
			if(this.v$.$invalid){
				return 'trans-grey'
			}else{
				return 'orange'
			}
		},
	},
}

</script>

<style lang="sass">

.page-forgot-password
	.form-password-reset
		display: flex
		flex-direction: column
		gap: 10px
		padding: 30px 0 20px 0

	.form-input
		position: relative

	.password-reset-message
		font-size: 16px
		color: $white
		text-align: center
		padding: 30px 0 40px
		display: flex
		flex-direction: column
		gap: 20px
		line-height: 1.2

	.form-errors
		display: flex
		flex-direction: column
		gap: 10px
		padding: 15px
		background-color: rgba($red, 0.5)
		border-radius: $borderRadiusMedium
		border: 1px solid $transGrey2

		.error-message
			color: $white
			font-size: 14px
			font-family: $mainFontBold

	.button-container
		display: flex
		justify-content: center
		margin: 20px 0 calc(40px + $appPadBottom)

</style>