import axios from 'axios'
import { 
	getDocumentFromDB, 
	saveDocumentToDB, 
	getAllDocumentsFromDB, 
	saveAllDocumentsToDB,
	getAllTagsFromDB,
	saveAllTagsToDB,
	getAllBookmarksFromDB,
	saveAllBookmarksToDB,
	getChapterFromDB,
	saveChapterToDB,
	doesChapterExistInDB
} from '@/store/indexedDB'

const state = () => ({
	documents: [],
	document: null,
	chapter: null,
	chapterSearchTerm: null,
	chapterSearchMatchCount: 0,
	canSearchChapters: false,
	tags: [],
	activeFilters: [],
	bookmarks: []
})

const getters = {
	getDocuments: state => state.documents,
	getDocument: state => state.document,
	getChapter: state => state.chapter,
	getChapterSearchTerm: state => state.chapterSearchTerm,
	getChapterSearchMatchCount: state => state.chapterSearchMatchCount,
	canSearchChapters: state => state.canSearchChapters,
	getTags: state => state.tags,
	getActiveFilters: state => state.activeFilters,
	getBookmarks: state => state.bookmarks
}

const mutations = {
	setDocuments(state, documents) {
		state.documents = documents
		console.log('documents set', documents)
	},
	setDocument(state, document) {
		state.document = document
	},
	setChapter(state, chapter) {
		state.chapter = chapter
	},
	setChapterSearchTerm(state, searchTerm) {
		state.chapterSearchTerm = searchTerm
	},
	setChapterSearchMatchCount(state, matchCount) {
		state.chapterSearchMatchCount = matchCount
	},
	setCanSearchChapters(state, canSearch) {
		state.canSearchChapters = canSearch
	},
	setTags(state, tags) {
		state.tags = tags
	},
	setActiveFilters(state, filters) {
		state.activeFilters = filters
	},
	setBookmarks(state, bookmarks) {
		state.bookmarks = bookmarks
		// console.log('bookmarks set', bookmarks)
	}
}

const actions = {
	getDocuments({ commit, dispatch, rootGetters }) {
		const isOnline = rootGetters['layout/getIsOnline'];

		return new Promise((resolve) => {
			if (isOnline) {
				console.log('getDocuments online')
				axios.get('/api/documents')
					.then(async response => {
						commit('setDocuments', response.data)
						console.log('documents', response.data)
						await saveAllDocumentsToDB(response.data);
						resolve(response)
					})
					.catch(error => {
						// if 401 then dispatch clearUser
						if (error.response && error.response.status === 401) {
							dispatch('auth/clearUser', null, { root: true })
							resolve(error)
						}else{
							console.log('Error fetching documents:', error)
							resolve(error)
						}
					})
			}else{
				(async () => {
					console.log('getDocuments offline')
					const documents = await getAllDocumentsFromDB()
					commit('setDocuments', documents)
					resolve(documents)
				})()
			}
		})
	},
	getDocument({ commit, dispatch, rootGetters }, id) {
		const isOnline = rootGetters['layout/getIsOnline'];

		return new Promise((resolve) => {
			if (isOnline) {
				axios.get(`/api/documents/${id}`)
					.then(async response => {
						console.log('document', response.data)

						// go through each chapter in document and check if it exists in indexedDB, if it does then 'availableOffline' = true, else false
						const chapters = response.data.chapters.map(chapter => {
							(async () => {
								const exists = await doesChapterExistInDB(chapter.id)
								chapter.availableOffline = exists
							})()
							return chapter
						})

						response.data.chapters = chapters

						commit('setDocument', response.data)
						await saveDocumentToDB(response.data)
						resolve(response.data)
					})
					.catch(error => {
						// if 401 then dispatch clearUser
						if (error.response && error.response.status === 401) {
							dispatch('auth/clearUser', null, { root: true })
							resolve(error)
						}else{
							console.log('Error fetching document:', error)
							resolve(error)
						}
					})
			}else{
				(async () => {
					const document = await getDocumentFromDB(id)
					// go through each chapter in document and check if it exists in indexedDB, if it does then 'availableOffline' = true, else false
					const chapters = document.chapters.map(chapter => {
						(async () => {
							const exists = await doesChapterExistInDB(chapter.id)
							chapter.availableOffline = exists
						})()
						return chapter
					})

					document.chapters = chapters

					console.log('document from db', {
						id: id,
						document: document
					})
					if(!document){
						console.error('Document not found in indexedDB')
						resolve(null)
					}else{
						commit('setDocument', document)
						resolve(document)
					}
				})()
			}
		})
	},
	getChapter({ commit, dispatch, rootGetters }, id) {
		// console.log('Fetching chapter with id:', id)
		const isOnline = rootGetters['layout/getIsOnline'];

		return new Promise((resolve, reject) => {
			if(isOnline){
				axios.get(`/api/chapters/${id}`)
					.then(response => {
						console.log('chapter', response.data)
						commit('setChapter', response.data)
						const chapterWithId = {
							...response.data,
							id: parseInt(id)
						}
						saveChapterToDB(chapterWithId)
						resolve(response.data)
					})
					.catch(error => {
						// if 401 then dispatch clearUser
						if (error.response && error.response.status === 401) {
							dispatch('auth/clearUser', null, { root: true })
							resolve(error)
						}else{
							console.error('Error fetching chapter:', error)
							reject(error)
						}
					})
			}else{
				(async () => {
					const chapter = await getChapterFromDB(id)
					// console.log('chapter from db', {
					// 	id: id,
					// 	chapter: chapter
					// })
					if(!chapter){
						console.error('Chapter not found in indexedDB')
						resolve(null)
					}else{
						commit('setChapter', chapter)
						resolve(chapter)
					}
				})()
			}
		})
	},
	async doesChapterExistOffline(_, id) {
		const exists = await doesChapterExistInDB(id);
		return exists;
	},
	searchApp({ dispatch }, searchTerm) {
		return new Promise((resolve) => {

			console.log('Searching for:', searchTerm)

			axios.post('/api/search', { 
				query: searchTerm 
			})
				.then(response => {
					console.log('search results:', response.data)
					resolve(response.data)
				})
				.catch(error => {
					// if 401 then dispatch clearUser
					if (error.response && error.response.status === 401) {
						dispatch('auth/clearUser', null, { root: true })
						resolve(error)
					}else{
						console.error('Error searching:', error)
					}
				})

		})

	},
	getTags({ commit, dispatch, rootGetters }) {
		
		const isOnline = rootGetters['layout/getIsOnline'];

		return new Promise((resolve) => {
			if (isOnline) {
				axios.get('/api/tags')
					.then(async response => {
						commit('setTags', response.data)
						// console.log('tags', response.data)
						await saveAllTagsToDB(response.data)
						resolve(response)
					})
					.catch(error => {
						// if 401 then dispatch clearUser
						if (error.response && error.response.status === 401) {
							dispatch('auth/clearUser', null, { root: true })
							resolve(error)
						}else{
							console.error('Error fetching tags:', error)
							resolve(error) // don't reject, just resolve with the error
						}
					})

			}else{
				(async () => {
					const tags = await getAllTagsFromDB()
					commit('setTags', tags)
					resolve(tags)
				})()
			}
		})
	},
	getBookmarks({ commit, dispatch, rootGetters }) {
		const isOnline = rootGetters['layout/getIsOnline'];

		return new Promise((resolve) => {
			if(isOnline){
				axios.get('/api/bookmarks')
					.then(response => {
						commit('setBookmarks', response.data.bookmarks)
						// console.log('bookmarks', response.data.bookmarks)
						// save to indexedDB
						saveAllBookmarksToDB(response.data.bookmarks)
						resolve(response)
					})
					.catch(error => {
						// if 401 then dispatch clearUser
						if (error.response && error.response.status === 401) {
							dispatch('auth/clearUser', null, { root: true })
							resolve(error)
						}else{
							console.error('Error fetching bookmarks:', error)
							resolve(error) // don't reject, just resolve with the error
						}
					})

			}else{
				(async () => {
					const bookmarks = await getAllBookmarksFromDB()
					commit('setBookmarks', bookmarks)
					resolve(bookmarks)
				})()
			}
		})
	},
	bookmarkDocument({ commit, dispatch, rootGetters }, documentId) {
		const isOnline = rootGetters['layout/getIsOnline'];

		return new Promise((resolve) => {
			if(isOnline){
				axios.post('/api/bookmark/document', { documentId: documentId })
					.then(response => {
						commit('setBookmarks', response.data.bookmarks)
						resolve(response)
					})
					.catch(error => {
						// if 401 then dispatch clearUser
						if (error.response && error.response.status === 401) {
							dispatch('auth/clearUser', null, { root: true })
							resolve(error)
						}else{
							console.error('Error adding bookmark:', error)
							resolve(error) // don't reject, just resolve with the error
						}
					})
			}else{
				console.log('Cannot bookmark document offline')
				resolve()
			}
		})
	},
	bookmarkChapter({ commit, dispatch, rootGetters }, chapterId) {
		const isOnline = rootGetters['layout/getIsOnline'];
		return new Promise((resolve) => {
			if(isOnline){
				axios.post('/api/bookmark/chapter', { chapterId: chapterId })
					.then(response => {
						commit('setBookmarks', response.data.bookmarks)
						resolve(response)
					})
					.catch(error => {
						// if 401 then dispatch clearUser
						if (error.response && error.response.status === 401) {
							dispatch('auth/clearUser', null, { root: true })
							resolve(error)
						}else{
							console.error('Error adding bookmark:', error)
							resolve(error) // don't reject, just resolve with the error
						}
					})
			}else{
				console.log('Cannot bookmark chapter offline')
				resolve()
			}
		})
	}
}


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}