<template>
	<div class="dashboard">
		<div class="container typography-styles">

			<template v-if="bookmarks.length > 0">
				<AtomHeading 
					color="grey" 
					align="left"
					size="l"
					weight="normal"
					>
					Bookmarks
				</AtomHeading>

				<div class="bookmark-slider">

					<div 
						class="slide-track"
						:style="{ width: slideTrackWidth }"
						>
						<template
							v-for="(bookmark, index) in bookmarks"
							:key="index"
							>
							<div class="bookmark-slide-element">
								<MoleculeDocumentCard
									v-if="bookmark.bookmarkable_type === 'App\\Models\\Document'"
									:document="bookmark.bookmarkable"
									:showDescription="true"
									:tallerImage="true"
									:fullHeight="true"
									@click="gotoDocument(bookmark.bookmarkable)"
									/>
								<MoleculeChapterCard
									v-else-if="bookmark.bookmarkable_type === 'App\\Models\\Chapter'"
									:chapter="bookmark.bookmarkable"
									:document="bookmark.bookmarkable.document"
									:showDescription="true"
									:tallerImage="true"
									:fullHeight="true"
									@click="gotoChapter(bookmark.bookmarkable)"
									/>
							</div>
						</template>
					</div>

				</div>
			</template>

			<AtomHeading 
				color="grey" 
				align="left"
				size="l"
				weight="normal"
				:spread="true"
				>
				Documents

				<div 
					class="filter-icon-container"
					@click="setFilterDocsPanelOpen(true)"
					>
					<div 
						v-if="activeFilters.length > 0"
						class="filter-count"
						>
						{{ activeFilters.length }}
					</div>

					<AtomIcon
						icon="filter"
						:color="activeFilters.length > 0 ? 'orange' : 'grey'"
						size="small"
						/>
				</div>
			</AtomHeading>

			<div class="documents-list">

				<template v-if="loading">

					<MoleculeDocumentCard 
						v-for="index in 6"
						:key="index"
						:loading="true"
						/>

				</template>
				<template v-else>

					<div 
						v-if="!filteredDocuments || filteredDocuments.length === 0"
						class="no-content span-2"
						>
						<span v-if="activeFilters.length > 0">
							No documents available with the selected filters
						</span>
						<span v-else>
							No documents available
						</span>
					</div>

					<template v-else>

						<MoleculeDocumentCard 
							v-for="document in filteredDocuments" 
							:key="document.id"
							:document="document"
							:showDescription="true"
							@click="gotoDocument(document)"
							/>

					</template>

				</template>

			</div>
			
			
		</div>
	</div>
</template>

<script>

import { mapActions, mapGetters, mapMutations } from 'vuex'

import AtomIcon from '@/components/atoms/AtomIcon.vue'
import AtomHeading from '@/components/atoms/AtomHeading.vue'
import MoleculeDocumentCard from '@/components/molecules/MoleculeDocumentCard.vue'
import MoleculeChapterCard from '@/components/molecules/MoleculeChapterCard.vue'

export default {
	name: 'PageDashboard',
	components: {
		AtomIcon,
		AtomHeading,
		MoleculeDocumentCard,
		MoleculeChapterCard
	},
	data: () => {
		return {
			loading: true,
			layoutOuter: null,
		}
	},
	mounted() {

		this.setActiveFooterIcon('home')

		this.getDocuments().then(() => {
			this.loading = false
			this.getBookmarks()
		})

		this.layoutOuter = document.querySelector('.layout-main-outer')
		this.layoutOuter.scrollTo({ top: 0, behavior: 'smooth' })
	},
	methods: {
		...mapActions('auth', {
			logout: 'logout',
		}),
		...mapActions('documents', {
			getDocuments: 'getDocuments',
			getBookmarks: 'getBookmarks'
		}),
		...mapMutations({
			setActiveFooterIcon: 'layout/setActiveFooterIcon',
			setFilterDocsPanelOpen: 'layout/setFilterDocsPanelOpen',
		}),
		gotoDocument(document) {
			// router push to document id
			this.$router.push({ name: 'Document', params: { id: document.id } })
		},
		gotoChapter(chapter) {
			// router push to chapter id
			if(this.isOnline){
				this.$router.push({ name: 'Chapter', params: { id: chapter.id } })
			}else if(!this.isOnline && chapter.availableOffline){
				this.$router.push({ name: 'Chapter', params: { id: chapter.id } })
			}else{
				console.log('Chapter not available offline')
			}
		},
	},
	computed: {
		...mapGetters({
			documents: 'documents/getDocuments',
			activeFilters: 'documents/getActiveFilters',
			bookmarks: 'documents/getBookmarks',
			isOnline: 'layout/getIsOnline'
		}),
		filteredDocuments(){
			if(this.documents.length === 0){
				return []
			}
			if(this.activeFilters.length === 0){
				return this.documents
			}else{
				const activeFilterNames = this.activeFilters.map(filter => filter.name);
				return this.documents.filter(document => {
					return document.tags.some(tag => activeFilterNames.includes(tag))
				})
			}
		},
		slideTrackWidth() {
			const bookmarkCount = this.bookmarks.length
			const widthVW = bookmarkCount * 55
			const gapPX = bookmarkCount * 12
			return `calc(${widthVW}vw + ${gapPX}px)`
		}
	}
}
</script>

<style lang="sass">

.dashboard
	display: flex
	border-radius: $borderRadiusLarge $borderRadiusLarge 0 0
	background-color: $lightGrey
	width: 100%
	min-height: $mainContentWindowHeight
	overflow: hidden
	padding: 15px 0

	.documents-list
		padding: 15px 0
		display: grid
		grid-template-columns: repeat(2, 1fr)
		gap: 12px

		.span-2
			grid-column: span 2

	.filter-icon-container
		display: flex
		align-items: center
		gap: 5px

		.filter-count
			background-color: $orange
			color: $white
			border-radius: 15px
			padding: 2px 5px
			font-size: 12px
			font-family: $mainFontBold
			line-height: 1
			white-space: nowrap

	.bookmark-slider
		display: block
		overflow-x: auto
		margin: 0 calc($sidePaddingSmall * -1) 10px
		padding: 15px 0 10px 0
		-webkit-overflow-scrolling: touch
		
		// hide scrollbar
		&::-webkit-scrollbar
			display: none

		&::-webkit-scrollbar-thumb
			display: none

		&::-webkit-scrollbar-track
			display: none

		&::-webkit-scrollbar-button
			display: none

		&::-webkit-scrollbar-corner
			display: none
		

		.slide-track
			display: flex
			gap: 12px
			padding: 0 $sidePaddingSmall

		.bookmark-slide-element
			width: 55vw

	


</style>