<template>
	<div 
		class="atom-search-type-toggle"
		:class="{
			'app-only': appOnly
		}"
		@click="toggleInput"
		>
		<div class="toggle-inner-display">
			<div 
				class="toggle-option orange"
				:class="{
					'active': modelValue === 'article'
				}"
				>
				Article
			</div>
			<div 
				class="toggle-option grey"
				:class="{
					'active': modelValue === 'app'
				}"
				>
				App
			</div>

			<div 
				class="toggle-knob" 
				:class="[
					`value-${modelValue}`,
					appOnly ? 'hide' : null
				]"
				/>
		</div>
	</div>
</template>

<script>

export default {
	name: 'AtomSearchTypeToggle',
	props: {
		modelValue: {
			type: String,
			default: 'app',
			required: true
		},
		appOnly: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		toggleInput() {
			if(this.appOnly) return

			let value = this.modelValue === 'app' ? 'article' : 'app'
			this.$emit('update:modelValue', value)
		}
	},
	watch: {
		appOnly(newVal) {
			// if the selection was on article and then the value of appOnly changed to true, set to app.
			if(newVal == true && this.modelValue === 'article'){
				this.$emit('update:modelValue', 'app')
			}
		}
	}
}

</script>

<style lang="sass">

.atom-search-type-toggle
	width: 70px
	height: 40px
	display: flex
	align-items: center

	.toggle-inner-display
		display: flex
		align-items: center
		position: relative
		width: 70px
		height: 22px

	.toggle-option
		font-size: 11px
		color: $white
		line-height: 1
		font-family: $mainFontBold
		font-weight: bold
		position: absolute
		inset: 0
		display: flex
		align-items: center
		justify-content: center
		text-align: center
		border-radius: 14px
		transition: all 300ms
		opacity: 0

		&.grey
			background-color: $midGrey
			padding-left: 18px

		&.orange
			background-color: $orange
			padding-right: 18px

		&.active
			opacity: 1

	.toggle-knob
		width: 22px
		height: 22px
		border-radius: 50%
		background-color: $white
		position: absolute
		transition: all 300ms
		opacity: 1

		&.value-article
			left: 50px
		
		&.value-app
			left: 0

		&.hide
			visibility: hidden
			opacity: 0

	&.app-only
		.toggle-option
			&.grey, &.orange
				padding: 0

</style>