import { createWebHistory, createRouter } from "vue-router"
import store from './store/store'

import LayoutUnAuth from "@/layouts/LayoutUnAuth.vue"
import LayoutMain from "@/layouts/LayoutMain.vue"

import PageStart from "@/views/PageStart.vue"
import PageLogin from "@/views/PageLogin.vue"
import PageRegister from "@/views/PageRegister.vue"
import PageDashboard from "@/views/PageDashboard.vue"
import PageDocument from "@/views/PageDocument.vue"
import PageChapter from "@/views/PageChapter.vue"
import PageForgotPassword from "@/views/PageForgotPassword.vue"
import PageAccount from "@/views/PageAccount.vue"
import PageChangePassword from "@/views/PageChangePassword.vue"
import PageNotificationSettings from "@/views/PageNotificationSettings.vue"
import PageRateShareApp from "./views/PageRateShareApp.vue"
import PageDeleteAccount from "./views/PageDeleteAccount.vue"
import PageFindACentre from "./views/PageFindACentre.vue"
import PageNews from "./views/PageNews.vue"
import PageNewsArticle from "./views/PageNewsArticle.vue"
import PageQuickLinks from "./views/PageQuickLinks.vue"
import PageContact from "./views/PageContact.vue"
import PageAbout from "./views/PageAbout.vue"

const routes = [
	{
		path: "/",
		component: LayoutUnAuth,
		children: [
			{
				path: '',
				name: "Start",
				component: PageStart,
			}
		]
	},
	{
		path: "/login",
		component: LayoutUnAuth,
		children: [
			{
				path: '',
				name: "Login",
				component: PageLogin,
			}
		]
	},
	{
		path: "/register",
		component: LayoutUnAuth,
		children: [
			{
				path: '',
				name: "Register",
				component: PageRegister,
			}
		]
	},
	{
		path: "/forgot-password",
		component: LayoutUnAuth,
		children: [
			{
				path: '',
				name: "ForgotPassword",
				component: PageForgotPassword,
			}
		]
	},
	{
		path: "/dashboard",
		component: LayoutMain,
		children: [
			{
				path: '',
				name: "Dashboard",
				component: PageDashboard,
				meta: { requiresAuth: true }
			}
		]
	},
	{
		path: "/document/:id",
		component: LayoutMain,
		children: [
			{
				path: '',
				name: "Document",
				component: PageDocument,
				meta: { requiresAuth: true }
			}
		]
	},
	{
		path: "/chapter/:id",
		component: LayoutMain,
		children: [
			{
				path: '',
				name: "Chapter",
				component: PageChapter,
				meta: { requiresAuth: true }
			}
		]
	},
	{
		path: "/account",
		component: LayoutMain,
		children: [
			{
				path: '',
				name: "Account",
				component: PageAccount,
				meta: { requiresAuth: true }
			}
		]
	},
	{
		path: "/account/change-password",
		component: LayoutMain,
		children: [
			{
				path: '',
				name: "ChangePassword",
				component: PageChangePassword,
				meta: { requiresAuth: true }
			}
		]
	},
	{
		path: "/account/notification-settings",
		component: LayoutMain,
		children: [
			{
				path: '',
				name: "NotificationSettings",
				component: PageNotificationSettings,
				meta: { requiresAuth: true }
			}
		]
	},
	{
		path: "/account/delete",
		component: LayoutMain,
		children: [
			{
				path: '',
				name: "DeleteAccount",
				component: PageDeleteAccount,
				meta: { requiresAuth: true }
			}
		]
	},
	{
		path: "/rate-share-app",
		component: LayoutMain,
		children: [
			{
				path: '',
				name: "RateShareApp",
				component: PageRateShareApp,
				meta: { requiresAuth: true }
			}
		]
	},
	{
		path: "/find-a-centre",
		component: LayoutMain,
		children: [
			{
				path: '',
				name: "FindACentre",
				component: PageFindACentre,
				meta: { requiresAuth: true }
			}
		]
	},
	{
		path: "/news",
		component: LayoutMain,
		children: [
			{
				path: '',
				name: "News",
				component: PageNews,
				meta: { requiresAuth: true }
			}
		]
	},
	{
		path: "/news/:id",
		component: LayoutMain,
		children: [
			{
				path: '',
				name: "NewsArticle",
				component: PageNewsArticle,
				meta: { requiresAuth: true }
			}
		]
	},
	{
		path: "/quick-links",
		component: LayoutMain,
		children: [
			{
				path: '',
				name: "QuickLinks",
				component: PageQuickLinks,
				meta: { requiresAuth: true }
			}
		]
	},
	{
		path: "/contact",
		component: LayoutMain,
		children: [
			{
				path: '',
				name: "Contact",
				component: PageContact,
				meta: { requiresAuth: true }
			}
		]
	},
	{
		path: "/about",
		component: LayoutMain,
		children: [
			{
				path: '',
				name: "About",
				component: PageAbout,
				meta: { requiresAuth: true }
			}
		]
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

// Global navigation guard
router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		// Check if the user is authenticated
		if (!store.getters['auth/isAuthenticated']) {
			// Redirect to the login page
			next({ name: 'Login' });
		} else {
			next();
		}
	} else if (to.name === 'Login' && store.getters['auth/isAuthenticated']) {
		// If the user is already authenticated and trying to access the login page, redirect to the dashboard
		next({ name: 'Dashboard' }); // TODO: Change this to the desired route after login, if not then dashboard
	} else {
		next();
	}
});

export default router