<template>
	<div 	
		class="page-chapter"
		:class="[
			highContrastEnabled ? 'high-contrast' : null,
			`text-size-${textSize}`
		]"
		>

		<div class="featured-image">
			<AtomImage 
				v-if="loading"
				:absolute="true"
				:loading="loading"
				/>
			<AtomImage 
				v-else
				:src="chapter.chapter ? featuredImage : null"
				:absolute="true"
				:cover="true"
				/>

			<div 
				v-if="!loading && chapter.chapter.name"
				class="title-overlay"
				:class="[
					featuredImage != null ? 'darker' : ''
				]"
				>
				<AtomHeading 
					color="white" 
					align="left"
					size="l"
					weight="normal"
					>
					{{ chapter.chapter.name }}
				</AtomHeading>
			</div>
		</div>

		<div class="typography-styles">
			
				<div 
					class="chapter-header"
					:class="{ sticky: chapterHeaderIsSticky }"
					ref="chapterHeading"
					>
					
					<AtomIcon
						icon="arrow-left"
						:color="highContrastEnabled ? (chapterHeaderIsSticky ? 'black' : 'white') : 'grey'"
						size="medium"
						@click="backToDocument"
						/>
					<AtomIcon
						icon="font-size"
						:color="readabilityPanelOpen ? 'orange' : (highContrastEnabled ? (chapterHeaderIsSticky ? 'black' : 'grey') : 'grey')"
						size="medium"
						@click="toggleReadabilityPanel"
						/>
					
					<div 
						class="bookmark-icon"
						:class="[
							isBookmarked ? 'is-bookmarked' : ''
						]"
						@click="toggleBookmark($event, chapter.chapter.id)"
						>
						<AtomIcon 
							v-if="isBookmarked"
							icon="bookmark-3d"
							color="purple"
							size="large"
							/>
						<AtomIcon 
							v-else
							icon="bookmark-flat"
							:color="highContrastEnabled ? (chapterHeaderIsSticky ? 'black' : 'grey') : 'grey'"
							size="large"
							/>
					</div>
				</div>

			<div class="container">
				<div 
					v-if="loading"
					class="chapter-description typography-styles"
					>
					<AtomLoadingText
						:lines="5"
						/>
				</div>
				<div 
					v-if="!loading && chapter.chapter.description && chapter.chapter.status == 'published'"
					class="chapter-description typography-styles"
					>
					<div class="chapter-text" v-html="chapter.chapter.description" />
				</div>
			</div>

			

			<div class="blocks-container">
				<template v-if="loading">

					<div class="container">
						<AtomLoadingText
							v-for="i in 8"
							:key="i"
							:lines="12"
							/>
					</div>

				</template>
				<template v-else>

					<div 
						v-if="!chapter || chapter.chapter.status != 'published'"
						class="no-content container"
						>
						Chapter not available
					</div>

					<template v-else>

						<AtomContentBlock
							v-for="(block, index) in chapter.chapter.blocks"
							:key="index"
							:block="block"
							/>

						<div class="chapter-nav container">
							<div class="prev-chapter">
								<div @click="gotoChapter(chapter.previousChapter)">
									<MoleculeHeadingWithIcon
										v-if="chapter.previousChapter"
										heading="Previous Chapter"
										headingSize="s"
										:headingColour="highContrastEnabled ? 'white' : 'grey'"
										icon="arrow-left"
										iconSide="left"
										iconColour="grey"
										/>
								</div>
							</div>
							<div class="next-chapter">
								<div @click="gotoChapter(chapter.nextChapter)">
									<MoleculeHeadingWithIcon
										v-if="chapter.nextChapter"
										heading="Next Chapter"
										headingSize="s"
										:headingColour="highContrastEnabled ? 'white' : 'grey'"
										icon="arrow-right"
										iconSide="right"
										iconColour="grey"
										/>
								</div>
							</div>
						</div>

					</template>

				</template>

			</div>			
			
		</div>
	</div>
</template>

<script>

import { mapActions, mapGetters, mapMutations } from 'vuex'

import AtomLoadingText from '@/components/atoms/AtomLoadingText.vue'
import AtomHeading from '@/components/atoms/AtomHeading.vue'
import AtomIcon from '@/components/atoms/AtomIcon.vue'
import AtomImage from '@/components/atoms/AtomImage.vue'
import AtomContentBlock from '@/components/atoms/AtomContentBlock.vue'
import MoleculeHeadingWithIcon from '@/components/molecules/MoleculeHeadingWithIcon.vue'

export default {
	name: 'PageChapter',
	components: {
		AtomLoadingText,
		AtomHeading,
		AtomIcon,
		AtomImage,
		AtomContentBlock,
		MoleculeHeadingWithIcon,
	},
	data: () => {
		return {
			loading: true,
			chapterID: null,
			chapterHeaderIsSticky: false,
			layoutOuter: null,
			initialChapterHeadingOffset: null,
			chapterStatic: null
		}
	},
	mounted() {
		
		this.setActiveFooterIcon('home')

		this.layoutOuter = document.querySelector('.layout-main-outer')
		
		// add scroll event listener
		this.layoutOuter.addEventListener('scroll', this.isChapterHeadingSticky)

		this.initializeChapter()

		this.setCanSearchChapters(true)
	},
	beforeUnmount() {
		// remove scroll event listener
		this.layoutOuter.removeEventListener('scroll', this.isChapterHeadingSticky)

		this.setCanSearchChapters(false)
		this.setSearchPanelOpen(false)
	},
	methods: {
		...mapActions('documents', {
			getChapter: 'getChapter',
			bookmarkChapter: 'bookmarkChapter'
		}),
		...mapMutations({
			setCanSearchChapters: 'documents/setCanSearchChapters',
			setChapterSearchMatchCount: 'documents/setChapterSearchMatchCount',
			setSearchPanelOpen: 'layout/setSearchPanelOpen',
			setReadabilityPanelOpen: 'layout/setReadabilityPanelOpen',
			setActiveFooterIcon: 'layout/setActiveFooterIcon'
		}),
		toggleBookmark(event, id) {
			event.stopPropagation()
			this.bookmarkChapter(id)
		},
		initializeChapter() {
			// get the id from the route params
			this.loading = true
			// smoothly scroll to the top of the page
			this.layoutOuter.scrollTo({ top: 0, behavior: 'smooth' })
			
			this.chapterID = this.$route.params.id
			this.getChapter(this.chapterID).then(() => {
				this.loading = false
				console.log('chapter', this.chapter)
				this.chapterStatic = JSON.parse(JSON.stringify(this.chapter))
			})
		},
		gotoChapter(chapter) {
			// router push to chapter id
			console.log('gotoChapter', chapter)
			this.$router.replace({ name: 'Chapter', params: { id: chapter.id } })
		},
		backToDocument(){
			// router push to document
			// console.log('back', this.chapter.document_id)
			if(this.chapter.chapter && this.chapter.chapter.document_id){
				this.$router.push({ name: 'Document', params: { id: this.chapter.chapter.document_id } })
			}
		},
		isChapterHeadingSticky() {
			// see if the chapter heading is above the parent container .layout-main-outer bounds, if so, chapterHeaderIsSticky true, else false
			const chapterHeading = this.$refs.chapterHeading

			if(this.initialChapterHeadingOffset == null){
				this.initialChapterHeadingOffset = chapterHeading.offsetTop
			}else{
				if (chapterHeading && this.layoutOuter) {
					let isSticky = chapterHeading.offsetTop != this.initialChapterHeadingOffset
					this.chapterHeaderIsSticky = isSticky
				}
			}
		},

		highlightMatches() {
			let matchCount = 0

			if(!this.loading && this.chapter){

				const term = this.chapterSearchTerm

				if (!term) {
					// Reset blocks if search term is empty
					this.chapter.chapter.blocks = this.chapter.chapter.blocks.map(block => {
						if(!block || typeof block == 'undefined') return block

						if (block.block_type === 'text') {
							return {
								...block,
								content_plain: block.originalContent || block.content_plain,
								outline_title: block.originalTitle || block.outline_title
							};
						} else if (block.block_type === 'table') {
							return {
								...block,
								content_json: block.originalContentJson || block.content_json
							};
						}
						
						return block
					})

					// also check the this.chapter.chapter.description
					if(this.chapter.chapter.originalDescription){
						this.chapter.chapter.description = this.chapter.chapter.originalDescription
					}

					return // quit here if no search term
				}

				// Create a RegExp for the search term (case-insensitive)
				const regex = new RegExp(`(${this.escapeRegExp(this.chapterSearchTerm)})`, 'gi')

				// Highlight matches in each block
				this.chapter.chapter.blocks = this.chapter.chapter.blocks.map(block => {
					if(!block || typeof block == 'undefined') return block
					if (block.block_type === 'text') {
						// Store the original content if not already stored
						if (!block.originalContent) block.originalContent = block.content_plain
						if (!block.originalTitle) block.originalTitle = block.outline_title

						// Parse the HTML content to preserve structure
						const parser = new DOMParser()
						const doc = parser.parseFromString(block.originalContent, 'text/html')
						
						// Call highlightTextNodes on the body of the parsed HTML
						this.highlightTextNodes(doc.body, regex);

						// Highlight matches directly within outline_title
						let highlightedTitle = block.outline_title
						if(block.originalTitle != null){
							highlightedTitle = block.originalTitle.replace(regex, match => {
								matchCount++
								return `<span class="search-match-highlight">${match}</span>`
							})
						}

						// Serialize the modified HTML back to a string
						return {
							...block,
							content_plain: doc.body.innerHTML,
							outline_title: highlightedTitle
						}
					} else if (block.block_type === 'table') {

						// Store the original JSON content if not already stored
						if (!block.originalContentJson) block.originalContentJson = JSON.parse(JSON.stringify(block.content_json));

						// Process each cell in the table to add highlights
						const highlightedJson = block.originalContentJson.map(row =>
							row.map(cell => {
								return cell.replace(regex, match => {
									matchCount++
									return `<span class="search-match-highlight">${match}</span>`
								})
							})
						);

						return {
							...block,
							content_json: highlightedJson
						};

					}else{
						return block
					}
				})

				// also check the this.chapter.chapter.description
				if(!this.chapter.chapter.originalDescription){
					this.chapter.chapter.originalDescription = this.chapter.chapter.description
				}
				if(this.chapter.chapter.description != null){
					this.chapter.chapter.description = this.chapter.chapter.originalDescription.replace(regex, match => {
						matchCount++
						return `<span class="search-match-highlight">${match}</span>`
					})
				}

				this.setChapterSearchMatchCount(matchCount)

			}
		},
		highlightTextNodes(node, regex) {
			// Function to recursively search text nodes and wrap matches
			if (node.nodeType === Node.TEXT_NODE) {
				// If there's a match, replace with a highlighted span
				if (regex.test(node.textContent)) {
					const highlightedText = node.textContent.replace(regex, '<span class="search-match-highlight">$1</span>')
					const wrapper = document.createElement('span')
					wrapper.innerHTML = highlightedText
					node.replaceWith(...wrapper.childNodes)
				}
			} else {
				// Recursively process child nodes if it's an element
				node.childNodes.forEach(childNode => this.highlightTextNodes(childNode, regex))
			}
		},

		escapeRegExp(string) {
			return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // Escape special characters for regex
		},
		
		toggleReadabilityPanel() {
			this.setReadabilityPanelOpen(!this.readabilityPanelOpen)
		}
	},
	watch: {
		'$route.params.id': 'initializeChapter',
		chapterSearchTerm: 'highlightMatches'
	},
	computed: {
		...mapGetters({
			chapter: 'documents/getChapter',
			chapterSearchTerm: 'documents/getChapterSearchTerm',
			readabilityPanelOpen: 'layout/getReadabilityPanelOpen',
			highContrastEnabled: 'layout/getEnableHighContrast',
			textSize: 'layout/getTextSize',
			bookmarks: 'documents/getBookmarks'
		}),
		featuredImage() {
			if(this.chapter.chapter 
				&& this.chapter.chapter.uploads 
				&& this.chapter.chapter.uploads.length > 0 
				&& this.chapter.chapter.uploads[0].sizes
				&& this.chapter.chapter.uploads[0].sizes.length > 0
				&& this.chapter.chapter.uploads[0].sizes.some(size => size.size_name === 'large')
				){
				const largeSize = this.chapter.chapter.uploads[0].sizes.find(size => size.size_name === 'large');
				if (largeSize) {
					return largeSize.publicUrl;
				}else{
					return null
				}
			}else{
				return null
			}
		},
		isBookmarked() {
			if(this.chapter && this.chapter.chapter && this.bookmarks){
				return this.bookmarks.some(bookmark => bookmark.bookmarkable_type === 'App\\Models\\Chapter' && bookmark.bookmarkable_id === this.chapter.chapter.id)
			}else{
				return false
			}
		}
	}
}
</script>

<style lang="sass">

.search-match-highlight
	background-color: $orange

	&.current-match
		background-color: $yellow

.page-chapter
	display: flex
	flex-direction: column
	border-radius: $borderRadiusLarge $borderRadiusLarge 0 0
	background-color: $lightGrey
	width: 100%
	min-height: $mainContentWindowHeight
	overflow: visible
	padding: 0 0 20px 0
	transition: background-color 300ms

	.featured-image
		width: 100%
		height: 230px
		background-color: lighten($midGrey, 15%)
		margin-bottom: 0px
		display: flex
		position: relative
		border-radius: $borderRadiusLarge $borderRadiusLarge 0 0
		overflow: hidden

		.title-overlay
			position: absolute
			bottom: 0
			left: 0
			right: 0
			padding: 60px $sidePaddingSmall 15px $sidePaddingSmall
			background: linear-gradient(0deg, rgba(#333, 0.3), rgba(#333, 0.0))

			&.darker
				background: linear-gradient(0deg, rgba(#333, 0.8), rgba(#333, 0.0))

	.chapter-description
		display: flex
		gap: 10px
		padding: 0 0 20px 0
		font-family: $mainFontBold

		// .chapter-number
		// 	font-size: 20px
		// 	margin-top: -3px

		.chapter-text
			font-size: 16px
			flex: 1

	.chapter-header
		position: sticky
		top: 0
		z-index: 3
		margin: 0 0 20px
		padding: 0 $sidePaddingSmall
		display: flex
		align-items: center
		justify-content: space-between
		transition: all 300ms
		height: 40px

		&.sticky
			background-color: rgba($white, 0.75)
			backdrop-filter: blur(5px)

		.bookmark-icon
			height: 40px

			&.is-bookmarked
				margin-top: -6px

				.atom-icon.size-large
					height: 34px

	.blocks-container
		padding: 0 0 20px 0
		display: flex
		flex-direction: column
		gap: 12px

		.chapter-nav
			display: flex
			align-items: center
			justify-content: space-between
			gap: 10px

	&.high-contrast
		background-color: $bgDarkGrey

		.chapter-description
			color: $white

			.chapter-text
				color: $white

	&.text-size-small
		.chapter-description
			.chapter-text
				font-size: 16px

	&.text-size-medium
		.chapter-description
			.chapter-text
				font-size: 20px

	&.text-size-large
		.chapter-description
			.chapter-text
				font-size: 24px



</style>