<template>
	<div 
		class="molecule-document-card"
		:class="[
			!loading && document ? 'has-document' : '',
			loading ? 'is-loading' : '',
			!loading && !document ? 'no-document' : '',
			fullHeight ? 'full-height' : ''
		]"
		>
		<div 
			v-if="loading"
			class="loading-card"
			/>
		<template v-else-if="!loading && document">
			<div 
				class="document-card-inner"
				:class="[
					tallerImage ? 'taller-image' : ''
				]"
				>
				<div 
					class="bookmark-icon"
					:class="[
						bookmarked ? 'is-bookmarked' : ''
					]"
					@click="toggleBookmark($event, document.id)"
					>
					<AtomIcon 
						v-if="bookmarked"
						icon="bookmark-3d"
						color="orange"
						size="large"
						/>
					<AtomIcon 
						v-else
						icon="bookmark-flat"
						color="grey"
						size="large"
						/>
				</div>

				<div class="image-container">
					<AtomImage 
						:src="thumbnail"
						:absolute="true"
						:cover="true"
						/>
				</div>

				<div class="info-container">
					<AtomHeading 
						color="grey"
						size="s"
						weight="bold"
						align="left"
						>
						{{ document.name }}
					</AtomHeading>

					<div
						v-if="showDescription"
						class="document-description"
						>
						{{ shortDescription }}
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			Could not load Document
		</template>
	</div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

import AtomIcon from '@/components/atoms/AtomIcon.vue'
import AtomImage from '@/components/atoms/AtomImage.vue'
import AtomHeading from '@/components/atoms/AtomHeading.vue'

export default {
	name: 'MoleculeDocumentCard',
	components: {
		AtomIcon,
		AtomImage,
		AtomHeading
	},
	props: {
		document: {
			type: [Boolean, Object],
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		},
		showDescription: {
			type: Boolean,
			default: false
		},
		tallerImage: {
			type: Boolean,
			default: false
		},
		fullHeight: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		...mapActions('documents', [
			'bookmarkDocument'
		]),
		toggleBookmark(event, id) {
			event.stopPropagation()
			this.bookmarkDocument(id)
		}
	},
	computed: {
		...mapGetters('documents', {
			bookmarks: 'getBookmarks'
		}),
		// limit description to 7 words
		shortDescription() {
			if(this.document && this.document.description){
				return this.document.description.split(' ').slice(0, 7).join(' ') + (this.document.description.split(' ').length > 7 ? '...' : '')
			}else{
				return ''
			}
		},
		thumbnail() {
			if(this.document 
				&& this.document.uploads 
				&& this.document.uploads.length > 0 
				&& this.document.uploads[0].sizes
				&& this.document.uploads[0].sizes.length > 0
				&& this.document.uploads[0].sizes.some(size => size.size_name === 'thumbnail')
				){
				const thumbnailSize = this.document.uploads[0].sizes.find(size => size.size_name === 'thumbnail');
				if (thumbnailSize) {
					return thumbnailSize.publicUrl;
				}else{
					return null
				}
			}else{
				return null
			}
		},
		bookmarked() {
			if(this.document && this.bookmarks){
				// check all items in the array where 'bookmarkable_type' is "App\Models\Document" and 'bookmarkable_id' is the document.id. if it is then return true, else false
				return this.bookmarks.some(bookmark => bookmark.bookmarkable_type === 'App\\Models\\Document' && bookmark.bookmarkable_id === this.document.id)
			}else{
				return false
			}
		}
	}
}

</script>

<style lang="sass">

$imageToContentsize: 95px
$imageToContentsizeLarger: 115px

.molecule-document-card
	display: flex
	width: 100%
	min-height: 160px
	background-color: $white
	border-radius: $borderRadiusSmall
	position: relative
	cursor: pointer

	.loading-card
		background: linear-gradient(30deg, lighten($midGrey, 15%) 25%, lighten($midGrey, 20%) 50%, lighten($midGrey, 15%) 75%)
		background-size: 100% 200% 
		animation: gradientLoadingAnimationY 2.6s infinite linear
		position: absolute
		inset: 0
		border-radius: $borderRadiusSmall

	&.has-document
		border-radius: $borderRadiusSmall 0 $borderRadiusSmall $borderRadiusSmall

	&.no-document
		background-color: lighten($midGrey, 15%)
		padding: 15px
		text-align: center
		align-items: center
		justify-content: center
		flex-direction: column
		gap: 10px
		color: $midGrey
		font-family: $mainFontReg

		&:before
			content: '!'
			font-family: $mainFontBold
			width: 30px
			height: 30px
			border-radius: 50%
			border: 2px solid $midGrey
			display: flex
			align-items: center
			justify-content: center
			font-size: 20px

	&.full-height
		height: 100%

	.document-card-inner
		position: relative
		width: 100%
		display: flex
		flex-direction: column

		&:before
			content: ''
			position: absolute
			top: 0
			right: 0
			width: 20px
			height: 20px
			background-color: $midGrey
			background: linear-gradient(45deg, $midGrey 0%, $midGrey 20%, lighten($midGrey, 10%) 50%, $midGrey 100%)
			border-radius: 0 0 0 $borderRadiusSmall
			z-index: 1

		&:after
			content: ''
			position: absolute
			top: 0
			right: 0
			width: 0
			height: 0
			border-left: 20px solid $lightGrey
			border-left: 20px solid transparent
			border-right: 20px solid $lightGrey
			border-bottom: 20px solid transparent
			z-index: 2

		.bookmark-icon
			position: absolute
			top: 0px
			left: 8px
			z-index: 3

			&.is-bookmarked
				top: -3px

				.atom-icon.size-large
					height: 34px

		.image-container
			position: relative
			width: 100%
			height: $imageToContentsize
			overflow: hidden
			border-radius: $borderRadiusSmall $borderRadiusSmall 0 0

		.info-container
			padding: 12px
			position: relative
			display: flex
			flex-direction: column
			gap: 10px
			flex-grow: 1

			.document-description
				color: $midGrey
				font-size: 12px
				line-height: 1.2
				// padding-top: 10px
				flex-grow: 1

		&.taller-image
			.image-container
				height: $imageToContentsizeLarger

			.info-container
				// top: $imageToContentsizeLarger
	

</style>