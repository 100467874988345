
import axios from 'axios'
import router from '@/router'

const state = () => ({
	token: localStorage.getItem('token') || null,
	user: JSON.parse(localStorage.getItem('user')) || null,
})

const getters = {
	isAuthenticated: state => !!state.token,
	getToken: state => state.token,
	getUser: state => state.user
}

const mutations = {
	setToken(state, token) {
		state.token = token
		localStorage.setItem('token', token)
	},
	clearToken(state) {
    state.token = null;
    localStorage.removeItem('token');
  },
	setUser(state, user) {
		state.user = user
		localStorage.setItem('user', JSON.stringify(user))
	}
}

const actions = {
	async login({ commit }, payload) {
		return new Promise((resolve, reject) => {
			axios.post('/api/login', payload)
				.then(response => {
					commit('setToken', response.data.token)
					commit('setUser', response.data.user)
					resolve(response)
				})
				.catch(error => {
					// if there is a error.message, it means the api request was successful, but the login failed. so return the message
					if (error.response && error.response.data && error.response.data.message) {
						// Use the custom error message from the backend
						reject(new Error(error.response.data.message))
					}else{
						reject(error)
					}
				})
		})
	},
	async logout({ dispatch }) {
		return new Promise((resolve) => {
			axios.post('/api/logout')
				.then(response => {
					dispatch('clearUser')
					resolve(response)
				})
				.catch(error => {
					dispatch('clearUser')
					resolve(error) // no need to cause an 'uncaught runtime error' here by calling 'reject()'. They will always be logged out after this action, even if the api request fails with a 401.
				})
		})
	},
	clearUser({ commit }) {
		commit('clearToken')
		commit('setUser', null)
		router.push('/login') // Redirect to the login page
	},
	async register(_, payload) {
		return new Promise((resolve, reject) => {
			axios.post('/api/register', payload)
				.then(response => {
					resolve(response)
				})
				.catch(error => {
					// if there is a error.message, it means the api request was successful, but the registration failed. so return the message
					if (error.response && error.response.data && error.response.data.message) {
						// Use the custom error message from the backend
						reject(new Error(error.response.data.message))
					}else{
						reject(error)
					}
				})
		})
	},
	async forgotPassword({ dispatch }, payload) {
		return new Promise((resolve, reject) => {
			axios.post('/api/forgot-password', payload)
				.then(response => {
					resolve(response)
				})
				.catch(error => {
					// if 401 then dispatch clearUser
					if (error.response && error.response.status === 401) {
						dispatch('clearUser')
						resolve(error)
					}else{
						// if there is a error.message, it means the api request was successful, but the registration failed. so return the message
						if (error.response && error.response.data && error.response.data.message) {
							// Use the custom error message from the backend
							reject(new Error(error.response.data.message))
						}else{
							reject(error)
						}
					}
				})
		})
	},
	async updateAccount({ dispatch }, payload) {
		return new Promise((resolve, reject) => {
			axios.post('/api/account/update', payload)
				.then(response => {
					resolve(response)
				})
				.catch(error => {
					// if 401 then dispatch clearUser
					if (error.response && error.response.status === 401) {
						dispatch('clearUser')
						resolve(error)
					}else{
						// if there is a error.message, it means the api request was successful, but the registration failed. so return the message
						if (error.response && error.response.data && error.response.data.message) {
							// Use the custom error message from the backend
							reject(new Error(error.response.data.message))
						}else{
							reject(error)
						}
					}
				})
		})
	},
	async updatePassword({ dispatch }, payload) {
		return new Promise((resolve, reject) => {
			axios.post('/api/account/password', payload)
				.then(response => {
					resolve(response)
				})
				.catch(error => {
					// if 401 then dispatch clearUser
					if (error.response && error.response.status === 401) {
						dispatch('clearUser')
						resolve(error)
					}else{
						// if there is a error.message, it means the api request was successful, but the registration failed. so return the message
						if (error.response && error.response.data && error.response.data.message) {
							// Use the custom error message from the backend
							reject(new Error(error.response.data.message))
						}else{
							reject(error)
						}
					}
				})
		})
	},
	async updateNotificationSettings({ dispatch }, payload) {
		return new Promise((resolve, reject) => {
			axios.post('/api/account/notifications', payload)
				.then(response => {
					resolve(response)
				})
				.catch(error => {
					// if 401 then dispatch clearUser
					if (error.response && error.response.status === 401) {
						dispatch('clearUser')
						resolve(error)
					}else{
						// if there is a error.message, it means the api request was successful, but the registration failed. so return the message
						if (error.response && error.response.data && error.response.data.message) {
							// Use the custom error message from the backend
							reject(new Error(error.response.data.message))
						}else{
							reject(error)
						}
					}
				})
		})
	},
	async deleteAccount({ dispatch }, payload) {
		return new Promise((resolve, reject) => {
			axios.post('/api/account/delete', payload)
				.then(response => {
					resolve(response)
				})
				.catch(error => {
					// if 401 then dispatch clearUser
					if (error.response && error.response.status === 401) {
						dispatch('clearUser')
						resolve(error)
					}else{
						// if there is a error.message, it means the api request was successful, but the registration failed. so return the message
						if (error.response && error.response.data && error.response.data.message) {
							// Use the custom error message from the backend
							reject(new Error(error.response.data.message))
						}else{
							reject(error)
						}
					}
				})
		})
	},
	getProfile({ commit, dispatch }) {
		return new Promise((resolve) => {
			axios.get('/api/user')
				.then(response => {
					commit('setUser', response.data)
					resolve(response)
				})
				.catch(error => {
					// reject(error)
					// don't reject and cause an 'Uncaught runtime error' here. if the api request fails, the user will just be logged out because they obviously can't get the profile data.
					dispatch('logout')
					resolve(error)
				})
		})
	}
}


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}