<template>
	<div class="organism-popup-user-panel">
		
		<MoleculePopUpPanel
			@closePanel="setUserPanelOpen(false)"
			:panelOpen="panelOpen"
			>

			<div class="links-panel-inner">
				<ul>
					<li>
						<router-link 
							to="/account"
							@click="setUserPanelOpen(false)"
							>
							Your Info
						</router-link>
					</li>
					<li>
						<router-link 
							to="/account/change-password"
							@click="setUserPanelOpen(false)"
							>
							Change Password
						</router-link>
					</li>
					<li>
						<router-link 
							to="/account/notification-settings"
							@click="setUserPanelOpen(false)"
							>
							Notification Settings
						</router-link>
					</li>
					<li>
						<router-link 
							to="/rate-share-app"
							@click="setUserPanelOpen(false)"
							>
							Rate &amp; Share the App
						</router-link>
					</li>
					<li>
						<router-link 
							to="/account/delete"
							@click="setUserPanelOpen(false)"
							>
							Delete Account
						</router-link>
					</li>
					<li>
						<a href="https://compexcertification.com/my-compex-app/" target="_blank">Privacy & Terms</a>
					</li>
					<li>
						<span
							@click="closeAndLogout"
							>
							Sign Out
						</span>
					</li>
				</ul>

			</div>

		</MoleculePopUpPanel>

	</div>
</template>

<script>

import { mapGetters, mapMutations, mapActions } from 'vuex'

import MoleculePopUpPanel from '../molecules/MoleculePopUpPanel.vue'

export default {
	name: 'OrganismPopupUserPanel',
	components: {
		MoleculePopUpPanel
	},
	data: () => {
		return {
			
		}
	},
	methods: {
		...mapActions({
			logout: 'auth/logout'
		}),
		...mapMutations('layout', {
			setUserPanelOpen: 'setUserPanelOpen',
		}),
		resetAll(){
			this.setEnableHighContrast(false)
			this.setTextSize('small')
		},
		closeAndLogout(){
			this.setUserPanelOpen(false)
			this.logout()
		}
	},
	computed: {
		...mapGetters('layout', {
			panelOpen: 'getUserPanelOpen',
		})
	},
}

</script>

<style lang="sass">

.organism-popup-user-panel
	.links-panel-inner
		display: flex
		flex-direction: column
		align-items: center
		text-align: center
		gap: 15px

		ul
			width: 100%
			display: flex
			flex-direction: column
			gap: 5px

			li
				a, span
					font-size: 20px
					color: $white
					font-family: $mainFontBold
					letter-spacing: -0.5px
					padding: 10px 0
					display: flex
					text-align: center
					justify-content: center
					align-items: center
					background-color: rgba(#fff, 0.05)
					width: 100%
					border-radius: $borderRadiusSmall



</style>