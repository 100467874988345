<template>
	<div class="organism-readability-panel">
		
		<MoleculePopUpPanel
			@closePanel="setReadabilityPanelOpen(false)"
			:panelOpen="panelOpen"
			>

			<div class="readability-panel-inner">
				<div class="panel-title">
					Readability Options
				</div>

				<div class="contrast-switch">
					<AtomContrastSwitch 
						@change="setEnableHighContrast"
						:initialValue="highContrastEnabled"
						/>
				</div>

				<div class="panel-title">
					Text Size
				</div>

				<div class="text-size-switch">
					<AtomTextSizeSwitch 
						:initialValue="textSize"
						@change="setTextSize"
						/>
				</div>

				<div class="reset-container">
					<AtomButton
						color="trans-grey"
						label="Reset All"
						@click="resetAll"
						/>
				</div>
			</div>

		</MoleculePopUpPanel>

	</div>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'

import AtomContrastSwitch from '../atoms/AtomContrastSwitch.vue'
import AtomTextSizeSwitch from '../atoms/AtomTextSizeSwitch.vue'
import AtomButton from '../atoms/AtomButton.vue'
import MoleculePopUpPanel from '../molecules/MoleculePopUpPanel.vue'

export default {
	name: 'OrganismReadabilityPanel',
	components: {
		AtomContrastSwitch,
		AtomTextSizeSwitch,
		AtomButton,
		MoleculePopUpPanel
	},
	data: () => {
		return {
			
		}
	},
	methods: {
		...mapMutations('layout', {
			setReadabilityPanelOpen: 'setReadabilityPanelOpen',
			setEnableHighContrast: 'setEnableHighContrast',
			setTextSize: 'setTextSize'
		}),
		resetAll(){
			this.setEnableHighContrast(false)
			this.setTextSize('small')
		}
	},
	computed: {
		...mapGetters('layout', {
			panelOpen: 'getReadabilityPanelOpen',
			highContrastEnabled: 'getEnableHighContrast',
			textSize: 'getTextSize'
		})
	},
}

</script>

<style lang="sass">

.organism-readability-panel
	.readability-panel-inner
		display: flex
		flex-direction: column
		align-items: center
		text-align: center
		gap: 20px

		.panel-title
			font-size: 21px
			font-family: $mainFontBold
			color: #fff
			letter-spacing: -0.5px

		.text-size-switch
			width: 100%
			margin-bottom: 15px

		.contrast-switch
			margin-bottom: 15px

</style>