<template>
	<div class="organism-filter-centres-panel">
		
		<MoleculePopUpPanel
			@closePanel="closePanel"
			:panelOpen="panelOpen"
			panelIcon="filter"
			:fullscreen="true"
			:preventScrollEvents="false"
			>

			<div class="filter-panel-inner">

				<template v-if="collatedActiveFilters.length > 0">
					<div class="panel-title">
						Active Filters

						<div class="results-found-count">
							{{ getHowManyResultsFound }} Centres Found
						</div>
					</div>

					<div class="active-filters">
						<div 
							v-for="option in collatedActiveFilters"
							:key="`${option.name}-${option.type}`"
							class="active-filter-option"
							@click="toggleFilter(option.name, option.type)"
							>
							<div class="active-indicator" />
							<div class="option-title">
								{{ option.name }}
							</div>
						</div>
					</div>
				</template>

				<div class="panel-title">
					Filters
				</div>

				<div class="filter-type-tab-bar">
					<div 
						class="a-tab"
						:class="{ active: activeTab === 'country' }"
						@click="activeTab = 'country'"
						>
						Country
					</div>
					<div 
						class="a-tab"
						:class="{ active: activeTab === 'qualification' }"
						@click="activeTab = 'qualification'"
						>
						Qualification
					</div>
					<div 
						class="a-tab"
						:class="{ active: activeTab === 'type' }"
						@click="activeTab = 'type'"
						>
						Type
					</div>
				</div>


				<div 
					v-if="activeTab === 'country'"
					class="filter-options"
					>
					<div 
						v-for="option in countries"
						:key="option"
						class="filter-option"
						:class="{ active: activeFilters.countries.includes(option) }"
						@click="toggleFilter(option, 'countries')"
						>
						<div class="option-title">
							{{ option }}
						</div>
					</div>
				</div>

				<div 
					v-else-if="activeTab === 'qualification'"
					class="filter-options"
					>
					<div 
						v-for="option in qualifications"
						:key="option"
						class="filter-option"
						:class="{ active: activeFilters.qualifications.includes(option) }"
						@click="toggleFilter(option, 'qualifications')"
						>
						<div class="option-title">
							{{ option }}
						</div>
					</div>
				</div>

				<div 
					v-else-if="activeTab === 'type'"
					class="filter-options"
					>
					<div 
						v-for="option in types"
						:key="option"
						class="filter-option"
						:class="{ active: activeFilters.types.includes(option) }"
						@click="toggleFilter(option, 'types')"
						>
						<div class="option-title">
							{{ option }}
						</div>
					</div>
				</div>
			</div>

			<div class="button-bar">
				<AtomButton
					color="fake-trans-grey-on-grad-blue"
					label="Clear All"
					:fullWidth="true"
					@click="clearAll"
					/>
				<AtomButton
					color="orange"
					label="Apply"
					:fullWidth="true"
					@click="closePanel"
					/>
			</div>

		</MoleculePopUpPanel>

	</div>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'

import AtomButton from '../atoms/AtomButton.vue'
import MoleculePopUpPanel from '../molecules/MoleculePopUpPanel.vue'

export default {
	name: 'OrganismFilterCentresPanel',
	components: {
		AtomButton,
		MoleculePopUpPanel
	},
	data: () => {
		return {
			qualifications: [
				'ExF',
				'ExF+',
				'Ex01 - Ex04',
				'Ex05 - Ex06',
				'Ex07 - Ex08',
				'Ex09 - Ex10',
				'Ex11',
				'Ex12',
				'Ex14',
				'MTEx01 - MTEx04',
				'FSF',
				'RPP'
			],
			types: [
				'Ex Foundation',
				'Ex Foundation Plus With practical',
				'Gas & Vapours',
				'Dust',
				'Petrol Forecourts',
				'Water Utilities',
				'Mechanical',
				'Application Design Engineer',
				'Responsible Person',
				'Maritime',
				'Functional Safety Foundation',
				'Recognised Practitioner Programme'
			],
			activeTab: 'country'
		}
	},
	methods: {
		...mapMutations({
			setCentreFilterPanelOpen: 'centres/setCentreFilterPanelOpen',
			addActiveFilter: 'centres/addActiveFilter',
			removeActiveFilter: 'centres/removeActiveFilter',
			clearActiveFilters: 'centres/clearActiveFilters',
			setCentreInfoPanelOpen: 'centres/setCentreInfoPanelOpen',
		}),
		resetAll(){
			this.setEnableHighContrast(false)
			this.setTextSize('small')
		},
		toggleFilter(option, type){
			if(this.activeFilters[type].includes(option)){
				this.removeActiveFilter({
					filter: option, 
					filterType: type 
				})
			} else {
				this.addActiveFilter({
					filter: option, 
					filterType: type 
				})
			}
		},
		clearAll(){
			this.clearActiveFilters()
		},
		closePanel(){
			this.setCentreInfoPanelOpen(false)
			this.setCentreFilterPanelOpen(false)
		}
	},
	computed: {
		...mapGetters({
			panelOpen: 'centres/getCentreFilterPanelOpen',
			countries: 'centres/getCountries',
			activeFilters: 'centres/getActiveFilters',
			getHowManyResultsFound: 'centres/getHowManyResultsFound'
		}),
		collatedActiveFilters(){
			// active filters comes as { countries: [], qualifications: [] } and I want a single array of all active filters like this [{ name: 'England', type: 'country' }]
			let collatedFilters = []
			for (const [key, value] of Object.entries(this.activeFilters)) {
				value.forEach(filter => {
					collatedFilters.push({
						name: filter,
						type: key
					})
				})
			}
			return collatedFilters
		}
	},
}

</script>

<style lang="sass">

.organism-filter-centres-panel
	.filter-panel-inner
		display: flex
		flex-direction: column
		align-items: center
		gap: 15px
		padding-top: 20px
		min-height: calc(100dvh - $appPadTop - $appPadBottom - 190px)

		.panel-title
			font-size: 22px
			color: $white
			width: 100%
			display: flex
			justify-content: space-between
			align-items: flex-end

			.results-found-count
				font-size: 13px
				color: $white
				font-family: $mainFontReg
				letter-spacing: -0.5px
				padding: 0 0 0 10px

		.filter-options
			display: grid
			grid-template-columns: 1fr 1fr
			gap: 15px
			width: 100%
			padding-bottom: 20px

			.filter-option
				position: relative
				background-color: $transGrey1
				width: 100%
				display: flex
				height: 50px
				border-radius: $borderRadiusSmall

				.option-title
					position: absolute
					inset: 0
					display: flex
					justify-content: center
					align-items: center
					text-align: center
					font-size: 13px
					color: $white
					font-family: $mainFontBold
					letter-spacing: -0.5px
					padding: 0 10px
					line-height: 1.2
					@include fontSmoothing()

				&.active
					background-color: $white

					.option-title
						color: $black

		.active-filters
			display: flex
			justify-content: flex-start
			flex-wrap: wrap
			gap: 8px
			padding-bottom: 15px
			width: 100%

			.active-filter-option
				position: relative
				background-color: $white
				display: inline-flex
				align-items: center
				padding: 0 10px
				height: 30px
				border-radius: $borderRadiusSmall

				.active-indicator
					display: flex
					width: 15px
					height: 15px
					background-color: $orange
					border-radius: 50%
					justify-content: center
					align-items: center

					&:after
						content: ''
						width: 5px
						height: 1px
						background-color: $white

				.option-title
					text-align: center
					font-size: 11px
					color: $black
					font-family: $mainFontBold
					letter-spacing: -0.5px
					padding: 0 10px
					line-height: 1
					@include fontSmoothing()
					white-space: nowrap

	.button-bar
		position: sticky
		left: 0
		right: 0
		bottom: -20px
		padding: 20px 0
		display: grid
		grid-template-columns: 1fr 1fr
		gap: 15px
		z-index: 5

	.filter-type-tab-bar
		width: 100%
		display: flex

		.a-tab
			padding: 8px 15px
			color: $white
			position: relative
			border-radius: $borderRadiusSmall $borderRadiusSmall 0 0
			font-family: $mainFontBold

			&.active
				border-top: 1px solid $white
				border-right: 1px solid $white
				border-left: 1px solid $white

				&:before
					content: ''
					bottom: 0
					height: 1px
					width: 100vw
					background-color: $white
					left: -100vw
					position: absolute

				&:after
					content: ''
					bottom: 0
					height: 1px
					width: 100vw
					background-color: $white
					right: -100vw
					position: absolute



			


</style>