import { openDB } from 'idb';

const dbPromise = openDB('compex-db', 1, {
	upgrade(db) {
		db.createObjectStore('documents', { keyPath: 'id' });
		db.createObjectStore('tags', { keyPath: 'id' });
		db.createObjectStore('bookmarks', { keyPath: 'id' });
		db.createObjectStore('chapters', { keyPath: 'id' }); // Add this line to create the chapters object store
	},
});

// Document-related functions
export const getDocumentFromDB = async (id) => {
	const db = await dbPromise;
	const document = await db.get('documents', parseInt(id));
	console.log('Retrieved document from DB:', document);
	return document;
};

export const saveDocumentToDB = async (document) => {
	const db = await dbPromise;
	console.log('Saving document to DB:', document);
	return db.put('documents', document);
};

export const getAllDocumentsFromDB = async () => {
	const db = await dbPromise;
	return db.getAll('documents');
};

export const saveAllDocumentsToDB = async (documents) => {
	const db = await dbPromise;
	const tx = db.transaction('documents', 'readwrite');
	documents.forEach(doc => tx.store.put(doc));
	await tx.done;
};

// Tag-related functions
export const getTagFromDB = async (id) => {
	const db = await dbPromise;
	const tag = await db.get('tags', parseInt(id));
	console.log('Retrieved tag from DB:', tag);
	return tag;
};

export const saveTagToDB = async (tag) => {
	const db = await dbPromise;
	console.log('Saving tag to DB:', tag);
	return db.put('tags', tag);
};

export const getAllTagsFromDB = async () => {
	const db = await dbPromise;
	return db.getAll('tags');
};

export const saveAllTagsToDB = async (tags) => {
	const db = await dbPromise;
	const tx = db.transaction('tags', 'readwrite');
	tags.forEach(tag => tx.store.put(tag));
	await tx.done;
};

// Bookmark-related functions
export const getBookmarkFromDB = async (id) => {
	const db = await dbPromise;
	const bookmark = await db.get('bookmarks', parseInt(id));
	console.log('Retrieved bookmark from DB:', bookmark);
	return bookmark;
};

export const saveBookmarkToDB = async (bookmark) => {
	const db = await dbPromise;
	console.log('Saving bookmark to DB:', bookmark);
	return db.put('bookmarks', bookmark);
};

export const getAllBookmarksFromDB = async () => {
	const db = await dbPromise;
	return db.getAll('bookmarks');
};

export const saveAllBookmarksToDB = async (bookmarks) => {
	const db = await dbPromise;
	const tx = db.transaction('bookmarks', 'readwrite');
	bookmarks.forEach(bookmark => tx.store.put(bookmark));
	await tx.done;
};

// Chapter-related functions
export const getChapterFromDB = async (id) => {
	const db = await dbPromise;
	const chapter = await db.get('chapters', parseInt(id));
	console.log('Retrieved chapter from DB:', chapter);
	return chapter;
};

export const saveChapterToDB = async (chapter) => {
	const db = await dbPromise;
	console.log('Saving chapter to DB:', chapter);
	return db.put('chapters', chapter);
};

export const getAllChaptersFromDB = async () => {
	const db = await dbPromise;
	return db.getAll('chapters');
};

export const saveAllChaptersToDB = async (chapters) => {
	const db = await dbPromise;
	const tx = db.transaction('chapters', 'readwrite');
	chapters.forEach(chapter => tx.store.put(chapter));
	await tx.done;
};

// Function to check if a chapter exists in the DB
export const doesChapterExistInDB = async (id) => {
	const db = await dbPromise;
	const chapter = await db.get('chapters', parseInt(id));
	console.log('Chapter exists in DB:', !!chapter);
	return !!chapter;
};