<template>
	<div class="atom-contrast-switch">
		<div 
			class="switch-track" 
			:style="{ '--color-changer': bgColor }"
			>
			{{ switchValue ? 'High Contrast' : 'Low Contrast' }}

			<div 
				class="flash" 
				:class="{ active: switchValue }"
				/>

			<div 
				class="toggle"
				ref="toggle"
				:class="[
					panning ? 'panning' : null
				]"
				:style="{ transform: togglePosition }"
				>
				<AtomIcon
					icon="eye"
					:color="!switchValue ? 'grey' : 'orange'"
					size="small"
					/>
			</div>
		</div>
	</div>
</template>

<script>

import Hammer from 'hammerjs'

import AtomIcon from '@/components/atoms/AtomIcon.vue'

export default {
	name: 'AtomContrastSwitch',
	components: {
		AtomIcon
	},
	props: {
		initialValue: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			switchValue: false,
			percentage: 0,
			panStartPercentage: 0,
			bgColor: this.calculateColor(0),
			trackWidth: 200,
			panning: false
		}
	},
	mounted(){

		this.switchValue = this.initialValue
		if(this.initialValue){
			this.percentage = 100
		}

		const hammerToggle = new Hammer(this.$refs.toggle)
		hammerToggle.get('pan').set({ direction: Hammer.DIRECTION_HORIZONTAL })

		hammerToggle.on('panstart', () => {
			this.panning = true
			this.panStartPercentage = this.percentage
		})

		hammerToggle.on('panmove', (e) => {

			this.percentage = (e.deltaX / this.trackWidth * 100) + this.panStartPercentage

		})

		hammerToggle.on('panend', () => {
			// on panend, remove the class of 'panning' from the toggle
			this.panning = false

			if(this.percentage > 50) {
				this.percentage = 100
				this.toggleSwitch(true)
			}else{
				this.percentage = 0
				this.toggleSwitch(false)
			}
		})
	},
	watch: {
		percentage(newVal) {
			this.bgColor = this.calculateColor(newVal);
		},
		initialValue(newVal) {
			this.switchValue = newVal
			this.percentage = newVal ? 100 : 0
		}
	},
	methods: {
		toggleSwitch(isOn) {
			
			if(isOn !== undefined){
				this.switchValue = isOn
			}
			
			this.$emit('change', this.switchValue)

		},
		calculateColor(percentage) {
			const startColor = [172, 175, 174] // Mid Grey
			const endColor = [249, 176, 0] // Orange
			const r = Math.round(startColor[0] + (endColor[0] - startColor[0]) * (percentage / 100))
			const g = Math.round(startColor[1] + (endColor[1] - startColor[1]) * (percentage / 100))
			const b = Math.round(startColor[2] + (endColor[2] - startColor[2]) * (percentage / 100))

			return `rgb(${r}, ${g}, ${b})`
		}
	},
	computed: {
		// calculate the toggle's position
		togglePosition() {
			return `translateX(${(this.percentage * 1.65)}px)` // find the 'right' position and then pull the decimal point 2 to the left. e.g. 165px = 1.65. Because the end position is 100% of the track width. - 100 x 1.65 = 165
		}
	}
}

</script>

<style lang="sass">

.atom-contrast-switch
	display: flex
	align-items: center
	justify-content: center
	width: 100%
	height: 35px
	position: relative

	.switch-track
		position: absolute
		width: 200px
		height: 35px
		border-radius: 20px
		display: flex
		align-items: center
		justify-content: center
		font-size: 17px
		font-family: $mainFontBold
		letter-spacing: -0.7px
		color: $white
		background-color: var(--color-changer)
		overflow: hidden

		.toggle
			position: absolute
			width: 37px
			height: 37px
			border-radius: 50%
			background-color: $white
			border: 3px solid var(--color-changer)
			top: -1px
			left: -1px
			display: flex
			align-items: center
			justify-content: center
			transition: transform 0.3s

			&.panning
				transition: none

		.flash
			width: 200px
			height: 100px
			position: absolute
			right: -200px
			top: -50%
			background-image: linear-gradient(to left, transparent, white, transparent)
			transform: rotate(10deg)
			transition: right 0.6s
			opacity: 0.5

			&.active
				right: 280px

</style>