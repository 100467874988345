<template>
	<div class="page-delete-account page-layout">
		<div class="container">

			<MoleculeHeadingWithIcon
				heading="Delete Account"
				icon="arrow-left"
				iconColour="white"
				iconRoute="/dashboard"
				iconSide="left"
				/>

			<h1>Warning, this can't be undone!</h1>

			<form
				class="form-account-details"
				@submit.prevent="handleDeleteAccount"
				>
				<div class="form-tickbox-containers">
					<div class="term-row">
						<span class="tick-label">You understand your account and data will Be PERMANENTLY DELETED</span>
						<AtomYesNo v-model="form.confirm" />
					</div>
				</div>
			</form>

			<ul 
				class="form-errors"
				v-if="(v$.form.$error && v$.form.$errors.length > 0) || errorLog.length > 0"
				>
				<template v-if="errorLog.length > 0">
					<li 
						class="error-message"
						v-for="(error, key) in errorLog"
						:key="key"
						>
						{{ filterErrorMessage(error) }}
					</li>
				</template>
				<template v-if="v$.form.$error && v$.form.$errors.length > 0">
					<li 
						class="error-message"
						v-for="(error, key) in v$.form.$errors"
						:key="key"
						>
						{{ error.$message }}
					</li>
				</template>
			</ul>

			<div 
				v-if="updateMessage"
				class="form-success-message"
				>
				{{ updateMessage }}
			</div>

			<div class="button-container">
				<AtomButton
					label="Delete"
					:color="resetButtonColour"
					:loading="formProcessing"
					@click="handleDeleteAccount"
					/>
			</div>


		</div>
	</div>
</template>

<script>

import { mapActions, mapMutations } from 'vuex'
import useVuelidate from "@vuelidate/core"
import { helpers } from "@vuelidate/validators"

import AtomYesNo from '@/components/atoms/AtomYesNo.vue'
import AtomButton from '@/components/atoms/AtomButton.vue'

import MoleculeHeadingWithIcon from '@/components/molecules/MoleculeHeadingWithIcon.vue'

export default {
	name: 'PageDeleteAccount',
	components: {
		AtomYesNo,
		AtomButton,
		MoleculeHeadingWithIcon
	},
	setup: () => ({ v$: useVuelidate() }),
	data: () => {
		return {
			form: {
				confirm: false,
			},
			formProcessing: null,
			errorLog: [],
			updateMessage: null,
		}
	},
	validations() {

		return {
			form: {
				confirm: { 
					requiredTrue: helpers.withMessage('You must confirm that you wish to delete your account.', value => value === true)
				},
			},
	}

	},
	mounted(){
		this.setActiveFooterIcon('user')

		this.$nextTick(() => {
			this.setHeaderOpacity(1)
		})
	},
	beforeUnmount(){
		this.setHeaderOpacity(0)
	},
	methods: {
		...mapMutations({
			setHeaderOpacity: 'layout/setHeaderOpacity',
			setActiveFooterIcon: 'layout/setActiveFooterIcon'
		}),
		...mapActions('auth', {
			deleteAccount: 'deleteAccount',
			logout: 'logout',
		}),
		handleDeleteAccount(){
			// clear any previous errors and messages
			this.errorLog = []
			this.updateMessage = null
			
			this.v$.$touch()
			if (!this.v$.$invalid && !this.formProcessing) {
				this.formProcessing = true
				this.deleteAccount(this.form)
					.then(() => {
						this.formProcessing = false

						this.updateMessage = 'Account deleted successfully, logging you out now...'

						setTimeout(() => {
							this.logout()
						}, 3000)

					})
					.catch((error) => {
						this.formProcessing = false
						this.errorLog.push(error)
					})

			}
			
		},
		filterErrorMessage(error){
			console.log('error', JSON.stringify(error))
			if(typeof error === 'string' && error.includes('AxiosError: ')){
				return error.replace('AxiosError: ', '')
			}else if(typeof error === 'object' && error.message){
				return error.message
			}else{
				return error
			}
		}
	},
	computed: {
		resetButtonColour(){
			// when the login fields are valid, the button should be orange to indicate that they can proceed.
			if(this.v$.$invalid){
				return 'trans-grey'
			}else{
				return 'red'
			}
		},
	},
}

</script>

<style lang="sass">

.page-delete-account
	padding-top: 20px

	h1
		display: block
		padding: 40px 0 0
		font-size: 20px
		color: $white
		text-align: center
		font-family: $mainFontBold
		font-weight: 900

	.form-account-details
		display: flex
		flex-direction: column
		gap: 10px
		padding: 30px 0 20px 0

	.form-input
		position: relative

	.form-errors
		display: flex
		flex-direction: column
		gap: 10px
		padding: 15px
		background-color: rgba($red, 0.5)
		border-radius: $borderRadiusMedium
		border: 1px solid $transGrey2

		.error-message
			color: $white
			font-size: 14px
			font-family: $mainFontBold

	.form-success-message
		display: flex
		flex-direction: column
		gap: 10px
		padding: 15px
		background-color: rgba($green, 0.5)
		border-radius: $borderRadiusMedium
		border: 1px solid $transGrey2
		color: $white
		font-size: 14px
		font-family: $mainFontBold

	.button-container
		display: flex
		justify-content: center
		margin: 20px 0 calc(40px + $appPadBottom)

	.form-tickbox-containers
		display: flex
		flex-direction: column
		gap: 15px
		padding: 0

		.term-row
			display: flex
			justify-content: space-between
			align-items: center
			gap: 20px
			flex-direction: column

			.tick-label
				font-size: 16px
				color: $white
				text-align: center

			a
				text-decoration: underline

	.form-need-help
		display: flex
		justify-content: space-between
		color: #fff
		font-size: 12px
		margin: 5px 0 15px
		padding: 0
		gap: 10px
		align-items: center

		strong
			font-weight: normal
			font-family: $mainFontBold
			font-size: 16px

		a
			text-decoration: underline

</style>