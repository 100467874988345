<template>
	<div 
		class="atom-icon"
		:class="[
			'color-' + color,
			'icon-' + icon,
			'size-' + size
		]"
		>

		<template v-if="icon == 'search'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 22 22">
				<g>
					<path 
						class="fill-color" 
						d="M20.7,19.5l-5.1-5.2c1.1-1.4,1.7-3.1,1.7-4.9,0-4.5-3.6-8.1-8.1-8.1C4.8,1.3,1.1,4.9,1.1,9.4c0,4.4,3.6,8,8.1,8,2.1,0,3.9-.8,5.3-2l5.1,5.2c.2.1.3.2.5.2s.4-.1.5-.2c.4-.4.4-.8.1-1.1ZM9.2,15.9c-3.6,0-6.6-2.9-6.6-6.5s2.9-6.6,6.6-6.6c3.6,0,6.6,2.9,6.6,6.6-.1,3.5-3,6.5-6.6,6.5Z"
						/>
				</g>
			</svg>
		</template>

		<template v-if="icon == 'home'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 31 27">
				<g>
					<path 
						class="fill-color" 
						d="M29.6,13.5c0,0.9-0.7,1.6-1.6,1.6h-1.6v7.8c0,0.1,0,0.3,0,0.4V24c0,1.1-0.9,2-2,2h-0.8c-0.1,0-0.1,0-0.2,0
						s-0.1,0-0.2,0h-1.6h-1.2c-1.1,0-2-0.9-2-2v-1.2v-3.1c0-0.9-0.7-1.6-1.6-1.6h-3.1c-0.9,0-1.6,0.7-1.6,1.6v3.1V24c0,1.1-0.9,2-2,2
						H9.3H7.7c-0.1,0-0.1,0-0.2,0s-0.1,0-0.2,0H6.5c-1.1,0-2-0.9-2-2v-5.5v-0.1V15H3c-0.9,0-1.6-0.7-1.6-1.6c0-0.4,0.1-0.8,0.5-1.2
						L14.4,1.4C14.8,1,15.2,1,15.5,1s0.7,0.1,1,0.3l12.5,11C29.4,12.6,29.6,13,29.6,13.5z"
						/>
				</g>
			</svg>
		</template>

		<template v-if="icon == 'info'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 27 27">
				<g>
					<path 
						class="fill-color" 
						d="M13.5,26C20.4,26,26,20.4,26,13.5S20.4,1,13.5,1S1,6.6,1,13.5S6.6,26,13.5,26z M11.5,17.4h1.2v-3.1h-1.2
						c-0.6,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2h2.3c0.6,0,1.2,0.5,1.2,1.2v4.3h0.4c0.6,0,1.2,0.5,1.2,1.2s-0.5,1.2-1.2,1.2h-3.9
						c-0.6,0-1.2-0.5-1.2-1.2S10.9,17.4,11.5,17.4z M13.5,7.2c0.9,0,1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6
						S12.6,7.2,13.5,7.2z"
						/>
				</g>
			</svg>
		</template>

		<template v-if="icon == 'map'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 21 27">
				<g>
					<path 
						class="fill-color" 
						d="M11.7,25.4c2.5-3.1,8.2-10.7,8.2-15c0-5.2-4.2-9.4-9.4-9.4s-9.4,4.2-9.4,9.4c0,4.3,5.7,11.9,8.2,15
						C9.9,26.2,11.1,26.2,11.7,25.4L11.7,25.4z M10.5,7.3c1.7,0,3.1,1.4,3.1,3.1s-1.4,3.1-3.1,3.1s-3.1-1.4-3.1-3.1S8.8,7.3,10.5,7.3z"
						/>
				</g>
			</svg>
		</template>

		<template v-if="icon == 'user'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 27">
				<path 
					class="fill-color"
					d="M8.911,14.997c-4.81,-0 -8.706,3.896 -8.706,8.706c0,0.801 0.649,1.45 1.45,1.45l18.975,-0c0.801,-0 1.45,-0.649 1.45,-1.45c0,-4.81 -3.897,-8.706 -8.706,-8.706l-4.463,-0Zm2.231,-2.344c3.453,-0 6.25,-2.798 6.25,-6.25c0,-3.451 -2.797,-6.25 -6.25,-6.25c-3.451,-0 -6.25,2.799 -6.25,6.25c0,3.452 2.799,6.25 6.25,6.25" 
					style="fill-rule:nonzero;"
					/>
			</svg>
		</template>

		<template v-if="icon == 'bell'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 26.5 29.4">
				<path 
					class="fill-color" 
					d="M12,3.4c-.9,0-1.6.7-1.6,1.6v.9c-3.6.7-6.3,3.9-6.3,7.7v.9c0,2.3-.8,4.5-2.4,6.2l-.4.4c-.2.5-.3,1.2,0,1.7s.8.9,1.4.9h18.8c.6,0,1.2-.4,1.4-.9.3-.6.2-1.2-.3-1.7l-.4-.4c-1.5-1.7-2.4-3.9-2.4-6.2v-.9c0-3.8-2.7-6.9-6.2-7.7v-.9c0-.9-.6-1.6-1.5-1.6ZM14.2,27.5c.6-.6.9-1.4.9-2.2h-6.2c0,.8.3,1.6.9,2.2s1.4.9,2.2.9,1.7-.3,2.2-.9Z"
					/>
			</svg>
		</template>

		<template v-if="icon == 'padlock-locked'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 55 63">
				<path 
					class="fill-color" 
					d="M10.068,23.82l0,-5.859c0,-9.704 7.875,-17.579 17.579,-17.579c9.705,0 17.58,7.875 17.58,17.579l-0,5.859l1.95,-0c4.308,-0 7.812,3.504 7.812,7.812l0,23.438c0,4.308 -3.504,7.812 -7.812,7.812l-39.063,0c-4.308,0 -7.812,-3.504 -7.812,-7.812l-0,-23.438c-0,-4.308 3.504,-7.812 7.812,-7.812l1.954,-0Zm7.813,-5.859l-0,5.859l19.529,-0l-0,-5.859c-0,-5.396 -4.367,-9.766 -9.763,-9.766c-5.395,-0 -9.766,4.37 -9.766,9.766" 
					style="fill-rule:nonzero;"
					/>
			</svg>
		</template>

		<template v-if="icon == 'padlock-unlocked'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 71 63">
				<path 
					class="fill-color"
					d="M43.405,17.96c0,-5.396 4.371,-9.767 9.767,-9.767c5.396,0 9.762,4.371 9.762,9.767l0,5.858c0,2.163 1.746,3.909 3.909,3.909c2.162,-0 3.904,-1.746 3.904,-3.909l-0,-5.858c-0,-9.704 -7.871,-17.579 -17.575,-17.579c-9.704,-0 -17.579,7.875 -17.579,17.579l-0,5.858l-27.346,0c-4.309,0 -7.809,3.505 -7.809,7.813l0,23.437c0,4.309 3.5,7.813 7.809,7.813l39.062,-0c4.313,-0 7.817,-3.504 7.817,-7.813l-0,-23.437c-0,-4.308 -3.504,-7.813 -7.817,-7.813l-3.904,0l0,-5.858Z" 
					style="fill-rule:nonzero;"
					/>
			</svg>
		</template>

		<template v-if="icon == 'arrow-left'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 20 14">
				<path 
					class="fill-color" 
					d="M17.6,6H4.8l3.3-3.3c.4-.4.4-1,0-1.4-.4-.4-1-.4-1.4,0L1.7,6.3c-.4.4-.4,1,0,1.4h0l5,5c.4.4,1,.4,1.4,0,.4-.4.4-1,0-1.4l-3.3-3.3h12.8c.6,0,1-.4,1-1s-.4-1-1-1Z"
					/>
			</svg>
		</template>

		<template v-if="icon == 'arrow-right'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 20 14">
				<path 
					class="fill-color" 
					d="M2.4,6h12.8l-3.3-3.3c-.4-.4-.4-1,0-1.4s1-.4,1.4,0l5,5c.4.4.4,1,0,1.4h0l-5,5c-.4.4-1,.4-1.4,0s-.4-1,0-1.4l3.3-3.3H2.4c-.6,0-1-.4-1-1s.4-1,1-1Z"
					/>
			</svg>
		</template>

		<template v-if="icon == 'pencil'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 17.8 17.9">
				<polygon 
					class="fill-color" 
					points="6.3 15.3 14.4 7.2 10.6 3.4 2.5 11.5 1 16.9 6.3 15.3"
					/>
				<path 
					class="fill-color" 
					d="M15.3,6.3l1.1-1.1c.6-.6.6-1.5,0-2l-1.7-1.7c-.6-.6-1.5-.6-2,0l-1.2,1.2h0l3.8,3.6h0Z"
					/>
			</svg>
		</template>

		<template v-if="icon == 'filter'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 17 15">
				<path 
					class="fill-color" 
					d="M9.35,14c-.3,0-.6-.1-.8-.3l-1.7-1.3c-.3-.3-.5-.7-.5-1.1v-1.9L1.55,3.6c-.4-.5-.5-1.2-.2-1.7.2-.5.8-.9,1.4-.9h11.6c.6,0,1.2.4,1.4.9.2.5.2,1.2-.2,1.7l-4.8,5.8v3.2c0,.5-.3,1-.8,1.2-.2.2-.4.2-.6.2ZM2.75,2c-.3,0-.4.1-.5.3h0c-.1.2-.1.5,0,.6l5,6.1v2.3c0,.1.1.2.1.3l1.8,1.3c.1.1.3.1.4.1.1-.1.2-.2.2-.3v-3.6l5-6.1c.1-.2.2-.4.1-.6-.1-.3-.3-.4-.6-.4,0,0-11.5,0-11.5,0Z"
					/>
			</svg>
		</template>

		<template v-if="icon == 'document'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 12 16">
				<path 
					class="fill-color" 
					d="M11.436,3.873l-3.75,-0l-0,-3.75l3.75,3.75Zm-11.25,-1.875c-0,-1.034 0.841,-1.875 1.875,-1.875l4.688,-0l-0,3.75c-0,0.519 0.418,0.937 0.937,0.937l3.75,-0l-0,8.438c-0,1.034 -0.841,1.875 -1.875,1.875l-7.5,-0c-1.034,-0 -1.875,-0.841 -1.875,-1.875l-0,-11.25Z"
					/>
			</svg>
		</template>

		<template v-if="icon == 'font-size'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 27 27">
				<g>
					<circle class="fill-color" cx="13.5" cy="13.5" r="12.5"/>
					<g>
						<path style="fill: #fff" d="M14.785,16.608h-1.581c-.177,0-.32-.041-.429-.124-.108-.082-.191-.188-.247-.316l-.52-1.533h-3.344l-.52,1.533c-.044.112-.124.214-.238.305-.115.091-.257.136-.426.136h-1.594l3.405-8.795h2.088l3.404,8.795ZM11.544,13.246l-.814-2.408c-.061-.153-.125-.333-.193-.54-.068-.208-.137-.432-.205-.674-.061.246-.125.474-.193.683s-.133.391-.193.543l-.809,2.396h2.408Z"/>
						<path style="fill: #fff" d="M15.124,11.218c.378-.334.798-.582,1.259-.745.46-.163.954-.245,1.481-.245.378,0,.72.062,1.023.185s.562.294.772.513c.211.22.373.48.486.782.112.302.169.632.169.99v3.911h-.857c-.177,0-.311-.024-.401-.072-.091-.049-.166-.149-.227-.302l-.133-.345c-.157.133-.308.25-.452.351-.146.101-.295.187-.45.257s-.32.122-.495.156-.369.052-.582.052c-.286,0-.546-.037-.779-.111-.233-.075-.432-.185-.595-.329-.162-.146-.288-.325-.377-.541-.089-.215-.133-.463-.133-.745,0-.226.057-.453.169-.686.113-.23.308-.442.586-.634.277-.19.651-.351,1.123-.479.471-.129,1.063-.201,1.78-.217v-.266c0-.366-.075-.631-.227-.794-.15-.163-.365-.245-.643-.245-.222,0-.402.024-.543.073-.142.048-.267.103-.378.162-.11.061-.218.115-.322.163-.105.049-.229.073-.375.073-.129,0-.237-.032-.325-.097-.089-.064-.161-.142-.218-.229l-.338-.586ZM18.492,14.062c-.374.016-.682.048-.924.096-.241.049-.433.109-.573.182s-.238.155-.293.247c-.054.093-.081.193-.081.302,0,.214.058.363.175.45s.288.13.513.13c.242,0,.453-.043.634-.127.182-.085.364-.222.55-.41v-.869Z"/>
					</g>
				</g>
			</svg>
		</template>

		<template v-if="icon == 'bookmark-flat'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 21 30">
				<g>
					<path class="fill-color" d="M0.3,0l-0,29.4l10.8,-6.2l9.6,6.2l-0,-29.4l-20.4,0Z" style="fill-rule:nonzero;"/>
					<path d="M10.5,7l1.7,5.5l5.5,0l-4.5,3.4l1.7,5.5l-4.4,-3.4l-4.5,3.4l1.7,-5.5l-4.4,-3.4l5.5,0l1.7,-5.5Z" style="fill:#fff;fill-rule:nonzero;"/>
				</g>
			</svg>
		</template>

		<template v-if="icon == 'bookmark-3d'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 23 33">
				<g>
					<path class="fill-color" d="M20.6,0l-17.9,0c0,0 0,2.8 0,1.9l0,30.4l10.2,-4.6l10.1,4.7l0,-29.7c0,-1.5 -1.1,-2.7 -2.4,-2.7Zm-3.4,24.4l-4.4,-3.4l-4.4,3.4l1.7,-5.5l-4.4,-3.4l5.5,-0l1.7,-5.5l1.7,5.5l5.4,-0l-4.4,3.4l1.6,5.5Z" style="fill-rule:nonzero;"/>
					<path d="M14.5,15.5l-1.7,-5.5l-1.7,5.5l-5.5,0l4.4,3.4l-1.6,5.5l4.4,-3.4l4.4,3.4l-1.7,-5.5l4.5,-3.4l-5.5,0Z" style="fill:#fff;fill-rule:nonzero;"/>
					<path class="fill-color-secondary" d="M2.7,0c-0.4,0 -0.8,0.1 -1.1,0.2c-0.4,0.1 -0.7,0.3 -0.9,0.6c-0.3,0.3 -0.5,0.7 -0.6,1.1c-0.1,0.2 -0.1,0.7 -0.1,1l2.7,0l0,-2.9Z" style="fill-rule:nonzero;"/>
				</g>
			</svg>
		</template>

		<template v-if="icon == 'close'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 17 17">
				<path class="fill-color" d="M15.6,3.6c.6-.6.6-1.5,0-2.1s-1.5-.6-2.1,0l-5,4.9L3.6,1.4c-.6-.5-1.6-.5-2.2,0s-.5,1.6,0,2.2l4.9,4.9L1.4,13.4c-.6.6-.6,1.5,0,2.1.6.6,1.5.6,2.1,0l4.9-4.9,4.9,4.9c.6.6,1.5.6,2.1,0s.6-1.5,0-2.1l-4.9-4.9,5.1-4.9Z"/>
			</svg>
		</template>

		<template v-if="icon == 'chevron-down'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 14 9">
				<path class="fill-color" d="M7.7,7.7c-.4.4-1,.4-1.4,0L1.3,2.7c-.4-.4-.4-1,0-1.4s1-.4,1.4,0l4.3,4.3L11.3,1.3c.4-.4,1-.4,1.4,0s.4,1,0,1.4l-5,5h0Z"/>
			</svg>
		</template>

		<template v-if="icon == 'chevron-up'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 14 9">
				<path class="fill-color" d="M6.3,1.3c.4-.4,1-.4,1.4,0l5,5c.4.4.4,1,0,1.4s-1,.4-1.4,0L7,3.4,2.7,7.7c-.4.4-1,.4-1.4,0-.4-.4-.4-1,0-1.4L6.3,1.3h0Z"/>
			</svg>
		</template>
		
		<template v-if="icon == 'eye'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 26 16">
				<g>
					<path class="fill-color" d="M25.05,6.79c0.6,0.62 0.6,1.79 0,2.41c-2.02,2.1 -6.65,6.2 -12.05,6.2c-5.4,0 -10.03,-4.1 -12.05,-6.2c-0.6,-0.62 -0.6,-1.79 0,-2.41c2.02,-2.1 6.65,-6.2 12.05,-6.2c5.4,0 10.03,4.1 12.05,6.2Z" style="fill-rule:nonzero;"/>
					<circle cx="13" cy="8" r="5.84" style="fill:#fff;"/>
					<circle class="fill-color" cx="13" cy="8" r="3.21"/>
				</g>
			</svg>
		</template>

		<template v-if="icon == 'logo-facebook'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 37 37">
				<path class="fill-color" d="M18.5,1C8.84,1,1,8.83,1,18.5s7.84,17.5,17.5,17.5,17.5-7.83,17.5-17.5S28.16,1,18.5,1ZM24.21,10.78c-.15,0-.3-.01-.45,0-.78.06-1.57.1-2.35.18-.37.04-.75.19-.98.5-.15.2-.28.47-.29.71-.05,1.19-.05,2.38-.06,3.56,0,.02.01.05.02.09,1.34.01,2.68-.1,4.06.07-.14,1.47-.51,2.86-.76,4.3h-3.41v10.63h-4.51c-.04-3.54-.08-7.08-.12-10.65h-3.86v-4.22h3.9v-.28c.02-1.31.01-2.62.06-3.93.03-.9.25-1.76.71-2.55.72-1.2,1.87-1.71,3.18-1.93s2.62-.17,3.94-.11c.3.01.61.03.92.04v3.59Z"/>
			</svg>
		</template>

		<template v-if="icon == 'logo-linkedin'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 37 37">
				<path class="fill-color" d="M18.5,1C8.84,1,1,8.83,1,18.5s7.84,17.5,17.5,17.5,17.5-7.83,17.5-17.5S28.16,1,18.5,1ZM14.16,26.62h-3.68v-12.15h3.68v12.15ZM12.35,12.6c-1.19,0-2.15-.96-2.15-2.15s.96-2.15,2.15-2.15,2.15.96,2.15,2.15-.96,2.15-2.15,2.15ZM28.54,26.63h-3.72l.01-6.51c0-3.93-4.67-3.13-4.83-.16v6.66h-3.68v-12.15h3.5v1.65c2.38-3.14,8.72-2.82,8.72,3.12v7.39Z"/>
			</svg>
		</template>

		<template v-if="icon == 'logo-twitter'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 37 37">
				<g>
					<polygon class="fill-color" points="10.68 9.949 23.465 27.062 26.359 27.062 13.574 9.949 10.68 9.949"/>
					<path class="fill-color" d="M18.491,1.013h0C8.825,1.013.985,8.853.985,18.519h0c0,9.666,7.84,17.506,17.506,17.506h0c9.666,0,17.506-7.84,17.506-17.506h0c0-9.694-7.84-17.506-17.506-17.506ZM22.791,28.411l-5.817-7.784-7.222,7.784h-1.855l8.233-8.908L7.954,8.572h6.294l5.367,7.165,6.632-7.165h1.855l-7.643,8.289,8.655,11.549s-6.323,0-6.323,0Z"/>
				</g>
			</svg>
		</template>

		<template v-if="icon == 'share'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 22 22">
				<path class="fill-color" d="M16.52,21c-.41-.13-.84-.21-1.22-.39-1.45-.68-2.23-2.35-1.83-3.9.05-.21,0-.3-.19-.4-1.7-.86-3.4-1.73-5.1-2.6-.06-.03-.12-.06-.19-.09-.78,1-1.8,1.53-3.08,1.46-.9-.05-1.67-.4-2.29-1.05-1.35-1.39-1.35-3.49-.02-4.9,1.26-1.33,3.64-1.57,5.18.13,1.93-1.16,3.87-2.32,5.81-3.49-.75-1.99.4-3.91,1.96-4.52,1.67-.66,3.48-.03,4.39,1.53.84,1.44.49,3.34-.83,4.43-1.29,1.07-3.44,1.21-4.92-.43-.05.03-.11.06-.16.09-1.82,1.09-3.64,2.19-5.46,3.28-.14.08-.17.15-.12.3.22.61.27,1.24.09,1.88-.02.06-.03.13-.04.19q0,.01.01.04c1.83.93,3.67,1.88,5.5,2.81.26-.24.49-.5.76-.71,2.08-1.61,5.22-.32,5.57,2.28.27,1.98-1.03,3.7-3.02,4-.04.01-.07.02-.11.04-.22.02-.45.02-.69.02Z"/>
			</svg>
		</template>

		<template v-if="icon == 'thumbs-up'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 22 22">
				<g>
					<path class="fill-color" d="M19.16,14.13c1.1,1.04.68,2.76-.73,3.15.1.26.24.51.29.77.16,1.01-.57,1.81-1.61,1.82h-8.03c-.23,0-.47,0-.7-.03-.39-.06-.78-.15-1.18-.22-.15-.03-.14-.12-.14-.23v-3.28c0-2,0-4.01.01-6.01,0-.12.07-.24.13-.35.16-.28.37-.55.49-.85.53-1.31,1.05-2.63,1.56-3.95.04-.11.06-.23.06-.34.01-.71,0-1.42,0-2.13,0-1.05.86-1.7,1.87-1.42.39.11.71.32.98.62,1.22,1.42,1.56,3.04,1.09,4.84-.1.37-.29.72-.44,1.08-.03.07-.07.13-.12.22h6.13c1.01,0,1.8.74,1.8,1.71,0,.66-.29,1.18-.85,1.53-.05.03-.11.06-.16.1,0,0-.01.01-.01.02.94.95.67,2.36-.44,2.95Z"/>
					<path class="fill-color" d="M1.38,15.04v-4.79c0-.73.42-1.16,1.15-1.16h2.85c.68,0,1.12.43,1.12,1.11v9.68c0,.69-.44,1.11-1.13,1.12h-2.87c-.68,0-1.11-.43-1.11-1.11-.01-1.62-.01-3.23-.01-4.85Z"/>
				</g>
			</svg>
		</template>

		<template v-if="icon == 'email'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 14 11">
				<path class="fill-color" d="M2.12,1c-.62,0-1.12.5-1.12,1.12,0,.35.17.69.45.9l5.1,3.83c.27.2.63.2.9,0l5.1-3.83c.28-.21.45-.54.45-.9,0-.62-.5-1.12-1.12-1.12H2.12ZM1,3.62v4.88c0,.83.67,1.5,1.5,1.5h9c.83,0,1.5-.67,1.5-1.5V3.62l-5.1,3.83c-.53.4-1.27.4-1.8,0L1,3.62Z"/>
			</svg>
		</template>

		<template v-if="icon == 'phone'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 14 14">
				<path class="fill-color" d="M4.86,1.58c-.18-.44-.65-.67-1.11-.55l-2.06.57c-.41.11-.69.48-.69.9,0,5.8,4.7,10.5,10.5,10.5.42,0,.79-.28.9-.69l.56-2.06c.12-.45-.11-.93-.54-1.11l-2.25-.94c-.38-.16-.82-.05-1.08.27l-.95,1.16c-1.65-.79-2.98-2.12-3.77-3.77l1.16-.94c.32-.26.43-.7.27-1.09l-.94-2.25h0Z"/>
			</svg>
		</template>

		<template v-if="icon == 'link'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 22 17">
				<path class="fill-color" d="M19.29,8.87c1.8-1.8,1.8-4.72,0-6.52-1.59-1.59-4.11-1.8-5.94-.49l-.05.04c-.46.33-.56.97-.24,1.42.33.46.97.56,1.42.24l.05-.04c1.02-.73,2.42-.62,3.31.27,1,1,1,2.63,0,3.64l-3.58,3.58c-1,1-2.63,1-3.64,0-.89-.89-1-2.29-.27-3.31l.04-.05c.33-.46.22-1.1-.24-1.42-.45-.33-1.1-.22-1.42.24l-.04.05c-1.31,1.83-1.11,4.34.49,5.94,1.8,1.8,4.72,1.8,6.52,0l3.59-3.59ZM2.71,8.13c-1.8,1.8-1.8,4.72,0,6.52,1.59,1.59,4.11,1.8,5.94.49l.05-.04c.46-.33.56-.97.24-1.42-.33-.46-.97-.56-1.42-.24l-.05.04c-1.02.73-2.42.62-3.31-.27-1-1.01-1-2.63,0-3.64l3.58-3.58c1-1,2.63-1,3.64,0,.89.89,1,2.29.27,3.31l-.04.05c-.33.46-.22,1.1.24,1.42.46.33,1.1.22,1.42-.24l.04-.05c1.31-1.83,1.11-4.35-.49-5.94-1.8-1.8-4.72-1.8-6.52,0l-3.59,3.59Z"/>
			</svg>
		</template>

	</div>
</template>

<script>

export default {
	name: 'AtomIcon',
	props: {
		icon: {
			type: String,
			required: true
		},
		color: {
			type: String,
			default: 'white',
			validator: function(value) {
				return ['white', 'grey', 'orange', 'green'].includes(value);
			}
		},
		size: {
			type: String,
			default: 'medium',
			validator: function(value) {
				return ['small', 'medium', 'large'].includes(value);
			}
		}
	}
}

</script>

<style lang="sass">

.atom-icon
	display: flex
	align-items: center
	justify-content: center
	-webkit-tap-highlight-color: transparent // Prevent tap highlight on iOS
	outline: none // Remove outline for focused elements
	// border: 1px solid $red // for debugging layout

	&.size-xxsmall
		width: 10px
		height: 10px

	&.size-xsmall
		width: 15px
		height: 15px

	&.size-small
		width: 20px
		height: 20px

	&.size-medium
		width: 25px
		height: 25px

	&.size-large
		width: 30px
		height: 30px

	&.size-xlarge
		width: 35px
		height: 35px

	&.color-white
		.fill-color
			fill: $white

	&.color-grey
		.fill-color
			fill: $midGrey

	&.color-light-grey
		.fill-color
			fill: $lightGrey

	&.color-dark-grey
		.fill-color
			fill: $darkGrey

	&.color-black
		.fill-color
			fill: $black

	&.color-orange
		.fill-color
			fill: $orange

		.fill-color-secondary
			fill: $darkOrange

	&.color-green
		.fill-color
			fill: $green

	&.color-purple
		.fill-color
			fill: $purple

		.fill-color-secondary
			fill: $darkPurple

	svg
		// border: 1px solid $green // for debugging layout
		width: 100%
		height: 100%

		.fill-color
			transition: fill 300ms


</style>