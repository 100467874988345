import axios from 'axios'

const state = () => ({
	centres: [],
	centreInfoPanelOpen: false,
	centreInfoPanelContent: null,
	countries: [],
	centreFilterPanelOpen: false,
	activeFilters: {
		countries: [],
		qualifications:	[],
		types: []
	},
	howManyResultsFound: 0
})

const getters = {
	getCentres: state => state.centres,
	getCentreInfoPanelOpen: state => state.centreInfoPanelOpen,
	getCentreInfoPanelContent: state => state.centreInfoPanelContent,
	getCountries: state => state.countries,
	getCentreFilterPanelOpen: state => state.centreFilterPanelOpen,
	getActiveFilters: state => state.activeFilters,
	getHowManyResultsFound: state => state.howManyResultsFound
}

const mutations = {
	setCentres(state, centres) {
		state.centres = centres
	},
	setCentreInfoPanelOpen(state, open) {
		state.centreInfoPanelOpen = open
	},
	setCentreInfoPanelContent(state, content) {
		state.centreInfoPanelContent = content
	},
	setCountries(state, countries) {
		state.countries = countries
	},
	setCentreFilterPanelOpen(state, open) {
		state.centreFilterPanelOpen = open
	},
	addActiveFilter(state, { filterType, filter }) {
		state.activeFilters[filterType].push(filter)
	},
	removeActiveFilter(state, { filterType, filter }) {
		state.activeFilters[filterType] = state.activeFilters[filterType].filter(f => f !== filter)
	},
	clearActiveFilters(state) {
		state.activeFilters = {
			countries: [],
			qualifications: []
		}
	},
	setHowManyResultsFound(state, howMany) {
		state.howManyResultsFound = howMany
	}
}

const actions = {
	getCentres({ commit, dispatch }) {
		// console.log('Fetching centres...')
		return new Promise((resolve, reject) => {
			axios.get('/api/centres')
				.then(response => {
					commit('setCentres', response.data)
					// console.log('centres', response.data)
					resolve(response)
				})
				.catch(error => {
					// if 401 then dispatch clearUser
					if (error.response && error.response.status === 401) {
						dispatch('auth/clearUser', null, { root: true })
						resolve(error)
					}else{
						console.error('Error fetching centres:', error)
						reject(error)
					}
				})
		})
	},
	getCountries({ commit, dispatch }) {
		return new Promise((resolve, reject) => {
			axios.get('/api/centres/countries')
				.then(response => {
					commit('setCountries', response.data)
					// console.log('countries', response.data)
					resolve(response)
				})
				.catch(error => {
					if (error.response && error.response.status === 401) {
						dispatch('auth/clearUser', null, { root: true })
						resolve(error)
					}else{
						console.error('Error fetching countries:', error)
						reject(error)
					}
				})
		})
	}
}


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}