import { createStore } from 'vuex'
import auth from './modules/auth'
import layout from './modules/layout'
import documents from './modules/documents'
import centres from './modules/centres'
import news from './modules/news'
import quickLinks from './modules/quick-links'
import axiosAuthPlugin from './plugins/axiosAuth'

const store = createStore({
  modules: {
    auth,
    layout,
    documents,
    centres,
    news,
    quickLinks,
  },
  plugins: [axiosAuthPlugin],
})

export default store
