<template>
	<div class="organism-popup-info-panel">
		
		<MoleculePopUpPanel
			@closePanel="setInfoPanelOpen(false)"
			:panelOpen="panelOpen"
			>

			<div class="links-panel-inner">
				<ul>
					<li>
						<router-link 
							to="/about"
							@click="setInfoPanelOpen(false)"
							>
							About Us
						</router-link>
					</li>
					<!-- <li>
						<a href="#">Events</a>
					</li> -->
					<li>
						<router-link 
							to="/contact"
							@click="setInfoPanelOpen(false)"
							>
							Contact Us
						</router-link>
					</li>
					<li>
						<router-link 
							to="/quick-links"
							@click="setInfoPanelOpen(false)"
							>
							Quick Links
						</router-link>
					</li>
				</ul>

				<div class="social-links">
					<a href="https://twitter.com/ComPex_Cert" target="_blank">
						<AtomIcon 
							icon="logo-twitter"
							color="grey"
							size="xlarge"
							/>
					</a>
					<a href="https://www.linkedin.com/company/compex-competency/" target="_blank">
						<AtomIcon 
							icon="logo-linkedin"
							color="grey"
							size="xlarge"
							/>
					</a>
					<a href="https://www.facebook.com/CompExCompetency" target="_blank">
						<AtomIcon 
							icon="logo-facebook"
							color="grey"
							size="xlarge"
							/>
					</a>
				</div>
			</div>

		</MoleculePopUpPanel>

	</div>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'

import AtomIcon from '@/components/atoms/AtomIcon.vue'
import MoleculePopUpPanel from '../molecules/MoleculePopUpPanel.vue'

export default {
	name: 'OrganismPopupInfoPanel',
	components: {
		AtomIcon,
		MoleculePopUpPanel
	},
	data: () => {
		return {
			
		}
	},
	methods: {
		...mapMutations('layout', {
			setInfoPanelOpen: 'setInfoPanelOpen',
		}),
		resetAll(){
			this.setEnableHighContrast(false)
			this.setTextSize('small')
		}
	},
	computed: {
		...mapGetters('layout', {
			panelOpen: 'getInfoPanelOpen',
		})
	},
}

</script>

<style lang="sass">

.organism-popup-info-panel
	.links-panel-inner
		display: flex
		flex-direction: column
		align-items: center
		text-align: center
		gap: 15px

		ul
			width: 100%
			display: flex
			flex-direction: column
			gap: 5px

			li
				a
					font-size: 20px
					color: $white
					font-family: $mainFontBold
					letter-spacing: -0.5px
					padding: 10px 0
					display: flex
					text-align: center
					justify-content: center
					align-items: center
					background-color: rgba(#fff, 0.05)
					width: 100%
					border-radius: $borderRadiusSmall

		.social-links
			display: flex
			gap: 15px
			align-items: center
			justify-content: center


</style>